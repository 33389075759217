._3TCX0 {
  padding: 8px 12px;
  min-width: 78px;
  height: 40px;
  border-radius: 6px;
  text-align: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.5px;
  cursor: pointer;
  white-space: nowrap;
  border: 1px solid transparent;
  background-color: #fff;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._2QiH2 {
  text-transform: uppercase;
}
._10v7S {
  height: 48px;
  border-radius: 8px;
}
._1YYe3 {
  height: 36px;
  line-height: 20px;
  font-weight: normal;
}
._3TCX0[disabled] {
  cursor: initial;
  color: rgba(255,255,255,0.5);
  border: initial;
  background-color: rgba(255,255,255,0.1);
}
._1irKu {
  color: #fff;
  background-color: #000;
}
._1irKu:hover {
  color: rgba(255,255,255,0.5);
}
._1irKu:active {
  color: #000;
  background-color: transparent;
}
._1irKu[disabled] {
  color: rgba(0,0,0,0.5);
  background-color: rgba(0,0,0,0.1);
}
._3gpWB {
  color: #000;
  background-color: transparent;
  border-color: rgba(0,0,0,0.25);
}
._3gpWB:not([disabled]):hover {
  color: rgba(0,0,0,0.5);
  border-color: rgba(0,0,0,0.45);
}
._3gpWB:active {
  color: #000;
  border-color: #000;
}
._3gpWB[disabled] {
  background-color: rgba(0,0,0,0.1);
}
.fKAlT {
  color: #000;
  background-color: #fff;
}
.fKAlT:hover {
  background-color: #e3e3e3;
}
.fKAlT:active {
  background-color: #f1f2f2;
}
._3k8Q7 {
  color: #fff;
  background-color: transparent;
  border-color: rgba(255,255,255,0.3);
}
._3k8Q7:hover {
  border-color: rgba(255,255,255,0.5);
}
._3k8Q7:active {
  border-color: rgba(255,255,255,0.75);
}
._2xPXK {
  color: #000;
  background-color: #fff;
  display: flex;
  align-items: center;
}
._2xPXK:hover {
  color: rgba(0,0,0,0.5);
}
._2xPXK:hover > ._2DRoL {
  fill: rgba(0,0,0,0.5);
}
._2xPXK:active {
  color: #000;
}
._2xPXK:active > ._2DRoL {
  fill: #000;
}
._2xPXK[disabled] {
  color: rgba(0,0,0,0.1);
}
._2xPXK[disabled] > ._2DRoL {
  fill: rgba(0,0,0,0.1);
}
._2DRoL {
  width: 8px;
  height: 14px;
  fill: #000;
  margin-right: 12px;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
._2on1x {
  color: #000;
  background-color: #bbdcb0;
}
._2on1x:hover {
  background-color: #fff;
}
._2on1x:active {
  background-color: #a2c596;
}
