.V7vNr {
  display: flex;
  margin-right: -40px;
}
@media (max-width: 1079px) {
  .V7vNr {
    margin-right: -33px;
  }
}
@media (max-width: 971px) {
  .V7vNr {
    flex-wrap: wrap;
    margin-right: -32px;
    margin-bottom: -32px;
  }
}
@media (max-width: 674px) {
  .V7vNr {
    margin-right: -24px;
  }
}
@media (max-width: 567px) {
  .V7vNr {
    margin-right: 0;
    margin-bottom: 0;
  }
}
._3f35d {
  width: 280px;
  margin-right: 40px;
}
@media (max-width: 1079px) {
  ._3f35d {
    width: 264.6666666666667px;
    margin-right: 33px;
  }
}
@media (max-width: 971px) {
  ._3f35d {
    width: calc(50% - 33px);
    margin-bottom: 32px;
  }
}
@media (max-width: 674px) {
  ._3f35d {
    width: calc(50% - 24px);
    margin-right: 24px;
  }
}
@media (max-width: 567px) {
  ._3f35d {
    width: 100%;
    margin-right: 0;
    margin-bottom: 12px;
  }
}
._2xo6b {
  margin: 0 auto;
}
