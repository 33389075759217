._3nPGY {
  margin-bottom: 40px;
}
._2Dzd1 {
  display: flex;
  align-items: center;
}
@media (max-width: 567px) {
  ._2Dzd1 {
    display: block;
  }
}
._20EoF {
  min-width: 148px;
  margin-right: 14px;
}
@media (max-width: 567px) {
  ._20EoF {
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }
}
@media (max-width: 567px) {
  .NryiQ {
    width: 100%;
    text-align: center;
  }
}
