._1OyQJ {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
._1kK6W {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}
@media (max-width: 1439px) {
  ._1kK6W {
    padding: 0 10px;
  }
}
._2Zxfw {
  display: flex;
  margin-right: 22px;
  align-items: center;
  gap: 8px;
  font-size: 24px;
}
.LulV7 {
  color: #000;
  font-weight: 600;
}
.IY2WF {
  color: rgba(0,0,0,0.4);
  font-weight: 400;
}
@media (max-width: 567px) {
  .UvKV4,
  ._1OyQJ form {
    width: 100%;
  }
}
._1gVJi input {
  background-color: transparent !important;
}
