._1KCW- {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ya8HB {
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  fill: #fff;
  cursor: pointer;
  transition: opacity 150ms ease-in;
  position: absolute;
  top: 24px;
  right: 24px;
}
._3Pd6m {
  position: relative;
  z-index: 6;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: #fff;
  border-radius: 8px;
  transition: opacity 150ms ease-in;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 575px) {
  ._3Pd6m {
    padding: 8px;
  }
}
.pPUvB {
  height: 64px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
._6Dxes {
  font-size: 16px;
  line-height: 24px;
  color: rgba(0,0,0,0.45);
}
._2rA95 {
  overflow-y: auto;
  flex: 1;
  padding-top: 24px;
  padding-bottom: 50px;
  position: relative;
}
@media (max-width: 767px) {
  ._2rA95 {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
._1q9mt {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 150%;
  background-color: rgba(0,0,0,0.6);
  transform: translateX(-50%) translateY(-50%);
}
