._3aHdJ {
  padding-bottom: 60px;
}
._1yUGL {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
._1A7wN {
  display: flex;
  gap: 10px;
}
.uEcdR {
  margin-right: 24px;
}
@media (max-width: 567px) {
  .uEcdR {
    margin-right: 12px;
  }
}
._3MOmj {
  min-width: auto;
}
@media (max-width: 674px) {
  ._3MOmj {
    width: 160px;
  }
}
._3JikO {
  display: flex;
  margin-bottom: 60px;
}
._3JikO:last-child {
  margin-bottom: 0;
}
@media (max-width: 971px) {
  ._3JikO {
    flex-direction: column;
  }
}
.yUljy {
  flex: 1 1 0%;
  width: calc(100% - 300px);
}
@media (max-width: 971px) {
  .yUljy {
    width: 100%;
    margin-bottom: 40px;
  }
}
._3uY5g {
  padding: 0;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 20px;
  padding-bottom: 40px;
}
._3uY5g a {
  color: #a1a490;
  border-bottom: 1px solid;
}
@media (hover: hover) {
  ._3uY5g a:hover {
    color: #474b2f;
  }
}
