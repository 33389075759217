._3kKyt {
  margin-right: 10px;
  margin-left: 10px;
}
.y9mn4 {
  margin-right: 29px;
  font-weight: 600;
  color: #a1a490;
}
._3OqY3 {
  display: flex;
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
  color: #474b2f;
  padding: 0;
  border: none;
  flex-direction: column;
  align-items: center;
}
@media (hover: hover) {
  ._3OqY3 {
    transition: color 0.35s;
  }
  ._3OqY3:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
._5Hd09 {
  font-weight: 400;
  font-size: 12px;
}
.RdeNd {
  display: block;
}
._3lYCN {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 6px;
  margin-left: 2px;
  opacity: 0.1;
  color: #e3e5dc;
}
@media (hover: hover) {
  ._3lYCN {
    transition: transform 0.35s;
    will-change: transform;
  }
  ._3OqY3:hover ._3lYCN {
    transform: translateX(6px);
  }
}
@media (max-width: 1079px) {
  ._3lYCN {
    width: 20px;
    height: 20px;
  }
}
._22JOy {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 6px;
  margin-left: 8px;
  color: #e3e5dc;
}
@media (hover: hover) {
  ._22JOy {
    transition: transform 0.35s;
    will-change: transform;
  }
  ._3OqY3:hover ._22JOy {
    transform: translateX(6px);
  }
}
@media (max-width: 1079px) {
  ._22JOy {
    width: 20px;
    height: 20px;
  }
}
