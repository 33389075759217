._1CM5a {
  display: flex;
  align-items: center;
  margin-bottom: 28px;
  color: #a1a490;
}
@media (max-width: 567px) {
  ._1CM5a {
    margin-bottom: 22px;
  }
}
._3B6Bw {
  display: inline-flex;
  align-items: center;
}
._1te2R {
  display: inline-flex;
  align-items: center;
  margin-left: 9px;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.333;
  vertical-align: middle;
}
._2AuHC {
  padding-bottom: 60px;
}
@media (max-width: 567px) {
  ._2AuHC {
    padding-bottom: 32px;
  }
}
.BiKXM {
  padding-top: 0;
}
._1PXd8 {
  height: 22px;
  text-align: center;
}
.yZXs4 {
  margin-bottom: -22px;
  padding-left: 10px;
  color: #a1a490;
}
._2yHHA {
  display: inline-block;
  margin-right: 8px;
  opacity: 0.2;
  vertical-align: middle;
  transform: rotate(90deg);
}
