._2JVjO {
  width: 256px;
  padding: 32px 25px 36px;
  border: 1px solid #e4e7d7;
  border-radius: 20px;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 1.333;
  color: #a1a490;
}
@media (max-width: 971px) {
  ._2JVjO {
    padding: 32px 24px;
  }
}
@media (max-width: 674px) {
  ._2JVjO {
    padding: 32px;
  }
}
@media (max-width: 567px) {
  ._2JVjO {
    padding: 18px 17px 28px;
  }
}
._2I73N {
  margin-bottom: 24px;
}
._2I73N:last-child {
  margin-bottom: 0;
}
@media (max-width: 971px) {
  ._1psEr {
    width: calc(50% - 16px);
  }
}
@media (max-width: 567px) {
  ._1psEr {
    width: 100%;
  }
}
._3kpfA {
  flex-shrink: 0;
  width: 96px;
}
.Z75SX {
  padding-left: 5px;
  font-weight: 600;
  color: #a1a490;
}
._1jIuL {
  margin-bottom: 12px;
}
._3ljYg {
  margin-bottom: 16px;
}
._2-rmr ._3kpfA {
  text-align: left;
}
._2z3ss {
  flex: 1;
  text-align: right;
  color: #474b2f;
}
._17boY {
  font-weight: 600;
}
._2sA8c {
  color: #474b2f;
}
@media (hover: hover) {
  ._2sA8c {
    transition: color 0.35s;
  }
  ._2sA8c:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
._2sA8c:not(:last-child) {
  margin-bottom: 2px;
}
@media (max-width: 971px) {
  ._2sA8c:not(:last-child) {
    margin-right: 12px;
  }
}
@media (max-width: 567px) {
  ._2sA8c:not(:last-child) {
    margin-right: 8px;
  }
}
@media (max-width: 971px) {
  ._2sA8c {
    display: inline-block;
    vertical-align: top;
  }
}
@media (max-width: 567px) {
  ._2sA8c {
    display: block;
  }
}
.au5N9 {
  color: #a1a490;
}
.R4xVE {
  font-weight: 600;
}
._2OSEB {
  color: #474b2f;
}
@media (hover: hover) {
  ._2OSEB {
    transition: color 0.35s;
  }
  ._2OSEB:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
.pLJos {
  display: flex;
  margin-bottom: 16px;
}
.pLJos:last-child {
  margin-bottom: 0;
}
._5DFWY,
._2BOmg {
  display: block;
}
.tehQb {
  display: inline-flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.333;
}
._1QDe_ {
  padding-right: 4px;
  color: #474b2f;
}
.tehQb svg {
  width: 20px;
  height: 20px;
}
._2li75 {
  padding-top: 16px;
}
