.-yVmD {
  position: relative;
}
.SUQYO {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  border: none;
  opacity: 0;
}
