.ALnJD {
  position: relative;
  width: 384px;
  padding: 28px 32px 42px 32px;
  margin: 0;
}
@media (max-width: 567px) {
  .ALnJD {
    width: 320px;
  }
}
._1Gyff {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgba(57,57,53,0.88);
  transition: opacity 150ms ease-in;
  position: absolute;
  top: 20px;
  right: 20px;
}
.TO0UJ {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: auto;
  margin-left: auto;
  vertical-align: middle;
}
@media (hover: hover) {
  ._1Gyff {
    transition: background-color 0.35s;
  }
  ._1Gyff:hover {
    background-color: #1a1f19;
  }
}
._2DVsP {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-right: 35px;
  margin-bottom: 25px;
}
._2uO3a {
  margin-right: 30px;
}
._3T7Fw {
  margin-bottom: 0;
}
