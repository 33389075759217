._4jjPv {
  position: absolute;
  top: 0;
  left: 32px;
  width: 112px;
  height: 112px;
  transform: translate3d(0, -50%, 0);
  border-radius: 50%;
}
@media (max-width: 971px) {
  ._4jjPv {
    width: 76px;
    height: 76px;
  }
}
@media (max-width: 567px) {
  ._4jjPv {
    left: 15px;
  }
}
