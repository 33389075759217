._1J1Yn {
  margin-bottom: 18px;
}
._3F47F {
  display: flex;
  justify-content: space-between;
}
@media (max-width: 674px) {
  ._3F47F {
    display: block;
  }
}
._1h1ob {
  width: calc(100% / 2 - 18px);
}
@media (max-width: 674px) {
  ._1h1ob {
    width: 100%;
    margin-bottom: 40px;
  }
  ._1h1ob:last-child {
    margin-bottom: 0;
  }
}
