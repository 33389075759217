._1qTZG {
  margin-bottom: 22px;
}
._1qTZG:last-child {
  margin-bottom: 0;
}
@media (max-width: 674px) {
  ._1qTZG {
    margin-right: -32px;
    margin-left: -32px;
  }
}
@media (max-width: 567px) {
  ._1qTZG {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media (max-width: 567px) {
  .lcXnt {
    justify-content: center;
  }
}
._2UKOZ {
  min-width: 260px;
}
