._2vR1A {
  display: flex;
  justify-content: space-between;
  margin-bottom: 14px;
}
@media (max-width: 674px) {
  ._2vR1A {
    flex-wrap: wrap;
  }
}
._3JZNf {
  width: 24.5%;
  width: calc(25% - 2px);
  background-color: #f4f4f1;
  overflow: hidden;
}
._3JZNf:first-child {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
._3JZNf:last-child {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
@media (max-width: 674px) {
  ._3JZNf {
    width: 49.5%;
    width: calc(50% - 1px);
    margin-bottom: 2px;
  }
  ._3JZNf:first-child {
    border-radius: 0;
  }
  ._3JZNf:last-child {
    border-radius: 0;
  }
}
._3JZNf img {
  max-width: 120px;
}
@media (max-width: 674px) {
  ._3JZNf img {
    max-width: 95px;
  }
}
.XMMH- {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 160px;
  background-color: #f4f4f1;
}
@media (hover: hover) {
  .XMMH- {
    transition: background-color 0.35s;
  }
  .XMMH-:hover {
    transition-duration: 0.175s;
    background-color: #edefea;
  }
}
@media (max-width: 674px) {
  .XMMH- {
    height: 120px;
  }
}
._29ZiT {
  margin-right: -9px;
  margin-left: -9px;
}
@media (max-width: 674px) {
  ._29ZiT {
    display: flex;
    flex-direction: column;
    text-align: center;
  }
  ._29ZiT .GrtQB {
    justify-content: center;
  }
}
.GrtQB {
  margin-top: 10px;
  margin-right: 9px;
  margin-left: 9px;
  vertical-align: middle;
}
._1Os90 svg {
  display: none;
}
@media (max-width: 567px) {
  .GrtQB > span {
    max-width: 100% !important;
  }
}
