.NNh4J {
  max-width: 484px;
  max-height: 622px;
  box-shadow: 0px 14px 26px rgba(0,0,0,0.12);
  border-radius: 20px;
}
@media (max-width: 575px) {
  .NNh4J {
    max-width: 336px;
    max-height: 658px;
    height: fit-content;
    padding: 0;
  }
}
._3vyuC {
  height: 230px;
  background-color: #000;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  justify-content: center;
  align-items: center;
}
@media (max-width: 320px) {
  ._3vyuC {
    height: 115px;
  }
}
._2_kGS {
  width: 157px;
  fill: #cfdbf9;
}
@media (max-width: 320px) {
  ._2_kGS {
    width: 100px;
  }
}
._24ekY {
  display: block;
  padding: 40px;
  color: #000;
  text-align: start;
}
@media (max-width: 674px) {
  ._24ekY {
    padding: 24px 20px;
  }
}
.fI-wY {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 16px;
}
._3BTji {
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin-bottom: 5px;
}
._3slJQ {
  margin: 24px 0;
  width: 230px;
}
._3slJQ > span {
  color: #484a32;
}
.Di89Y {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0,0,0,0.45);
}
._1oxSU {
  color: rgba(0,0,0,0.45);
  text-decoration: underline;
}
._3ng_- {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgba(57,57,53,0.88);
  transition: opacity 150ms ease-in;
  position: absolute;
}
._2SLHS {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: auto;
  margin-left: auto;
  vertical-align: middle;
}
@media (hover: hover) {
  ._3ng_- {
    transition: background-color 0.35s;
  }
  ._3ng_-:hover {
    background-color: #1a1f19;
  }
}
._3e5Sp {
  max-width: 620px;
  background-color: #f4f4f1;
  border-radius: 20px;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
}
._3akOI {
  margin-top: 16px;
}
._2GPE2 {
  width: 100%;
  height: auto !important;
}
._2uGKY {
  width: 100%;
  max-width: 320px;
  padding-left: 15px;
  padding-right: 15px;
}
.E2umC {
  margin-left: 16px;
}
._3LY1Z {
  line-height: 20px;
  font-size: 13px;
  height: 16px;
  color: #ef6c00;
  margin-top: 4px;
}
._3ScCk {
  position: absolute !important;
  width: 320px;
  bottom: -22px;
}
