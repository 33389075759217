._2tAa3 {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #3b722d;
  border-radius: 20px;
  color: #fff;
  padding: 24px;
  height: 100%;
}
@media (max-width: 674px) {
  ._2tAa3 {
    flex-direction: row;
    align-items: center;
  }
}
._2tYOF {
  font-weight: 600;
  font-size: 31px;
  line-height: 120%;
}
@media (max-width: 971px) {
  ._2tYOF {
    font-weight: 500;
    font-size: 18px;
    line-height: 140%;
  }
}
.pzaf7 {
  font-size: 18px;
  line-height: 140%;
}
@media (max-width: 971px) {
  .pzaf7 {
    font-weight: 400;
    font-size: 15px;
    line-height: 140%;
  }
}
