.IEoBU {
  height: 100%;
}
.IEoBU :not(img) {
  height: 100%;
}
._1O6vT {
  display: block;
  width: 100%;
  margin-top: 8px;
  text-align: right;
  color: #9ea5ad;
}
.s79L_ {
  display: block;
}
._2XF-B {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: transparent;
}
._2cj4Q {
  visibility: hidden;
}
._3Ac3p {
  display: block;
  width: 100%;
  max-width: 100%;
  height: 100%;
}
._3BIkI {
  visibility: hidden;
}
._401ji {
  border-radius: 20px;
}
