._1qnVQ {
  margin-bottom: 20px;
}
._3pJxw {
  position: relative;
}
._1ccYY {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 16px 0;
}
._1ccYY:before {
  content: '';
  position: absolute;
  top: -60px;
  right: -24px;
  left: -24px;
  bottom: 0;
  border-radius: 20px;
  background-color: #f4f4f1;
}
@media (max-width: 567px) {
  ._1ccYY {
    margin-bottom: 0;
    padding-bottom: 38px;
  }
}
._1caIw {
  width: calc(50% - 8px);
}
._1caIw:not(:last-child) {
  margin-bottom: 16px;
}
@media (max-width: 567px) {
  ._1caIw {
    width: 100%;
  }
}
._3-Gds {
  padding: 12px 0 12px 18px;
}
._1iyCt {
  position: absolute;
  width: 100%;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s ease-in, visibility 0.35s ease-in;
}
.trUqO {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 567px) {
  .trUqO {
    display: block;
    text-align: center;
  }
}
._3A7jQ {
  color: #474b2f;
}
@media (max-width: 567px) {
  ._3A7jQ {
    margin-bottom: 16px;
  }
}
.TD9Wv a {
  font-size: 15px;
  color: #a1a490;
}
.TD9Wv a svg {
  color: #d7d8d1;
}
.TD9Wv a:last-child {
  font-weight: 400;
}
._1q5zv {
  height: 56px;
  z-index: 1;
  transition: height 0.35s;
}
._3KnWc {
  font-weight: 600;
  color: #ef6c00;
}
._1jNIj ._1q5zv {
  height: 190px;
}
@media (max-width: 567px) {
  ._1jNIj ._1q5zv {
    height: 160px;
  }
}
._1ZCLF ._1iyCt {
  opacity: 1;
  visibility: visible;
  transition: opacity 200ms ease-out, visibility 200ms ease-out;
}
._2x91C {
  color: #ef6c00;
  margin: 20px 0;
}
