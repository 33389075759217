._16PxJ {
  padding-top: 40px;
  padding-bottom: 40px;
  font-size: 0;
  text-align: center;
}
._1azQ6 {
  display: inline-block;
  width: 48px;
  height: 48px;
  border: 3px solid currentColor;
  border-radius: 50%;
  box-sizing: border-box;
  color: #e4e7d7;
  animation: UVSx7 0.75s infinite linear;
}
._1azQ6:before,
._1azQ6:after {
  content: '';
  position: absolute;
  top: -3px;
  left: -3px;
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit;
  box-sizing: border-box;
}
._1azQ6,
._1azQ6:before {
  border-color: currentColor transparent transparent transparent;
}
._1azQ6:before {
  display: inline-block;
  animation: UVSx7 1.5s infinite ease;
}
@-moz-keyframes UVSx7 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes UVSx7 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes UVSx7 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes UVSx7 {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
