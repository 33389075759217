._1HY7_ {
  padding-bottom: 60px;
}
._1rI3v {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}
._2Fexz {
  margin-right: 24px;
}
@media (max-width: 567px) {
  ._2Fexz {
    margin-right: 12px;
  }
}
._3pLks {
  min-width: auto;
}
@media (max-width: 674px) {
  ._3pLks {
    width: 160px;
  }
}
._2PA94 {
  display: flex;
  margin-bottom: 60px;
}
._2PA94:last-child {
  margin-bottom: 0;
}
@media (max-width: 971px) {
  ._2PA94 {
    flex-direction: column;
  }
}
._13Epk {
  flex: 1 1 0%;
  width: calc(100% - 300px);
}
@media (max-width: 971px) {
  ._13Epk {
    width: 100%;
    margin-bottom: 40px;
  }
}
._2djL2 {
  padding: 0;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 20px;
  padding-bottom: 40px;
}
._2djL2 a {
  color: #a1a490;
  border-bottom: 1px solid;
}
@media (hover: hover) {
  ._2djL2 a:hover {
    color: #474b2f;
  }
}
._2rDmU {
  margin-bottom: 48px;
}
