.fTutk {
  position: relative;
}
._3Ab-H {
  width: 180px;
  border-color: #eceee4;
  transition: width 200ms cubic-bezier(0.075, 0.82, 0.165, 1);
}
@media (max-width: 567px) {
  ._3Ab-H {
    width: auto;
  }
}
.SUWzq {
  width: 500px;
  border-color: #a1a492;
}
@media (max-width: 1439px) {
  .SUWzq {
    width: 280px;
  }
}
@media (max-width: 567px) {
  .SUWzq {
    width: auto;
  }
}
._1UE5_ {
  position: absolute;
  top: 0;
  right: 6px;
  height: 100%;
  padding: 0 10px;
  border: none;
  line-height: 1;
  cursor: pointer;
  color: #d7d8d1;
  background-color: transparent;
}
@media (hover: hover) {
  ._1UE5_ {
    transition: color 0.35s;
  }
  .ODts6 {
    transition: opacity 0.35s;
  }
  ._1UE5_:hover {
    color: #a1a490;
  }
  ._1UE5_:hover .ODts6 {
    opacity: 1;
  }
}
.ODts6 {
  display: inline-block;
  width: 20px;
  height: 20px;
  color: #474b2f;
  vertical-align: middle;
}
._3064W {
  height: auto;
}
._3064W ._3Ab-H input {
  padding: 8px 1px;
  border: none;
  border-bottom: 2px solid #474b2f;
  border-radius: 0;
  font-size: 31px;
  font-weight: 600;
  line-height: 22px;
  color: #474b2f;
  background-color: transparent;
}
@media (max-width: 567px) {
  ._3064W ._3Ab-H input {
    font-size: 18px;
  }
}
._3064W ._3Ab-H input::placeholder {
  font-style: italic;
  font-weight: normal;
}
._3064W ._1UE5_ {
  display: none;
}
