.Mc9_q {
  flex-basis: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 82px;
}
.N08Uq {
  max-width: 620px;
  background-color: #f4f4f1;
  border-radius: 20px;
  padding-top: 60px;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: relative;
}
.ZvZ5g {
  margin-top: 16px;
}
._1MDse {
  width: 100%;
  height: auto !important;
}
._2Ng34 {
  width: 100%;
  max-width: 320px;
  padding-left: 15px;
  padding-right: 15px;
}
._2HfWA {
  margin-left: 16px;
}
.XtlZW {
  line-height: 20px;
  font-size: 13px;
  height: 16px;
  color: #ef6c00;
  margin-top: 4px;
}
._1csAz {
  position: absolute !important;
  width: 320px;
  bottom: -22px;
}
