.H5dQG {
  display: flex;
  flex-direction: column;
}
._2jrNK {
  border-radius: 20px;
  overflow: hidden;
  background-color: #f4f4f1;
}
._2jrNK:last-child {
  margin-bottom: 0;
}
._1TxyI {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 330px;
  padding-bottom: 42px;
  background-color: inherit;
}
.pVNnR {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: calc(100% - 114px);
  margin-bottom: auto;
  text-align: center;
}
.pVNnR a,
.pVNnR img {
  max-width: 99.999%;
}
.pVNnR a {
  display: block;
  opacity: 1;
  transition: opacity 0.35s;
  backface-visibility: hidden;
}
@media (hover: hover) {
  .pVNnR a:hover {
    opacity: 0.6;
  }
}
._7RlAB {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 58px;
  overflow: hidden;
}
.Oxsp_ {
  width: 100%;
  margin-top: auto;
  text-align: center;
  font-family: "EuclidCircularB";
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  color: #484b32;
}
.Oxsp_ b {
  color: #000;
}
._1-YZ9 {
  position: relative;
  align-self: center;
  height: 40px;
  margin-top: calc(36px / -2);
  z-index: 1;
}
