.rKv1X {
  margin-bottom: 60px;
}
@media (max-width: 971px) {
  .rKv1X {
    padding-top: 0;
  }
}
@media (max-width: 567px) {
  .rKv1X {
    margin-bottom: 40px;
    padding-top: 20px;
  }
}
._1FYCP {
  position: relative;
  padding-top: 24px;
}
