._3yUsz {
  padding-bottom: 60px;
}
._2u3iG {
  max-width: 620px;
}
._2sW2X {
  max-width: 1000px;
  overflow-y: auto;
}
._2sW2X table {
  width: 100%;
  min-width: 800px;
  table-layout: fixed;
  margin-bottom: 24px;
}
._2sW2X table td,
._2sW2X th {
  padding: 10px;
  border: 1px solid #a1a490;
}
._2qyyw {
  margin-bottom: 24px;
  font-family: EuclidCircularB;
  font-style: normal;
  font-size: 48px;
  font-weight: 600;
  line-height: 1.25;
  font-stretch: normal;
  letter-spacing: normal;
}
@media (max-width: 971px) {
  ._2qyyw {
    font-size: 31px;
    line-height: 1.29;
  }
}
.ldXLs ul {
  list-style: disc;
  margin: 0 0 19px 20px;
}
.ldXLs ul li {
  padding: 0 0 17px 10px;
}
.ldXLs h2 {
  font-family: EuclidCircularB;
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  font-stretch: normal;
  letter-spacing: normal;
  margin-bottom: 24px;
}
@media (max-width: 567px) {
  .ldXLs h2 {
    font-size: 15px;
    line-height: 1.6;
  }
}
.ldXLs,
.ldXLs p {
  font-family: NoticiaText;
  font-style: normal;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  font-stretch: normal;
  letter-spacing: normal;
  margin-bottom: 24px;
}
@media (max-width: 567px) {
  .ldXLs,
  .ldXLs p {
    font-size: 15px;
    line-height: 1.6;
  }
}
.ldXLs a {
  box-shadow: inset 0 -1px 0 0 #474b2f;
}
._3yUsz hr {
  margin-bottom: 24px;
  border: none;
  border-bottom: 1px solid #eceee3;
}
