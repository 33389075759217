._1znp8 {
  position: relative;
  flex-shrink: 0;
  min-width: 152px;
  margin-top: 12px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 12px 39px 0 rgba(198,198,198,0.55);
}
.TM0Zi {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;
  padding: 7px 20px;
  cursor: pointer;
  color: #474b2f;
}
@media (hover: hover) {
  .TM0Zi {
    transition: color 0.35s;
  }
  .TM0Zi:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
.ExGxx {
  color: #a1a490;
}
._3qJ-q {
  max-height: 24px;
  max-width: 24px;
  margin-right: 8px;
}
