._15XGg {
  padding: 32px 40px;
  border-radius: 20px;
  color: #a1a490;
  background-color: #f4f4f1;
}
@media (max-width: 1079px) {
  ._15XGg {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media (max-width: 971px) {
  ._15XGg {
    padding: 24px 16px;
  }
}
@media (max-width: 674px) {
  ._15XGg {
    margin-right: -32px;
    margin-left: -32px;
    padding-right: 56px;
    padding-left: 56px;
    border-radius: 0;
  }
}
@media (max-width: 567px) {
  ._15XGg {
    margin-right: -15px;
    margin-left: -15px;
    padding: 32px 15px;
  }
}
.GlRPc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
  white-space: nowrap;
}
@media (max-width: 1439px) {
  .GlRPc {
    flex-direction: column;
    padding-right: 12px;
    padding-left: 12px;
  }
}
@media (max-width: 1238px) {
  .GlRPc {
    margin-bottom: 0;
    padding-bottom: 32px;
  }
}
._1ix_Q {
  margin-right: 6px;
  color: #484b32;
  order: 1;
}
@media (max-width: 1439px) {
  ._1ix_Q {
    margin-right: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
}
@media (max-width: 1238px) {
  ._1ix_Q {
    margin-top: 12px;
  }
}
._3TtEG {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
}
._7v96d {
  margin-bottom: 32px;
}
._7v96d:last-child {
  margin-bottom: 0;
}
._15mhV {
  margin-bottom: 2px;
}
._15mhV:last-child {
  margin-bottom: 0;
}
._15mhV:first-child ._3BXKt {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
._15mhV:last-child ._3BXKt {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
._3jJK4 {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
}
._3_XII {
  margin-bottom: 8px;
  color: #484b32;
}
.a9XMD {
  margin-bottom: 32px;
  color: #484b32;
}
.a9XMD:last-child {
  margin-bottom: 0;
}
._31M_Q {
  width: 100%;
  height: 44px;
  justify-content: center;
  margin-right: 12px;
  padding: 12px 16px;
  vertical-align: middle;
}
._31M_Q:last-child {
  margin-right: 0;
}
._31M_Q:last-child {
  background-color: transparent;
}
._31M_Q svg {
  vertical-align: top;
}
.xl2DI {
  display: inline-flex;
  width: 100%;
  text-align: center;
  cursor: pointer;
}
@media (max-width: 567px) {
  ._31M_Q {
    justify-content: center;
    width: 100%;
  }
  .xl2DI {
    width: 100%;
  }
}
@media (min-width: 1239px) {
  .BBMur {
    padding: 16px 8px 24px;
  }
}
@media (min-width: 1239px) {
  .BBMur .GlRPc {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 16px;
    padding-right: 16px;
    padding-left: 16px;
  }
}
@media (min-width: 1239px) {
  .BBMur ._1ix_Q {
    margin-top: 0;
    order: 0;
  }
}
@media (min-width: 1239px) {
  .BBMur ._7v96d {
    margin-bottom: 24px;
  }
}
@media (min-width: 1239px) {
  .BBMur .a9XMD {
    margin-bottom: 16px;
  }
}
