.iFZQ_ {
  visibility: hidden;
  overflow: hidden;
  font-size: 15px;
}
.Xwd5B {
  position: relative;
  display: flex;
}
._33KzK {
  position: relative;
}
._1NK_G {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}
._30CXY {
  flex-wrap: wrap;
  white-space: normal;
}
._30CXY ._2OP2k {
  margin-top: 8px;
}
._2OP2k {
  display: inline-block;
  margin-right: 8px;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: inherit;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;
  color: #a1a490;
}
._2OP2k:last-child {
  margin-right: 0;
}
._3sWNN {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  max-width: 45px;
  height: 28px;
  text-align: center;
  vertical-align: top;
  user-select: none;
}
._1k1DK {
  color: inherit;
}
.QTyrw {
  visibility: visible;
}
._2RNAz ._2OP2k {
  color: #fff;
  background-color: rgba(0,0,0,0.64);
}
@media (hover: hover) {
  ._2RNAz ._2OP2k {
    transition: color 0.35s, background-color 0.35s;
  }
  ._2RNAz ._2OP2k:hover {
    color: #fff;
    background-color: #454c52;
  }
}
.V_eu_ ._2OP2k {
  background-color: #eceee4;
  color: #484b32;
  box-shadow: inset 0 0 0 2px #eceee3;
}
@media (hover: hover) {
  .V_eu_ ._2OP2k {
    transition: color 0.35s, background-color 0.35s, box-shadow 0.35s;
  }
  .V_eu_ ._2OP2k:hover {
    color: #484b32;
    background-color: #cdcfbf;
    box-shadow: inset 0 0 0 2px #cdcfbf;
  }
}
._24Maz ._2OP2k {
  box-shadow: inset 0 0 0 2px #eceee3;
}
@media (hover: hover) {
  ._24Maz ._2OP2k {
    transition: color 0.35s, background-color 0.35s;
  }
  ._24Maz ._2OP2k:hover {
    color: #474b2f;
    background-color: #eceee3;
  }
}
._3sXMK ._24Maz ._2OP2k {
  border-color: #f4f4f1;
  color: #98b206;
}
@media (hover: hover) {
  ._3sXMK ._24Maz ._2OP2k:hover {
    background-color: #f4f4f1;
  }
}
._3Bi9L ._33KzK {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 674px) {
  ._3Bi9L ._33KzK {
    display: block;
  }
}
._3Bi9L ._1NK_G {
  text-align: left;
}
._3Bi9L ._30CXY {
  justify-content: flex-end;
}
@media (max-width: 674px) {
  ._3Bi9L ._30CXY {
    justify-content: flex-start;
  }
}
._2g-gw {
  display: block;
  width: 9px;
  height: 13px;
  vertical-align: middle;
}
.f-p3t {
  padding: 2px 12px;
}
.OxHzc {
  padding: 4px 8px;
}
