._2rkZK {
  overflow: hidden;
}
._15VYc {
  margin: 24px auto;
  max-width: 590px;
}
._15VYc>div {
  width: 140%;
}
._1CT-l {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 24px auto;
  max-width: 590px;
}
._1wJSR {
  color: #484b32;
  min-height: 44px;
  height: 100%;
}
._1wJSR span {
  white-space: normal !important;
  text-align: left;
}
._1wJSR svg {
  color: #c4c4c4;
  fill: currentColor;
}
._1wJSR:hover .wpkSH {
  color: #484b32;
}
