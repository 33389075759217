.heaZF {
  position: relative;
  padding: 75px 32px 0;
  border-radius: 20px;
}
@media (max-width: 674px) {
  .heaZF {
    border-radius: 0;
  }
}
@media (max-width: 567px) {
  .heaZF {
    padding-top: 60px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
._1Difd {
  left: 26px;
}
@media (max-width: 567px) {
  ._1Difd {
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
._1Rdaz {
  background-size: 80% auto;
  border-color: #f9fbf7;
  background-color: #fff;
}
._2snLj {
  height: 3px;
  margin: 0 -32px;
  border: none;
  background-color: #fff;
  position: relative;
}
._1yjJ1 {
  padding-top: 15px;
  padding-bottom: 25px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
._2XqDb {
  display: unset;
}
.eCykC {
  margin-bottom: 26px;
  word-break: break-word;
}
@media (max-width: 567px) {
  ._1WEeQ {
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: 18px;
    line-height: 1.333;
  }
}
.QgTWa {
  display: flex;
  padding-top: 25px;
  padding-bottom: 25px;
}
@media (max-width: 567px) {
  .QgTWa {
    display: block;
  }
}
._2RNDT {
  flex: 1 1 0%;
  max-width: 300px;
  padding-right: 20px;
}
@media (max-width: 567px) {
  ._2RNDT:not(:last-child) {
    margin-bottom: 16px;
  }
}
._1aaof {
  margin-bottom: 7px;
  color: #a1a490;
}
._3thO6 {
  font-weight: 600;
  word-break: break-word;
  color: #a1a490;
}
@media (hover: hover) {
  ._3thO6 {
    transition: color 0.35s;
  }
  ._3thO6:hover {
    color: #474b2f;
  }
}
._34SdI {
  margin-right: -32px;
  margin-left: -32px;
  border-top: 5px solid #fff;
  border-bottom: 5px solid #fff;
}
._3JvkR {
  position: relative;
}
@media (max-width: 567px) {
  ._3JvkR {
    padding-top: 0;
  }
}
._3JvkR p {
  color: rgba(71,75,47,0.6);
}
._3JvkR article {
  padding-top: 46px;
  padding-bottom: 100px;
}
.mzndU {
  margin-right: 24px;
  margin-bottom: 26px;
  min-width: auto;
  background-color: transparent;
  color: #a1a490;
  box-shadow: inset 0 0 0 3px #eceee3;
  transition: color 0.35s, background-color 0.35s;
}
@media (hover: hover) {
  .mzndU {
    transition: color 0.35s, background-color 0.35s;
  }
}
.mzndU:hover {
  color: #474b2f;
  box-shadow: inset 0 0 0 3px #eceee3;
  background-color: transparent;
}
[class*='_lightTheme'] ._2snLj {
  background-color: #f9fbf7;
}
[class*='_lightTheme'] ._34SdI {
  border-color: #f9fbf7;
}
