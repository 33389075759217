._2_nV4 {
  max-width: 100%;
  visibility: hidden;
  white-space: nowrap;
}
._3_EV9 {
  visibility: visible;
}
._1L9O_ {
  background-color: #eceee4;
  color: #141414;
}
._1L9O_:hover {
  background-color: #cdcfbf;
}
._1BD9M {
  background-color: #484b32;
  color: #f9fbf7;
}
._1BD9M:before {
  border: none;
}
._1fKpi {
  padding-left: 6px;
  color: #141414;
}
._1ynH1 button {
  border-color: #eceee3;
}
._1ynH1 ._1fKpi {
  font-weight: 400;
  color: #a1a490;
}
._2wTTt {
  white-space: normal;
}
@media (max-width: 971px) {
  ._2wTTt {
    white-space: nowrap;
  }
}
._2wTTt ._1L9O_ {
  margin-bottom: 4px;
}
@media (max-width: 971px) {
  ._2wTTt ._1L9O_ {
    margin-bottom: 0;
  }
}
.DqgH2 {
  width: fit-content;
  min-width: 0;
}
