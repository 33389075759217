.rJWjZ {
  display: block;
  padding: 20px;
  background-color: #fff;
}
@media (max-width: 1079px) {
  .rJWjZ {
    position: relative;
    margin-top: 32px;
    padding: 40px 15px 16px 16px;
  }
}
@media (hover: hover) {
  .rJWjZ:hover .CS3Po {
    transform: scale(1.1);
  }
  .rJWjZ:hover .THIyQ {
    color: #a1a490;
  }
}
._25tz_ {
  position: relative;
  display: block;
  max-width: 100%;
}
._3qVYU {
  position: relative;
  display: block;
  width: 96px;
  height: 96px;
  border-radius: 20px;
  float: left;
  overflow: hidden;
  transform: translateZ(0);
}
@media (max-width: 1079px) {
  ._3qVYU {
    position: absolute;
    top: -32px;
    left: 16px;
    width: 64px;
    height: 64px;
    margin-bottom: 8px;
  }
}
.CS3Po {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #000;
  transition: transform 0.35s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.tbjG4 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-left: 26px;
}
@media (max-width: 1079px) {
  .tbjG4 {
    width: 100%;
    padding-left: 0;
  }
}
.THIyQ {
  flex: 1 1 1;
  margin-bottom: 12px;
  word-break: break-word;
  transition: color 0.35s;
}
.THIyQ a {
  color: inherit;
}
._1Hu46 {
  flex-shrink: 0;
  overflow: visible;
}
