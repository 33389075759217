._3sAFg {
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
}
._1NphX {
  height: 100%;
  border-radius: 0;
}
@media (max-width: 1439px) {
  ._1NphX {
    margin: 4px 0;
  }
}
@media (min-width: 1440px) {
  ._2yQS2 {
    display: flex;
    flex-wrap: wrap;
  }
}
@media (min-width: 1440px) {
  ._2zVBD {
    width: 608px;
  }
  ._2zVBD:not(:last-child) {
    margin-right: 4px;
  }
}
._2zVBD:nth-child(odd) ._1NphX {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
@media (max-width: 1439px) {
  ._2zVBD:nth-child(odd) ._1NphX {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 0;
  }
}
._2zVBD:nth-child(even) ._1NphX {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
@media (max-width: 1439px) {
  ._2zVBD:nth-child(even) ._1NphX {
    border-top-right-radius: 0;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}
._267ZH {
  width: calc(100% - 234px);
  line-height: 1.333;
}
@media (max-width: 567px) {
  ._267ZH {
    width: 100%;
  }
}
.OOTbQ ._267ZH {
  width: 100%;
}
._3g4Vj {
  margin-bottom: 4px;
  font-size: 31px;
  font-weight: 600;
  line-height: 1.29032;
}
@media (max-width: 971px) {
  ._3g4Vj {
    font-size: 18px;
    line-height: 1.333;
  }
}
@media (max-width: 567px) {
  ._3g4Vj {
    display: none;
  }
}
.OOTbQ ._3g4Vj {
  display: none;
}
.IFTTo {
  margin-bottom: 12px;
  color: #b2b7a3;
}
@media (max-width: 567px) {
  .IFTTo {
    display: none;
  }
}
.OOTbQ .IFTTo {
  display: none;
}
._2VhgQ {
  color: #474b2f;
}
.Snx7j {
  font-size: 18px;
  line-height: 1.333;
  color: #a1a490;
}
@media (max-width: 971px) {
  .Snx7j {
    font-size: 15px;
  }
}
._3fwE0 {
  font-weight: 600;
}
._3C60y {
  display: inline-block;
  vertical-align: middle;
  color: #000;
}
._3C60y svg {
  display: block;
}
._3fwE0 .Qsc-2 ._3fwE0 {
  padding-right: 10px;
  padding-left: 10px;
}
._1UMpx {
  width: 210px;
  border: 2px solid #eceee3;
  border-radius: 4x;
}
@media (max-width: 567px) {
  ._1UMpx {
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 24px;
    order: -1;
  }
}
._3o-cg {
  height: 100px;
  background-repeat: no-repeat;
  background-size: cover;
}
._1MyTX {
  padding: 6px 16px 16px;
  text-align: center;
}
._2IhSN {
  margin-bottom: 6px !important;
  font-size: 12px;
  color: #4a4a4a;
}
.pGJ1U {
  display: block;
  margin-bottom: 8px;
  padding: 15px 15px 14px;
  border-radius: 4px;
  font-size: 15px;
  color: #fff;
  background-color: #02a562;
}
.BmW6I {
  font-size: 12px;
  color: #4a4a4a;
}
.OOTbQ ._1UMpx {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 24px;
  order: -1;
}
._2Lgoc {
  width: 254px;
}
._2Lgoc .Snx7j {
  margin-bottom: 0;
  font-size: 15px;
  line-height: 1.333;
}
