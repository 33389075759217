.Pr8lI {
  position: relative;
  padding: 74px 32px 0;
  border-radius: 20px;
}
@media (max-width: 674px) {
  .Pr8lI {
    border-radius: 0;
  }
}
@media (max-width: 567px) {
  .Pr8lI {
    padding-top: 60px;
    padding-right: 15px;
    padding-left: 15px;
  }
}
._3a62g {
  left: 26px;
}
@media (max-width: 567px) {
  ._3a62g {
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
  }
}
._83tgB {
  border-color: #f9fbf7;
  background-color: #fff;
  border-radius: 20px;
}
._s3hZ {
  height: 10px;
  margin: 0 -32px;
  border: none;
  background-color: #fff;
}
._3uBe3 {
  padding-bottom: 24px;
  color: #a1a490;
}
._3LDjd {
  margin-bottom: 8px;
  color: #474b2f;
  word-break: break-word;
}
@media (max-width: 567px) {
  ._1aEtU {
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: 18px;
    line-height: 1.333;
  }
}
._3FdJG {
  margin-top: 24px;
}
._34eHb {
  padding-top: 24px;
  padding-bottom: 32px;
  white-space: break-spaces;
}
._2ZDnY {
  font-size: 15px;
  line-height: 24px;
}
.T_3xv {
  margin: 24px 0;
}
._3MSVZ {
  padding: 16px;
  border-radius: 20px;
  background: #f4f4f1;
  display: flex;
}
._2lTG {
  margin-left: 17px;
  color: #a1a490;
}
.UWoFk {
  color: inherit;
  text-decoration: underline;
}
[class*='_lightTheme'] ._s3hZ {
  background-color: #f9fbf7;
}
[class*='_lightTheme'] ._3XW_A {
  border-color: #f9fbf7;
}
