.BHwgl {
  margin-bottom: 60px;
}
@media (min-width: 972px) {
  .BHwgl {
    padding-top: 20px;
  }
}
@media (min-width: 1440px) {
  .BHwgl {
    margin-top: -40px;
    padding-top: 0;
  }
}
@media (max-width: 1079px) {
  .BHwgl {
    margin-bottom: 40px;
  }
}
._3OQIt {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 32px;
}
@media (max-width: 1439px) {
  ._3OQIt {
    margin-bottom: 60px;
  }
}
@media (max-width: 1079px) {
  ._3OQIt {
    margin-bottom: 40px;
  }
}
@media (max-width: 971px) {
  ._3OQIt {
    display: block;
    margin-bottom: 40px;
  }
}
.epSm4 {
  display: block;
  margin-bottom: 7px;
  font-size: 15px;
  font-weight: 600;
  color: #227422;
}
@media (max-width: 971px) {
  .epSm4 {
    margin-bottom: 20px;
  }
}
._3hRLD {
  max-width: 600px;
}
@media (max-width: 1079px) {
  ._3hRLD {
    max-width: 410px;
  }
}
@media (max-width: 971px) {
  ._3hRLD {
    max-width: 100%;
  }
}
.siZfi:last-child {
  margin-bottom: 0;
}
@media (max-width: 971px) {
  .siZfi {
    margin-bottom: 24px;
  }
}
@media (max-width: 971px) {
  ._3P9XS {
    font-size: 31px;
    line-height: 1.29032;
  }
}
._1Iuqb {
  padding: 0;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 20px;
  color: inherit;
}
._1Iuqb a {
  color: #a1a490;
}
@media (hover: hover) {
  ._1Iuqb a:hover {
    color: #474b2f;
  }
}
._7uH8w {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  color: #474b2f;
}
@media (hover: hover) {
  ._7uH8w {
    transition: color 0.35s;
  }
  ._7uH8w:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
._2uAVl {
  display: inline-block;
  padding-right: 7px;
  color: #a1a490;
}
._1Glkw {
  display: inline-block;
  margin-right: 5px;
  color: #1a1f19;
}
._1Glkw svg {
  display: block;
}
