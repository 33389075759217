._1aOPY {
  margin-bottom: 0;
}
@media (max-width: 971px) {
  ._1aOPY {
    display: flex;
    flex-direction: column;
  }
}
.gyuWm {
  margin-bottom: 40px;
}
@media (max-width: 971px) {
  .gyuWm {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
  }
}
._2lbJ9 {
  margin-bottom: 40px;
}
@media (max-width: 971px) {
  ._2lbJ9 {
    margin-bottom: 24px;
    font-size: 31px;
  }
}
.cwLhk {
  font-size: 48px;
  line-height: 1.25;
}
@media (max-width: 971px) {
  .cwLhk {
    font-size: 31px;
  }
}
._2Xw26 {
  margin-bottom: 40px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (max-width: 971px) {
  ._2Xw26 {
    margin-bottom: 40px;
    order: -1;
  }
}
@media (max-width: 567px) {
  ._2Xw26 {
    margin-right: -15px;
    margin-bottom: 24px;
    margin-left: -15px;
    border-radius: 0;
  }
}
._1BEF- {
  display: block;
  width: 100%;
  border-radius: 20px;
}
@media (max-width: 567px) {
  ._1BEF- {
    border-radius: 0;
  }
}
._1_euN {
  margin-bottom: 40px;
  padding: 0;
}
@media (max-width: 971px) {
  ._1_euN {
    margin-bottom: 24px;
  }
}
@media (max-width: 674px) {
  ._1_euN {
    margin-bottom: 60px;
    font-size: 18px;
    line-height: 1.333;
  }
}
@media (max-width: 567px) {
  ._1_euN {
    margin-bottom: 24px;
  }
}
._3RBAK {
  position: relative;
  width: 100%;
  margin-bottom: 90px;
}
@media (max-width: 567px) {
  ._3RBAK {
    margin-bottom: 60px;
  }
}
.Y7pba {
  padding-top: 40px;
  text-align: center;
}
._3RdcZ {
  padding-right: 16px;
  padding-left: 16px;
  border-color: #eff0e7;
  font-size: 15px;
  color: #474b2f;
}
._3RdcZ svg {
  display: none;
}
@media (max-width: 567px) {
  ._3RdcZ {
    max-width: 100%;
    font-size: 15px;
  }
}
._3RdcZ:before {
  border-width: 2px;
}
._3rqDw {
  padding: 62px 0 20px;
}
._2Qm7F {
  margin-bottom: 62px;
}
@media (max-width: 674px) {
  ._2Qm7F {
    margin-bottom: 107px;
  }
}
@media (max-width: 567px) {
  ._2Qm7F {
    margin-bottom: 62px;
  }
}
.JZZHM {
  margin-top: 40px;
  margin-bottom: 40px;
}
@media (max-width: 971px) {
  .JZZHM {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (max-width: 567px) {
  .JZZHM {
    padding-right: 15px;
    padding-left: 15px;
  }
}
