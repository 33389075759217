/* ** --- Reset CSS --- ** */
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
sub,
sup,
tt,
var,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
  -webkit-tap-highlight-color: rgba(0,0,0,0);
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
th,
td {
  text-align: left;
}
textarea {
  resize: none;
}
address {
  font-style: normal;
}
input,
textarea,
button,
select {
  outline: none;
  font: inherit;
  -webkit-font-smoothing: inherit;
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
/* ** --- Box sizing --- ** */
*,
*:before,
*:after {
  box-sizing: border-box;
}
/* ** --- Fonts --- ** */
@font-face {
  font-display: swap;
  font-family: 'EuclidCircularBLight';
  font-style: normal;
  font-weight: normal;
  src: url("/static/fonts/euclid_circular_b_light-webfont.woff") format('woff'), url("/static/fonts/euclid_circular_b_light-webfont.woff") format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'EuclidCircularBMedium';
  font-style: normal;
  font-weight: normal;
  src: url("/static/fonts/euclid_circular_b_medium-webfont.woff") format('woff'), url("/static/fonts/euclid_circular_b_medium-webfont.woff") format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'EuclidCircularB';
  font-style: normal;
  font-weight: normal;
  src: url("/static/fonts/euclid_circular_b_regular-webfont.woff") format('woff'), url("/static/fonts/euclid_circular_b_regular-webfont.woff") format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'EuclidCircularB';
  font-style: normal;
  font-weight: 600;
  src: url("/static/fonts/euclid_circular_b_semibold-webfont.woff") format('woff'), url("/static/fonts/euclid_circular_b_semibold-webfont.woff") format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'EuclidCircularB';
  font-style: normal;
  font-weight: 700;
  src: url("/static/fonts/euclid_circular_b_bold-webfont.woff") format('woff'), url("/static/fonts/euclid_circular_b_bold-webfont.woff") format('woff2');
}
@font-face {
  font-display: swap;
  font-family: 'NoticiaText';
  font-style: normal;
  font-weight: normal;
  src: url("/static/fonts/noticiatext-regular-webfont.woff") format('woff'), url("/static/fonts/noticiatext-regular-webfont.woff") format('woff2');
}
/* ** --- Document --- ** */
html,
body {
  height: 100%;
  font: normal 15px/1.333 'EuclidCircularB', Arial, Helvetica, sans-serif;
  color: #474b2f;
  background: #f9fbf7;
  -webkit-text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}
html.theme-article,
html.theme-article body {
  background: #fff;
}
body.scroll-locked {
  position: fixed;
  left: 0;
  width: 100%;
  overflow: hidden;
  box-sizing: border-box;
}
body.scroll-locked.blured main {
  position: relative;
  filter: blur(8px);
}
body.scroll-locked.blured main:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(249,251,247,0.88);
  z-index: 99;
}
.xl-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.8);
  z-index: 999;
}
#xl_auth {
  margin: 0 auto;
}
#inside-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
#inside-wrapper iframe {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(1);
  display: block;
  width: 310px !important;
  height: 518px !important;
  z-index: 999;
}
@media (max-width: 567px) {
  #inside-wrapper iframe {
    transform: translate(-50%, -50%) scale(0.85);
  }
}
@media (max-width: 320px) {
  #inside-wrapper iframe {
    transform: translate(-50%, -50%) scale(0.65);
  }
}
/* ** --- Links --- ** */
a {
  text-decoration: none;
  color: #474b2f;
}
/* ** --- Icons --- ** */
svg {
  vertical-align: middle;
}
/* ** --- Theme --- ** */
._lightTheme {
  background-color: #fff;
}
