._2p98Q {
  background-color: #f9fbf7;
}
._4lpkO {
  position: relative;
  max-width: 560px;
  margin: 0 auto;
  padding: 80px 0 0;
}
@media (max-width: 971px) {
  ._4lpkO {
    max-width: 100%;
    padding: 80px 32px 0;
  }
}
@media (max-width: 567px) {
  ._4lpkO {
    padding: 65px 15px 0;
  }
}
._3JNmA {
  position: relative;
  margin-bottom: 22px;
}
._2JC0O {
  position: absolute;
  top: -50px;
  left: 0;
  background-size: 80% 80%;
}
@media (max-width: 971px) {
  ._2JC0O {
    left: 32px;
  }
}
@media (max-width: 567px) {
  ._2JC0O {
    top: -24px;
    left: 15px;
    max-width: 64px;
    max-height: 64px;
  }
}
.lfnY7 {
  margin-bottom: 10px;
}
._1O7G2 {
  font-size: 15px;
  color: #b2b7a3;
}
.WE9Yw {
  margin-bottom: 56px;
}
@media (max-width: 567px) {
  .WE9Yw {
    margin-bottom: 50px;
  }
}
._2dZeM {
  color: #ef6c00;
  margin: 20px 0;
}
