._1Tm2A {
  background: #000 !important;
  border: 1px solid #212121 !important;
  box-sizing: border-box !important;
  border-radius: 6px !important;
  font-family: EuclidCircularB !important;
  font-size: 10px !important;
  line-height: 16px !important;
  text-align: center !important;
  letter-spacing: 0.5px !important;
  text-transform: uppercase !important;
  color: #fff !important;
}
