.bsSeh {
  display: flex;
  margin-bottom: 6px;
}
._36c36 {
  color: #ef6c00;
}
.zqvsv {
  color: #227422;
}
._2zko_ {
  display: flex;
  color: #474b2f;
}
