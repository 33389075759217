._2GDwK {
  background: #fff;
  border-radius: 20px;
  padding: 60px;
  margin: 40px 0;
}
@media (max-width: 1439px) {
  ._2GDwK {
    margin-right: 8.6%;
    margin-left: 8.6%;
  }
}
@media (max-width: 1079px) {
  ._2GDwK {
    margin: 24px 0;
  }
}
@media (max-width: 567px) {
  ._2GDwK {
    padding: 24px;
  }
}
._3CZMA {
  margin-bottom: 24px;
  font-weight: 600;
  font-size: 31px;
  line-height: 120%;
}
@media (max-width: 1079px) {
  ._3CZMA {
    font-size: 26px;
  }
}
._3C2Be {
  font-size: 18px;
  line-height: 140%;
  white-space: pre-line;
  margin-bottom: 8px;
}
@media (max-width: 1079px) {
  ._3C2Be {
    font-size: 15px;
  }
}
._3C2Be>a {
  text-decoration: underline;
}
._1c-0o {
  margin: 0 auto;
  max-width: 590px;
}
