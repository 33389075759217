._2IUwt {
  display: flex;
  flex-wrap: wrap;
}
.uVDus {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: 24px;
  text-align: center;
}
.uVDus:last-of-type {
  margin-right: 0;
}
._1IEXS {
  height: 100%;
}
.uVDus ._1IEXS path {
  fill: var(--textColor, #474b2f);
}
@media (hover: hover) {
  ._1IEXS path {
    transition: fill 0.35s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }
  .uVDus:hover ._1IEXS path {
    fill: $darkGreyColor;
  }
}
