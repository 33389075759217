.H-w5p {
  position: relative;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
@media (max-width: 567px) {
  .H-w5p {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}
._1fL6P {
  line-height: 24px;
  margin-bottom: 8px;
}
._30ekG {
  margin-bottom: 24px;
  line-height: 20px;
  font-size: 15px;
  color: #a1a490;
}
._1wBMb {
  display: inline-flex;
  color: #474b2f;
  height: 100%;
  width: fit-content;
  min-height: 44px;
}
._1wBMb > span {
  display: inline-flex;
  align-items: center;
  line-height: 20px;
  white-space: normal;
  text-align: left;
}
._2sS-X {
  margin-left: 7px;
}
