._14jWf {
  padding: 40px 40px 60px;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 3px 0 rgba(0,0,0,0.08);
}
@media (max-width: 674px) {
  ._14jWf {
    padding-bottom: 40px;
  }
}
@media (max-width: 567px) {
  ._14jWf {
    margin-right: -15px;
    margin-left: -15px;
    padding-right: 15px;
    padding-left: 15px;
    border-radius: 0;
  }
}
@media (max-width: 1439px) {
  .jkGmM {
    display: none;
  }
}
@media (max-width: 1079px) {
  .jkGmM {
    display: block;
  }
}
@media (max-width: 971px) {
  .jkGmM {
    display: none;
  }
}
._2XIIF {
  padding-right: 360px;
}
@media (max-width: 1439px) {
  ._2XIIF {
    padding-right: 40px;
  }
}
@media (max-width: 1079px) {
  ._2XIIF {
    padding-right: 310px;
  }
}
@media (max-width: 971px) {
  ._2XIIF {
    padding-right: 40px;
  }
}
@media (max-width: 567px) {
  ._2XIIF {
    padding-right: 15px;
  }
}
