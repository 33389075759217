._1Gdsv {
  background-position: 50% 50%;
  background-size: cover;
}
._1M3qC {
  display: flex;
  min-height: 104px;
  padding: 24px 32px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
@media (max-width: 567px) {
  ._1M3qC {
    display: block;
  }
}
._1u-mr {
  flex: 1 1 0;
  display: flex;
  color: #fff;
}
@media (max-width: 567px) {
  ._1u-mr {
    margin-bottom: 16px;
  }
}
.-j0B3 {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  margin-right: 22px;
  border-radius: 50%;
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
  text-align: center;
  color: #ef6c00;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}
.-j0B3:last-child {
  margin-right: 0;
}
@media (max-width: 567px) {
  .-j0B3 {
    margin-right: 16px;
  }
}
.G8qaE {
  margin-bottom: 4px;
}
._12HGX {
  flex-shrink: 0;
  align-self: center;
  font-size: 15px;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,0.08);
  color: #474b2f;
}
@media (hover: hover) {
  ._12HGX {
    transition: color 0.35s;
  }
  ._12HGX:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
@media (max-width: 567px) {
  ._12HGX {
    margin-left: 56px;
  }
}
._1bsKn {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  vertical-align: sub;
  color: #f6b6dd;
}
._1UOHc {
  display: block;
  width: 100%;
  margin-top: 4px;
  text-align: right;
  color: #9ea5ad;
}
