._3aGx0 {
  width: 200px;
  height: 325px;
  margin-right: 8px;
  background: #f9fbf7;
  border-radius: 20px;
  color: #484b32;
}
._2Ijkl {
  border-radius: 20px 20px 0 0;
  height: 200px;
}
._3FDTv {
  font-weight: 500;
  font-size: 18px;
}
._2YiMO {
  font-size: 12px;
  margin: 8px 0;
}
._2Ijkl,
._2YiMO,
._3FDTv {
  line-height: 140%;
}
._1FSVt {
  color: #3b722d;
  font-size: 12px;
}
._3_e7z {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 12px 16px;
}
