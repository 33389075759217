._3ZfJj {
  margin: 24px auto;
  max-width: 590px;
}
._3ZfJj>div {
  width: 136%;
}
._1DeJ8 ._1PHbh {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
._1DeJ8 ._2TQvX {
  width: 200px;
  margin-top: 20px;
}
._1DeJ8 ._2TQvX img {
  width: 100%;
  object-fit: contain;
}
._2W6K7 {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  max-width: 590px;
  margin: 24px auto;
}
.ZVIjJ {
  color: #484b32;
  min-height: 44px;
  height: 100%;
}
.ZVIjJ span {
  white-space: normal !important;
  text-align: left;
}
.ZVIjJ span img {
  width: 13px;
  margin-left: 5px;
}
.ZVIjJ svg {
  color: #c4c4c4;
  fill: currentColor;
}
.ZVIjJ:hover ._21NUV {
  color: #484b32;
}
._21NUV {
  margin-left: 5px;
}
