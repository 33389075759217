._2zY98 {
  max-height: 670px;
  max-width: 400px;
  background-color: transparent;
}
@media (max-width: 575px) {
  ._2zY98 {
    padding: 4px;
  }
}
._3V2ZE {
  padding: 0;
  scrollbar-width: thin;
  scrollbar-color: rgba(0,0,0,0.25) transparent;
}
._3V2ZE::-webkit-scrollbar {
  width: 8px;
}
._3V2ZE::-webkit-scrollbar-track {
  background: transparent;
}
._3V2ZE::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,0.25);
  border-radius: 6px;
  border: 3px solid transparent;
}
._1-bq9 {
  margin-top: -15px;
  color: rgba(255,255,255,0.6);
  text-align: center;
  font-size: 12px;
  line-height: 20px;
}
._36ufm {
  text-decoration: underline;
  color: rgba(255,255,255,0.6);
}
._36ufm:hover {
  color: #fff;
}
