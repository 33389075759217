._3Qj_9 {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #000;
  z-index: 2;
  width: 516px;
  margin: 0 24px 24px 0;
  --card-shadow-color: #cfdbf9;
}
@media (max-width: 1439px) {
  ._3Qj_9 {
    width: 450px;
  }
}
@media (max-width: 567px) {
  ._3Qj_9 {
    width: 280px;
    gap: 12px;
  }
}
._2pWiO {
  --card-shadow-color: #cfdbf9;
}
._3ENLr {
  --card-shadow-color: #facad9;
}
._1jyLM {
  --card-shadow-color: #d9d2f3;
}
._2aUpg {
  --card-shadow-color: #eef3b6;
}
._2f-AV {
  --card-shadow-color: #bbdcb0;
}
._1eIgH {
  width: 387px;
}
._1eIgH ._3VCma {
  height: 201px;
}
@media (max-width: 567px) {
  ._1eIgH ._3VCma {
    max-height: initial;
  }
}
._3Qj_9::before {
  position: absolute;
  z-index: -1;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: var(--card-shadow-color);
  transform: translate(24px, 24px);
}
@media (max-width: 971px) {
  ._3Qj_9::before {
    transform: translate(15px, 15px);
  }
}
._3VCma {
  width: 100%;
  height: 290px;
  border-radius: 20px;
  flex-shrink: 1;
}
._3VCma img {
  height: 100%;
  object-fit: cover;
}
@media (max-width: 1439px) {
  ._3VCma {
    height: 257px;
  }
}
@media (max-width: 567px) {
  ._3VCma {
    max-height: 158px;
    height: auto;
  }
}
._2a2E3:hover ~ ._3snqu ._13QBD {
  color: #227422;
}
._3snqu {
  justify-content: space-between;
}
._3snqu,
._2SJVJ {
  display: flex;
  align-items: center;
  gap: 16px;
}
@media (max-width: 567px) {
  ._3snqu,
  ._2SJVJ {
    gap: 8px;
  }
}
@media (max-width: 567px) {
  ._3snqu {
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
  }
}
._2SJVJ {
  flex-shrink: 1;
  flex-grow: 1;
  min-width: 50%;
}
@media (max-width: 567px) {
  ._2SJVJ {
    flex-shrink: 1;
  }
}
._3yozq {
  display: flex;
  flex-direction: column;
}
._3SJv- {
  display: flex;
  align-items: center;
  gap: 8px;
}
a:hover ._1w4ke {
  color: #227422;
}
a:hover ._3hKeS {
  background-color: #474b2f;
  border-color: #474b2f;
  color: #fff;
}
._1w4ke {
  font-size: 12px;
  font-weight: 400;
  transition: color 0.2s;
}
._3hKeS {
  border: 1px solid #000;
  border-radius: 8px;
  padding: 2px 4px;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.4;
  text-transform: uppercase;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s;
}
.cGR1d {
  width: 72px;
  height: 72px;
  border-radius: 20px;
  border: 2px solid #f9fbf7;
}
.cGR1d img {
  height: 100%;
  object-fit: cover;
}
@media (max-width: 567px) {
  .cGR1d {
    width: 48px;
    height: 48px;
    border-radius: 8px;
  }
}
._1CVXW:hover + ._2efTi ._1w4ke {
  color: #227422;
}
._1CVXW:hover + ._2efTi ._3hKeS {
  background-color: #474b2f;
  border-color: #474b2f;
  color: #fff;
}
._13QBD {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  transition: color 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
  overflow-wrap: break-word;
}
._13QBD:hover {
  color: #227422;
}
.rzd1D {
  max-width: 100%;
  flex-shrink: 1;
  min-width: 45px;
}
