._2yum8 {
  overflow: hidden;
}
._3BaHs {
  margin: 24px auto;
  max-width: 590px;
}
._3BaHs>div {
  width: 140%;
}
._2ZYgf {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin: 24px auto;
  max-width: 590px;
}
.FIMvd {
  color: #484b32;
  min-height: 44px;
  height: 100%;
}
.FIMvd span {
  white-space: normal !important;
  text-align: left;
}
.FIMvd svg {
  color: #c4c4c4;
  fill: currentColor;
}
.FIMvd:hover ._2FTcg {
  color: #484b32;
}
._2FTcg {
  margin-left: 5px;
}
