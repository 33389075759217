._32GFB {
  margin-bottom: 40px;
}
._3FhEH {
  display: inline-block;
  margin-right: 8px;
}
._3FhEH:last-child {
  margin-right: 0;
}
@media (max-width: 567px) {
  ._3FhEH {
    display: block;
    margin-right: 0;
    margin-bottom: 8px;
  }
  ._3FhEH:last-child {
    margin-bottom: 0;
  }
}
._2vI9f {
  display: flex;
  padding-right: 16px;
  padding-left: 16px;
  align-items: center;
  justify-content: center;
}
@media (max-width: 567px) {
  ._2vI9f {
    width: 100%;
  }
}
._2vI9f > span {
  display: flex;
  align-items: center;
}
@media (max-width: 567px) {
  ._2vI9f > span {
    justify-content: center;
  }
}
._1huV_ {
  margin-right: 8px;
  margin-bottom: 1px;
}
._2vI9f:hover {
  color: #474b2f;
}
