._33aEv {
  display: flex;
}
._1B8dk {
  margin-right: 40px;
  display: flex;
  flex-direction: column;
  width: 740px;
}
._1B8dk:last-child {
  margin-right: 0;
}
@media (max-width: 1439px) {
  ._1B8dk {
    width: calc(50% - 20px);
  }
}
@media (max-width: 1079px) {
  ._1B8dk {
    display: none;
  }
}
.QZMXD {
  flex-grow: 1;
  margin-bottom: 4px;
  background-color: #f4f4f1;
}
.QZMXD:last-child {
  margin-bottom: 0;
}
.QZMXD:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.QZMXD:last-child {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
._25uf0 {
  width: 440px;
}
@media (max-width: 1439px) {
  ._25uf0 {
    width: calc(50% - 20px);
  }
}
@media (max-width: 1079px) {
  ._25uf0 {
    width: 100%;
    border-radius: 20px;
  }
}
@media (max-width: 674px) {
  ._25uf0 {
    margin-right: -32px;
    margin-left: -32px;
    width: calc(100% + 32px * 2);
    border-radius: 0;
  }
}
@media (max-width: 567px) {
  ._25uf0 {
    margin-right: -15px;
    margin-left: -15px;
    width: calc(100% + 15px * 2);
  }
}
