._13cRt {
  position: relative;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  margin-top: 40px;
  margin-bottom: 32px;
}
._3gwgD {
  border: solid 6px #eceee3;
  width: 128px !important;
  display: flex;
  height: 128px !important;
}
._3q_Kq {
  justify-content: center;
  background-color: rgba(71,75,47,0.6);
  align-items: center;
  position: absolute;
  color: #fff;
  top: 0;
  font-size: 18px;
  font-weight: 600;
  margin-left: auto;
  margin-right: auto;
  display: none;
  width: 100%;
  height: 100%;
  text-align: center;
}
._13cRt:hover > ._3q_Kq {
  display: flex;
}
