._1TjT- {
  max-height: 100%;
  width: 112px;
  height: 112px;
  border-radius: 50%;
  background-color: #f9fbf7;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
._3G9TK {
  border: 6px solid #eee;
}
