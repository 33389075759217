._2bwIz {
  padding-top: 32px;
  padding-bottom: 45px;
  font-family: 'NoticiaText', Arial, Helvetica, sans-serif;
  font-size: 23px;
  line-height: 1.57;
}
._2bwIz,
._2bwIz p {
  color: #484b32;
}
._2bwIz a {
  box-shadow: none;
  transition: color 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media (hover: hover) {
  ._2bwIz a:hover {
    color: #227422;
  }
}
._3KnoY {
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: #fff;
}
._3KnoY a {
  position: relative;
  color: #fff;
  z-index: 1;
}
