._1mVSc {
  position: relative;
  display: block;
  width: 280px;
  padding-top: 24px;
  padding-left: 29px;
}
@media (max-width: 1079px) {
  ._1mVSc {
    width: 262px;
  }
}
@media (max-width: 971px) {
  ._1mVSc {
    width: calc(50% - 20px);
    margin-bottom: 24px;
  }
}
@media (max-width: 567px) {
  ._1mVSc {
    width: 100%;
  }
}
._1-1iU {
  position: absolute;
  top: 0;
  left: 0;
  width: 256px;
  height: 120px;
  border-radius: 30px;
  background-color: #f4f4f1;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  transition: transform 0.35s;
}
@media (max-width: 1079px) {
  ._1-1iU {
    width: 238px;
  }
}
@media (max-width: 971px) {
  ._1-1iU {
    width: calc(100% - 24px);
    background-position: center;
  }
}
._19XVg {
  padding-top: 96px;
  border-radius: 30px;
  background-color: #fff;
}
._2DB3y {
  position: relative;
  display: flex;
  align-items: center;
  height: 85px;
  padding: 0 35px 0 30px;
}
@media (max-width: 1238px) {
  ._2DB3y {
    min-height: 80px;
  }
}
._2LvB3 {
  position: absolute;
  top: 50%;
  left: -22px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background-color: #f9fbf7;
  transform: translateY(-50%);
}
.Xwk9M {
  display: inline-block;
  opacity: 0.15;
}
.Xwk9M svg {
  display: block;
}
._3vJzY {
  height: 20px;
  overflow: hidden;
}
@media (hover: hover) {
  ._1mVSc:hover ._1-1iU {
    transform: translate(11px, 11px);
  }
}
[class*='_lightTheme'] ._19XVg {
  background-color: #f9fbf7;
}
[class*='_lightTheme'] ._2LvB3 {
  border-color: #fff;
  background-color: #f9fbf7;
}
