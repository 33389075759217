._25liE {
  padding-bottom: 60px;
}
._2t--N {
  max-width: 620px;
}
._31bqN {
  margin-bottom: 24px;
  font-family: EuclidCircularB;
  font-style: normal;
  font-size: 48px;
  font-weight: 600;
  line-height: 1.25;
  font-stretch: normal;
  letter-spacing: normal;
}
@media (max-width: 971px) {
  ._31bqN {
    font-size: 31px;
    line-height: 1.29;
  }
}
._3HUfH h2 {
  font-family: EuclidCircularB;
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  font-stretch: normal;
  letter-spacing: normal;
}
@media (max-width: 567px) {
  ._3HUfH h2 {
    font-size: 15px;
    line-height: 1.6;
  }
}
._3HUfH,
._3HUfH p {
  font-family: NoticiaText;
  font-style: normal;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  font-stretch: normal;
  letter-spacing: normal;
}
@media (max-width: 567px) {
  ._3HUfH,
  ._3HUfH p {
    font-size: 15px;
    line-height: 1.6;
  }
}
._25liE hr {
  margin-bottom: 24px;
  border: none;
  border-bottom: 1px solid #eceee3;
}
._25liE a {
  box-shadow: inset 0 -1px 0 0 #474b2f;
}
