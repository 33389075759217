._2TYDI {
  max-height: 60px;
  margin-top: 8px;
  overflow: hidden;
  font-family: inherit;
  font-size: 15px;
  line-height: 20px;
  color: #fff;
}
@media (max-width: 674px) {
  ._2TYDI {
    margin-top: 16px;
  }
}
._2TYDI .word {
  vertical-align: top;
}
._2TYDI .last {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
