.zbdLI {
  min-height: 650px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 567px) {
  .zbdLI {
    min-height: 500px;
  }
}
