._2SRYa {
  display: flex;
  flex-wrap: wrap;
  margin-right: -20px;
  margin-bottom: -40px;
  margin-left: -20px;
}
@media (max-width: 971px) {
  ._2SRYa {
    margin-right: -16px;
    margin-left: -16px;
  }
}
._2zwnH {
  width: 280px;
  margin-right: 20px;
  margin-bottom: 40px;
  margin-left: 20px;
}
@media (max-width: 1079px) {
  ._2zwnH {
    width: 260px;
  }
}
@media (max-width: 971px) {
  ._2zwnH {
    width: calc(100% / 2 - 32px);
    margin-right: 16px;
    margin-left: 16px;
  }
}
@media (max-width: 674px) {
  ._2zwnH {
    width: 100%;
  }
}
