._2eCmW {
  display: flex;
  flex-wrap: wrap;
  margin-right: -40px;
}
._2JUUO {
  width: calc(50% - 40px);
  margin-right: 40px;
}
@media (max-width: 674px) {
  ._2JUUO {
    width: 100%;
  }
  ._2JUUO:not(:last-child) {
    margin-bottom: 40px;
  }
}
