._2tZHS {
  visibility: hidden;
  overflow: hidden;
  font-size: 15px;
}
.Pxt2c {
  position: relative;
  display: flex;
}
._2cZbV {
  position: relative;
}
._1DJ1R {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
}
._2UrU1 {
  flex-wrap: wrap;
  white-space: normal;
}
._2UrU1 .psgom {
  margin-top: 8px;
}
.psgom {
  display: inline-block;
  margin-right: 8px;
  padding: 4px 12px;
  border-radius: 20px;
  font-size: inherit;
  font-weight: 600;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;
  color: #a1a490;
}
.psgom:last-child {
  margin-right: 0;
}
.g-ny_ {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 45px;
  max-width: 45px;
  height: 28px;
  text-align: center;
  vertical-align: top;
  user-select: none;
}
._3TyXt {
  color: inherit;
}
._2YW5i {
  visibility: visible;
}
._1uu-R .psgom {
  color: #fff;
  background-color: rgba(0,0,0,0.64);
}
@media (hover: hover) {
  ._1uu-R .psgom {
    transition: color 0.35s, background-color 0.35s;
  }
  ._1uu-R .psgom:hover {
    color: #fff;
    background-color: #454c52;
  }
}
._3FwBa .psgom {
  background-color: #eceee4;
  color: #484b32;
  box-shadow: inset 0 0 0 2px #eceee3;
}
@media (hover: hover) {
  ._3FwBa .psgom {
    transition: color 0.35s, background-color 0.35s, box-shadow 0.35s;
  }
  ._3FwBa .psgom:hover {
    color: #484b32;
    background-color: #cdcfbf;
    box-shadow: inset 0 0 0 2px #cdcfbf;
  }
}
._3oqmG .psgom {
  box-shadow: inset 0 0 0 2px #eceee3;
}
@media (hover: hover) {
  ._3oqmG .psgom {
    transition: color 0.35s, background-color 0.35s;
  }
  ._3oqmG .psgom:hover {
    color: #474b2f;
    background-color: #eceee3;
  }
}
.KoaEP ._3oqmG .psgom {
  border-color: #f4f4f1;
  color: #98b206;
}
@media (hover: hover) {
  .KoaEP ._3oqmG .psgom:hover {
    background-color: #f4f4f1;
  }
}
._1VAyV ._2cZbV {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 674px) {
  ._1VAyV ._2cZbV {
    display: block;
  }
}
._1VAyV ._1DJ1R {
  text-align: left;
}
._1VAyV ._2UrU1 {
  justify-content: flex-end;
}
@media (max-width: 674px) {
  ._1VAyV ._2UrU1 {
    justify-content: flex-start;
  }
}
.-EBmU {
  display: block;
  width: 9px;
  height: 13px;
  vertical-align: middle;
}
._1rAPZ {
  padding: 2px 12px;
}
._2ryNQ {
  padding: 4px 8px;
}
