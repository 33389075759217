._2Sjzp {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-width: 590px;
  margin: 24px auto;
}
._2PdNP {
  width: 24px;
  color: #fff;
}
._2PdNP > path {
  fill: #fff;
}
.QAPbb {
  width: calc(50% - 8px);
}
@media (max-width: 674px) {
  .QAPbb {
    width: 100%;
  }
}
