._2UQ4S {
  padding-bottom: 60px;
}
@media (min-width: 1440px) {
  ._2UQ4S {
    margin-top: -20px;
  }
}
@media (max-width: 1439px) {
  ._2UQ4S {
    padding-top: 20px;
  }
}
@media (max-width: 674px) {
  ._2UQ4S {
    padding-top: 0;
    padding-bottom: 40px;
  }
}
@media (max-width: 567px) {
  ._2UQ4S {
    padding-top: 20px;
  }
}
._2Tssh {
  margin-bottom: 40px;
}
.Qp5Bo {
  margin-bottom: 40px;
}
@media (max-width: 674px) {
  .Qp5Bo {
    margin-left: 20px;
  }
}
@media (max-width: 567px) {
  .Qp5Bo {
    margin-left: 0;
  }
}
._3Pr2e {
  margin-bottom: 40px;
}
@media (max-width: 567px) {
  ._3Pr2e {
    margin-bottom: 24px;
  }
}
._3SyIO {
  margin-bottom: 40px;
}
._2j0sX {
  margin-top: 60px;
  margin-bottom: 60px !important;
}
@media (max-width: 971px) {
  ._2j0sX {
    margin-top: 0;
  }
}
._1YlqY {
  margin-bottom: 40px;
}
