.IdkmS {
  position: relative;
  display: inline-block;
  font-size: 14px;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
}
.IdkmS:last-child {
  margin-right: 0;
}
@media (max-width: 567px) {
  .IdkmS {
    display: flex;
  }
}
._2b1Lu {
  position: absolute;
  left: -9999px;
  opacity: 0;
  z-index: -1;
}
._3rRbN {
  display: flex;
  align-items: center;
  white-space: normal;
}
._3rRbN:not(.wPWhT):hover ._3A8Mb {
  transition-duration: 0.175s;
}
._3rRbN ._3A8Mb {
  position: relative;
  width: 16px;
  min-width: 16px;
  height: 16px;
  margin-right: 10px;
  box-shadow: inset 0 0 0 1px #c8c9bf;
  transition: background 0.35s, box-shadow 0.35s;
}
._3rRbN ._3A8Mb ._2ebz8 {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  opacity: 0;
  transition: opacity 0.35s;
  transform: translate(-50%, -50%);
}
._3rRbN ._2b1Lu:checked + ._3A8Mb ._2ebz8 {
  opacity: 1;
  transition-duration: 0.35s;
}
._3rRbN ._3yx6T {
  display: inline-block;
  font-size: 15px;
  line-height: 1.333;
  vertical-align: middle;
  color: #a1a490;
}
._32qxh:not(.wPWhT):hover ._3A8Mb {
  transition-duration: 0.175s;
}
._32qxh ._3A8Mb {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 24px;
  height: 24px;
  margin-right: 15px;
  border-radius: 50%;
  vertical-align: middle;
  transition: box-shadow 0.35s;
}
._32qxh ._3A8Mb:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  opacity: 0;
  background: $brandColor;
  transition: opacity 0.35s;
  transform: translateX(-50%) translateY(-50%);
}
._32qxh ._2b1Lu:checked + ._3A8Mb:after {
  opacity: 1;
  transition-duration: 0.175s;
}
._32qxh ._3yx6T {
  display: inline-block;
  font-size: 18px;
  line-height: 1.444;
  vertical-align: middle;
}
@media (max-width: 567px) {
  ._32qxh ._3yx6T {
    padding-right: 30px;
    line-height: 1;
    vertical-align: middle;
    white-space: normal;
  }
}
._2tBNW {
  color: #fff;
}
.wPWhT {
  cursor: default;
}
._2ebz8 {
  display: inline-block;
}
