._1ZcVq {
  position: relative;
  opacity: 1;
  padding: 10px;
}
.KLZTk {
  padding: 10px;
  text-wrap: nowrap;
  cursor: pointer;
  font-size: 16px;
  font-weight: 300;
  font-family: EuclidCircularBLight;
  color: #000;
}
.KLZTk:hover {
  opacity: 0.4;
}
._2LHtL {
  color: #30148a;
}
