._3ztQJ {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  text-align: center;
  white-space: nowrap;
  z-index: 6;
  transition: opacity 150ms ease-in;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 971px) {
  ._3ztQJ {
    padding: 0;
  }
}
._3ztQJ:before {
  content: '';
  display: inline-block;
  width: 0;
  height: 100%;
  vertical-align: middle;
}
._1rndb {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 150%;
  height: 150%;
  background-color: rgba(40,36,35,0.1);
  transform: translateX(-50%) translateY(-50%);
}
._3o7q7 {
  display: inline-flex;
  padding: 40px 0;
  text-align: left;
  vertical-align: middle;
  white-space: normal;
}
@media (max-width: 971px) {
  ._3o7q7 {
    width: 100%;
    min-height: 100%;
    padding: 0;
  }
}
._1QISL {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border: 0;
  border-radius: 100%;
  color: #c8c9bf;
  background-color: rgba(57,57,53,0.88);
  background-image: url("/static/icons/cross.svg");
  background-repeat: no-repeat;
  background-position: center center;
  z-index: 1;
}
.XKift {
  right: -14px;
  opacity: 0.6;
}
@media (hover: hover) {
  .XKift:hover {
    opacity: 1;
  }
}
._3-HwJ {
  position: relative;
  width: 100%;
  box-shadow: 0 2px 6px rgba(40,36,35,0.1);
}
@media (max-width: 567px) {
  ._3-HwJ {
    padding: 0;
  }
}
._1DRkj ._1rndb {
  background-color: rgba(0,0,0,0.5);
}
._1DRkj ._3o7q7 {
  min-height: auto;
  padding: 30px;
}
@media (max-width: 567px) {
  ._1DRkj ._3o7q7 {
    padding-right: 15px;
    padding-left: 15px;
  }
}
._1DRkj ._3-HwJ {
  box-shadow: none;
}
.BiVSF ._1rndb {
  background-color: rgba(0,0,0,0.5);
}
.BiVSF ._3o7q7 {
  min-height: auto;
  padding: 30px;
}
.BiVSF ._3-HwJ {
  box-shadow: none;
  display: flex;
  justify-content: center;
}
._1SbC1 ._3-HwJ {
  background: none;
}
._1RnKS ._1rndb {
  background-color: transparent;
}
.msie ._1RnKS ._1rndb {
  background-color: rgba(249,251,247,0.88);
}
._1RnKS ._3o7q7 {
  height: 100%;
  padding: 0;
}
._1RnKS ._3-HwJ {
  padding-top: 120px;
  box-shadow: none;
}
._3V4GF ._1rndb {
  background-color: #f9fbf7;
}
.NL_98._3ztQJ {
  z-index: 7;
}
.NL_98 ._3o7q7 {
  height: 100%;
  width: 100%;
  padding: 0;
}
.bJxIP._3ztQJ {
  z-index: 7;
}
.bJxIP._3o7q7 {
  width: auto;
  padding: 0;
  margin-top: 104px;
}
@media (max-width: 567px) {
  .bJxIP._3o7q7 {
    width: 100%;
    margin-top: 84px;
  }
}
.ModalContainer-enter {
  opacity: 0.01;
}
.ModalContainer-enter-active {
  opacity: 1;
  transition: opacity 150ms ease-in;
}
.ModalContainer-enter-done {
  opacity: 1;
}
.ModalContainer-exit {
  opacity: 0;
  transition: opacity 75ms ease-in;
}
.ModalContainer-exit-active {
  opacity: 0;
}
.ModalContainer-exit-done {
  opacity: 0;
}
