._3ZJ3X {
  position: fixed;
  z-index: 5;
  bottom: 24px;
  left: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border: 0;
  border-radius: 20px;
  background-color: rgba(249,251,247,0.5);
  box-shadow: inset 0 0 0 3px #eceee4;
}
.Ni0u3 {
  transform: rotate(-90deg);
  color: #551a8b;
}
