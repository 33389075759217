._3ewug {
  position: relative;
  width: 384px;
  padding: 28px 32px 42px 32px;
  margin: 0;
}
@media (max-width: 567px) {
  ._3ewug {
    width: 320px;
  }
}
._3pvAr {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgba(57,57,53,0.88);
  transition: opacity 150ms ease-in;
  position: absolute;
  top: 20px;
  right: 20px;
}
._2K4gI {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: auto;
  margin-left: auto;
  vertical-align: middle;
}
@media (hover: hover) {
  ._3pvAr {
    transition: background-color 0.35s;
  }
  ._3pvAr:hover {
    background-color: #1a1f19;
  }
}
._1sNag {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 25px;
}
._36o-e {
  margin-bottom: 25px;
  font-size: 15px;
  color: #a1a490;
}
.dOGF0 {
  margin-bottom: 20px;
}
.dOGF0:last-child {
  padding-bottom: 0;
}
._2p5jY {
  display: block;
  border: none;
  padding: 12px 16px;
  border-radius: 20px;
  background-color: #e9ff4c;
  cursor: pointer;
  color: inherit;
  font-size: 15px;
  font-weight: 600;
  position: absolute;
  bottom: -18px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
._2p5jY:disabled {
  cursor: not-allowed;
  background-color: #f4f4f1;
}
