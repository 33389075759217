.OmECd {
  position: relative;
  width: 310px;
  height: 236px;
  border-radius: 8px;
  color: #fff;
  transition: all 200ms ease-in-out;
  margin-right: 6px;
}
.OmECd:hover {
  width: 394px;
  height: 300px;
}
._1Qe-y {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: transform 0.35s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transform: translateZ(0);
  will-change: transform;
  border-radius: 8px;
}
._1Qe-y:before {
  background-color: inherit !important;
}
._3KCu1 {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
  word-break: break-word;
}
.Ia5lc {
  position: relative;
  height: 100%;
  transition: all 100ms ease-out;
}
._8LBRf {
  border: 2px solid #fff;
  flex-shrink: 0;
}
._3BJO8 {
  font-size: 28px;
  text-align: center;
  margin: 4px 0;
}
._16Gau {
  font-size: 15px;
  color: #fff;
  opacity: 0.5;
  text-align: center;
}
