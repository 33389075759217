._1h37Y {
  background: #eff6ed;
  border-radius: 20px;
  width: fit-content;
  margin-right: 8px;
  line-height: 140%;
}
._2tZix {
  border-radius: 20px;
}
._1R3Us {
  font-weight: 600;
  font-size: 15px;
}
.KCzbh {
  font-weight: 400;
  font-size: 12px;
}
._2ySZJ {
  margin: 0 auto;
  max-width: 590px;
  padding: 8px 16px;
}
