._36j2K {
  position: relative;
}
@media (max-width: 971px) {
  ._36j2K {
    padding: 16px 34px;
  }
}
._3PNrC {
  flex-shrink: 0;
  max-width: 100%;
}
@media (max-width: 971px) {
  ._3PNrC {
    width: initial;
  }
}
._3PNrC:not(:last-child) {
  margin-right: 40px;
}
@media (max-width: 971px) {
  ._3PNrC:not(:last-child) {
    margin-right: 0;
  }
}
._2g2jf {
  width: 220px;
}
@media (max-width: 971px) {
  ._2g2jf {
    width: initial;
  }
}
._17Jii {
  width: 620px;
}
@media (max-width: 1439px) {
  ._17Jii {
    width: 348px;
  }
}
@media (max-width: 1079px) {
  ._17Jii {
    width: 288px;
  }
}
@media (max-width: 971px) {
  ._17Jii {
    width: initial;
  }
}
._2kQTu {
  width: 216px;
}
@media (max-width: 971px) {
  ._2kQTu {
    width: initial;
  }
}
._3PNrC p {
  word-break: break-word;
}
@media (max-width: 971px) {
  ._3qWZW {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
}
@media (max-width: 971px) {
  ._2fmqe {
    margin-bottom: 12px;
  }
}
._3hxAu,
._3HlFh {
  position: relative;
}
@media (min-width: 972px) {
  ._3hxAu,
  ._3HlFh {
    display: flex;
    align-items: center;
    padding: 20px 40px;
  }
}
._2mMga {
  cursor: pointer;
}
._3cR9C {
  overflow: hidden;
}
._3HlFh {
  overflow: hidden;
}
@media (min-width: 972px) {
  ._3HlFh {
    align-items: stretch;
    padding-top: 24px;
    padding-bottom: 24px;
  }
}
@media (max-width: 971px) {
  ._3HlFh {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: 16px;
    padding-bottom: 16px;
  }
  ._3HlFh ._2kQTu {
    width: 50%;
  }
}
@media (max-width: 674px) {
  ._3HlFh {
    padding-bottom8: 8px;
  }
}
@media (max-width: 567px) {
  ._3HlFh {
    padding-top: 24px;
  }
  ._3HlFh ._3PNrC {
    margin-bottom: 24px;
  }
  ._3HlFh ._3PNrC:last-child {
    margin-bottom: 0;
  }
  ._3HlFh ._2g2jf {
    margin-right: auto;
    margin-left: auto;
  }
  ._3HlFh ._2kQTu {
    width: 100%;
  }
}
._3HlFh ._17Jii {
  color: #a1a490;
}
@media (max-width: 674px) and (min-width: 568px) {
  ._3HlFh ._2g2jf {
    width: 45%;
    width: calc(50% - 32px);
  }
  ._3HlFh ._2kQTu {
    width: 50%;
  }
}
._2RxA3 {
  margin-right: 40px;
}
@media (max-width: 971px) {
  ._2RxA3 {
    margin-right: 0;
  }
}
._2X_sw {
  display: flex;
  margin-bottom: 24px;
}
@media (max-width: 971px) {
  ._2X_sw {
    position: relative;
  }
}
@media (max-width: 567px) {
  ._2X_sw {
    display: block;
  }
}
@media (max-width: 971px) {
  ._3Sd4o {
    margin-bottom: 24px;
  }
}
@media (max-width: 567px) {
  ._3Sd4o {
    width: 100%;
  }
}
._34ozf {
  margin-bottom: 40px;
}
._34ozf:last-child {
  margin-bottom: 0;
}
@media (max-width: 971px) {
  ._34ozf {
    margin-bottom: 24px;
  }
}
._3KkS0 {
  margin-bottom: 10px;
  color: #a1a490;
}
._2qEDu {
  margin-bottom: 3px;
}
._1q0-s {
  color: #a1a490;
}
._1S-HX {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  border-width: 2px;
  border-color: #f4f4f1;
  background-color: #fff;
  background-size: contain;
}
@media (min-width: 972px) {
  ._1S-HX {
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -24px;
    transform: translate3d(-50%, 0, 0);
  }
}
@media (max-width: 971px) {
  ._1S-HX {
    margin-right: 16px;
  }
}
.q7ICz {
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  cursor: pointer;
  color: #d7d8d1;
  background: transparent;
}
@media (min-width: 972px) {
  .q7ICz {
    top: 16px;
    right: 22px;
  }
}
@media (max-width: 971px) {
  .q7ICz {
    right: 0;
    bottom: 0;
  }
}
@media (hover: hover) {
  .q7ICz {
    opacity: 0;
    transition: color 0.35s, opacity 0.35s;
  }
  ._36j2K:not(._2cGJB):hover .q7ICz {
    opacity: 1;
    visibility: visible;
    transition: color 0.35s, opacity 0.35s;
  }
  .q7ICz:hover {
    color: #474b2f;
  }
}
._2cGJB .q7ICz {
  opacity: 1;
  transform: rotate(180deg);
}
.q7ICz svg {
  position: relative;
  transform: rotate(90deg);
}
.EmZ0m {
  padding-top: 5px;
  word-break: break-word;
}
@media (max-width: 971px) {
  ._3NBK3 {
    position: absolute;
    top: calc(100% + 24px);
    left: 0;
    width: 258px;
    margin-right: 0;
  }
}
@media (max-width: 567px) {
  ._3NBK3 {
    position: static;
    top: initial;
    left: initial;
    width: 100%;
  }
}
._3NBK3 img {
  flex-shrink: 0;
  display: block;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
}
.USRTC {
  padding: 0;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: inherit;
}
._2Lu4w {
  color: #474b2f;
}
._1FLY3 {
  position: relative;
  height: 230px;
  border-radius: 20px;
  overflow: hidden;
}
@media (max-width: 971px) {
  ._1FLY3 {
    width: 100%;
  }
}
._3DaPj {
  color: #474b2f;
}
._3DaPj:first-child {
  margin-top: 0;
}
._3DaPj span {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
._2NNJl {
  margin-bottom: 7px;
}
._2NNJl:last-child {
  margin-bottom: 0;
}
._2NNJl svg {
  margin-right: 10px;
  vertical-align: sub;
  color: #b1b1b1;
}
._1c42X {
  font-weight: 600;
  color: #a1a490;
  color: #a1a490;
}
@media (hover: hover) {
  ._1c42X {
    transition: color 0.35s;
  }
  ._1c42X:hover {
    color: #474b2f;
  }
}
._1c42X svg {
  color: inherit;
}
@media (hover: hover) {
  ._2YFDG {
    display: tresh;
    transition: opacity 0.35s;
  }
  ._1c42X:hover ._2YFDG {
    opacity: 1;
  }
}
._2YFDG {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  opacity: 0.5;
  vertical-align: sub;
}
@media (max-width: 674px) and (max-width: 1079px) {
  ._36j2K {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (max-width: 674px) and (max-width: 971px) {
  ._36j2K {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (max-width: 674px) and (max-width: 567px) {
  ._36j2K {
    padding-right: 15px;
    padding-left: 15px;
  }
}
