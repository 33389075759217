._1G0nY {
  max-width: 484px;
  max-height: 622px;
  box-shadow: 0px 14px 26px rgba(0,0,0,0.12);
  border-radius: 20px;
}
@media (max-width: 575px) {
  ._1G0nY {
    max-width: 336px;
    max-height: 658px;
    height: fit-content;
    padding: 0;
  }
}
.WqtLm {
  height: 230px;
  background-color: #000;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  justify-content: center;
  align-items: center;
}
@media (max-width: 320px) {
  .WqtLm {
    height: 115px;
  }
}
._2EvQo {
  width: 157px;
  fill: #cfdbf9;
}
@media (max-width: 320px) {
  ._2EvQo {
    width: 100px;
  }
}
._3RLi8 {
  display: block;
  padding: 40px;
  color: #000;
  text-align: start;
}
@media (max-width: 674px) {
  ._3RLi8 {
    padding: 24px 20px;
  }
}
._1qnxD {
  font-weight: 600;
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 16px;
}
._3piir {
  font-size: 16px;
  line-height: 24px;
  text-align: left;
  margin-bottom: 5px;
}
._3LFE9 {
  margin: 24px 0;
  width: 230px;
}
._3LFE9 > span {
  color: #484a32;
}
._2x36M {
  font-size: 14px;
  line-height: 20px;
  color: rgba(0,0,0,0.45);
}
._3EsTN {
  color: rgba(0,0,0,0.45);
  text-decoration: underline;
}
.GbyHW {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  padding: 0;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgba(57,57,53,0.88);
  transition: opacity 150ms ease-in;
  position: absolute;
}
.njEhb {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: auto;
  margin-left: auto;
  vertical-align: middle;
}
@media (hover: hover) {
  .GbyHW {
    transition: background-color 0.35s;
  }
  .GbyHW:hover {
    background-color: #1a1f19;
  }
}
