._14OhH {
  margin-bottom: 40px;
}
.ze020 {
  margin-top: 24px;
  margin-bottom: 24px;
}
._36YDx {
  margin-bottom: 40px;
}
._36YDx:last-child {
  margin-bottom: 0;
}
