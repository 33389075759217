.DSIxy {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-right: -40px;
  margin-bottom: -40px;
}
@media (max-width: 971px) {
  .DSIxy {
    justify-content: space-between;
    margin-right: 0;
  }
}
._2pGTv {
  width: 275px;
  margin-right: 40px;
  margin-bottom: 40px;
}
@media (max-width: 1439px) {
  ._2pGTv {
    width: 280px;
  }
}
@media (max-width: 1079px) {
  ._2pGTv {
    width: 260px;
  }
}
@media (max-width: 971px) {
  ._2pGTv {
    width: calc(100% / 2 - (40px / 2));
    margin-right: 0;
  }
}
@media (max-width: 567px) {
  ._2pGTv {
    width: 100%;
  }
}
._2TyGY {
  position: relative;
}
._3nZdF {
  margin-left: 9px;
}
._3eJr8 {
  width: 246px;
}
._3hhyx {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  top: 92px;
  z-index: 3;
}
@media (max-width: 971px) {
  ._3hhyx {
    top: 40px;
    gap: 18px;
  }
}
._1ViGL {
  text-align: center;
  font-size: 31px;
  font-weight: 600;
  line-height: 40px;
}
@media (max-width: 674px) {
  ._1ViGL {
    font-size: 26px;
    line-height: 36px;
  }
}
._2dBAI {
  display: flex;
  gap: 40px;
}
@media (max-width: 971px) {
  ._2dBAI {
    gap: 20px;
  }
}
._2s0zy {
  width: 275px;
  height: 400px;
}
@media (max-width: 1439px) {
  ._2s0zy {
    width: 100%;
  }
  ._2s0zy:nth-child(1) {
    display: none;
  }
}
@media (max-width: 971px) {
  ._2s0zy {
    height: 279px;
  }
}
@media (max-width: 674px) {
  ._2s0zy:nth-child(2) {
    display: none;
  }
}
@media (max-width: 480px) {
  ._2s0zy:nth-child(3) {
    display: none;
  }
}
