._2NP4A {
  padding-bottom: 60px;
}
.S3rEp {
  margin-bottom: 60px;
}
@media (max-width: 1079px) {
  .S3rEp {
    margin-bottom: 40px;
  }
}
._1s95q {
  padding-bottom: 86px;
}
._3m6VX {
  padding-top: 22px;
}
._12Obn {
  margin-bottom: 60px;
}
._3hEyo {
  margin-bottom: 40px;
}
