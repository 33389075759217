.WPfAB {
  width: 100%;
}
.tIXr1 {
  width: 100%;
  margin-bottom: 14px;
}
@media (max-width: 674px) {
  .tIXr1 {
    width: calc(100% + 32px * 2);
    margin-right: -32px;
    margin-left: -32px;
  }
}
@media (max-width: 567px) {
  .tIXr1 {
    width: calc(100% + 15px * 2);
    height: 282px;
    margin-right: -15px;
    margin-left: -15px;
  }
}
._1iiIh {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.ux985 {
  display: flex;
  align-items: center;
  width: 100% !important;
  height: 100%;
}
