._2cko0 {
  padding: 8px 0;
}
._2cko0 > span {
  min-width: 166px;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  color: #a1a490;
}
