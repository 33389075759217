._18eHu {
  margin-bottom: 2px;
  padding-right: 32px;
  padding-left: 32px;
  background-color: #fff;
}
._18eHu:last-child {
  margin-bottom: 0;
}
@media (min-width: 675px) {
  ._18eHu:first-of-type {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  ._18eHu:last-of-type {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}
@media (max-width: 674px) and (max-width: 1079px) {
  ._18eHu {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (max-width: 674px) and (max-width: 971px) {
  ._18eHu {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (max-width: 674px) and (max-width: 567px) {
  ._18eHu {
    padding-right: 15px;
    padding-left: 15px;
  }
}
