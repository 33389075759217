._3F7gJ {
  margin-bottom: 40px;
}
._1l17d {
  border-radius: 20px;
  overflow: hidden;
  transform: translateZ(0);
}
._2jkSM {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 400px;
  padding: 25px 32px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (max-width: 971px) {
  ._2jkSM {
    height: 320px;
  }
}
@media (max-width: 674px) {
  ._2jkSM {
    height: 420px;
    padding-bottom: 32px;
  }
}
@media (max-width: 567px) {
  ._2jkSM {
    padding: 24px;
  }
}
._1Aknp {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 0;
}
._1Qy2Q {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.8));
  z-index: 1;
  transform: translate(0%, 100%);
  height: 50%;
}
._2vf4M {
  position: relative;
}
@media (max-width: 567px) {
  ._2vf4M {
    max-width: none;
    height: 100%;
  }
}
._2_ZQb {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
._3O-tn {
  position: relative;
  display: flex;
  align-items: flex-end;
  margin-top: auto;
}
@media (max-width: 674px) {
  ._3O-tn {
    display: block;
  }
}
._2OOvd {
  flex-shrink: 0;
  width: calc(100% - (1220px - 64px) / 2);
}
@media (max-width: 1439px) {
  ._2OOvd {
    width: calc(100% - (920px - 64px) / 2);
  }
}
@media (max-width: 1079px) {
  ._2OOvd {
    width: calc(100% - (860px - 64px) / 2);
  }
}
@media (max-width: 971px) {
  ._2OOvd {
    width: calc((100% / 2));
  }
}
@media (max-width: 674px) {
  ._2OOvd {
    width: 100%;
    margin-bottom: 16px;
  }
}
.L-CBd {
  font-size: 0;
}
._3a9go {
  max-width: 112px;
  height: 24px;
  margin-left: auto;
}
._3anfp {
  position: relative;
  flex-shrink: 0;
  width: calc(100% - (1220px - 64px) / 2);
  text-align: right;
  z-index: 3;
}
@media (max-width: 1439px) {
  ._3anfp {
    width: calc(100% - (920px - 64px) / 2);
  }
}
@media (max-width: 1079px) {
  ._3anfp {
    width: calc(100% - (860px - 64px) / 2);
  }
}
@media (max-width: 971px) {
  ._3anfp {
    width: calc((100% / 2));
  }
}
@media (max-width: 674px) {
  ._3anfp {
    position: relative;
    width: 100%;
    text-align: left;
  }
}
.KttTL {
  display: flex;
  justify-content: space-between;
}
._1S5Ss {
  display: inline-block;
  margin-right: 25px;
}
._2fvAe {
  display: inline-block;
  margin-top: 10px;
  vertical-align: top;
}
@media (max-width: 674px) {
  ._2fvAe {
    position: absolute;
    left: 0;
    bottom: 0;
    margin-top: 0;
    margin-left: 0;
  }
}
@media (max-width: 674px) {
  ._2l-sU {
    display: none;
  }
}
._2Pbgu {
  display: none;
}
@media (max-width: 674px) {
  ._2Pbgu {
    display: block;
  }
}
@media (max-width: 674px) {
  .az48E ._2OOvd {
    margin-bottom: 40px;
  }
}
