._3N9Hi {
  display: flex;
  width: 800px;
}
@media (max-width: 971px) {
  ._3N9Hi {
    width: 100%;
  }
}
._3N9Hi + ._3N9Hi {
  margin-top: 2px;
}
._3-mhZ {
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  width: 412px;
  padding: 32px 34px 29px 40px;
  background-color: #fff;
}
@media (max-width: 674px) {
  ._3-mhZ {
    display: none;
  }
}
.ylAIn {
  margin-bottom: 14px;
}
._3kMwP {
  position: absolute;
  top: 20px;
  left: 25px;
  width: calc(100% - 50px);
  opacity: 0;
}
@media (max-width: 674px) {
  ._3kMwP {
    opacity: 1;
  }
}
._3kMwP ._23sbY {
  background-color: #d1d5c5;
}
._3kMwP ._1QdCK {
  width: 95%;
}
._3kMwP ._1bLDa {
  width: 75%;
}
._3kMwP ._8ysdD {
  width: 55%;
}
._3l5EY {
  display: flex;
}
._3km7S {
  position: absolute;
  left: -20px;
  bottom: 24px;
  opacity: 0;
}
@media (max-width: 674px) {
  ._3km7S {
    opacity: 1;
  }
}
._3km7S ._23sbY {
  background-color: #d1d5c5;
}
._3yxBR:not(:last-child) {
  margin-right: 23px;
}
._1ivEK {
  display: flex;
}
._8prPl {
  width: 120px;
}
._23sbY {
  position: relative;
  height: 20px;
  overflow: hidden;
  background-color: #eff0ec;
}
._23sbY:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 150%;
  height: 150%;
  background: linear-gradient(135deg, rgba(250,252,255,0) 25%, #fafcff 50%, rgba(250,252,255,0) 75%);
  transform: translateX(-100%);
  animation-name: _2aCM-;
  animation-duration: 1.5s;
  animation-iteration-count: infinite;
}
.T-m5e {
  height: 40px;
}
._1bLDa {
  margin-right: 17px;
}
._1SVqJ {
  margin-right: 75px;
}
._3MrGD {
  margin-right: 9px;
}
.rFLiE {
  margin-right: 45px;
}
._26Z4w {
  width: 40px;
  height: 40px;
  margin-right: 9px;
  border-radius: 20px;
}
._1rPIu {
  margin-right: 66px;
}
._3234X {
  margin-right: 18px;
}
._3_UbI {
  margin-right: 18px;
}
.krnAn {
  width: 100%;
  height: 100%;
}
._3Rdr3 {
  flex-grow: 1;
  margin-bottom: 37px;
}
._3XdKv {
  position: relative;
  flex: 1 1 0;
  min-height: 345px;
}
@media (max-width: 674px) {
  ._3XdKv {
    margin-right: -32px;
  }
}
@media (max-width: 567px) {
  ._3XdKv {
    margin-right: -15px;
  }
}
._2AA-h {
  width: initial;
}
@media (max-width: 674px) {
  ._3N9Hi._2AA-h {
    width: calc(100% + 32px);
    margin-right: -32px;
  }
}
._2AA-h ._3-mhZ {
  flex: 1;
  width: initial;
}
._2AA-h ._3Rdr3 {
  margin-bottom: 25px;
}
._2AA-h ._3XdKv {
  flex: initial;
  width: 400px;
}
@media (max-width: 971px) {
  ._2AA-h ._3XdKv {
    width: 266px;
  }
}
@media (max-width: 674px) {
  ._2AA-h ._3XdKv {
    width: 100%;
  }
}
@media (max-width: 567px) {
  ._2AA-h .krnAn {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}
._1deI9:first-of-type ._3-mhZ {
  border-top-left-radius: 20px;
}
._1deI9:first-of-type .krnAn {
  border-top-right-radius: 20px;
}
@media (max-width: 674px) {
  ._1deI9:first-of-type .krnAn {
    border-top-left-radius: 20px;
    border-top-right-radius: 0;
  }
}
._1deI9:last-of-type ._3-mhZ {
  border-bottom-left-radius: 20px;
}
._1deI9:last-of-type .krnAn {
  border-bottom-right-radius: 20px;
}
@media (max-width: 674px) {
  ._1deI9:last-of-type .krnAn {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 20px;
  }
}
@-moz-keyframes _2aCM- {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
@-webkit-keyframes _2aCM- {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
@-o-keyframes _2aCM- {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
@keyframes _2aCM- {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(100%);
  }
}
