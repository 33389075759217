._1wdKr {
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 32px;
  overflow: hidden;
  background-position: 50% 50%;
  background-size: cover;
}
._1wdKr:hover {
  filter: brightness(96%);
}
@media (max-width: 971px) {
  ._1wdKr {
    padding: 24px;
  }
}
@media (max-width: 567px) {
  ._1wdKr {
    flex-direction: column-reverse;
    padding: 32px 26px;
  }
}
._3Qt6w {
  position: relative;
  padding-right: 32px;
  line-height: 1.333;
  cursor: pointer;
}
@media (max-width: 567px) {
  ._3Qt6w {
    width: 100%;
  }
}
._15xid {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
._1Ul25 {
  margin-bottom: 4px;
  font-size: 31px;
  font-weight: 600;
  line-height: 1.29032;
}
@media (max-width: 971px) {
  ._1Ul25 {
    font-size: 18px;
    line-height: 1.333;
  }
}
@media (max-width: 567px) {
  ._1Ul25 {
    display: none;
  }
}
._3SEl0 {
  margin-bottom: 12px;
  color: #b2b7a3;
}
@media (max-width: 567px) {
  ._3SEl0 {
    display: none;
  }
}
._10_rT {
  color: #474b2f;
}
._1yYph {
  opacity: 0.8;
  font-size: 18px;
  line-height: 1.333;
  color: #a1a490;
}
@media (max-width: 971px) {
  ._1yYph {
    font-size: 15px;
  }
}
._3pljc {
  position: relative;
  min-width: 208px;
  min-height: 232px;
  border: 2px solid #eceee3;
  border-radius: 4px;
  box-sizing: content-box;
}
@media (max-width: 567px) {
  ._3pljc {
    width: 208px;
    height: 232px;
    margin: 0 auto 24px;
  }
}
._3pljc .xpay2Play-widget.xpay2Play-widget__tiny {
  width: 100% !important;
  height: 232px !important;
}
.lC6yS {
  align-self: flex-start;
}
._3I0BN {
  flex-direction: column-reverse;
  padding: 18px;
}
._3I0BN .xpay2Play-widget.xpay2Play-widget__tiny {
  width: 100% !important;
  height: 232px !important;
}
._3I0BN._28kq8 {
  padding: 32px 24px;
}
._3I0BN ._3pljc {
  min-width: 212px;
  min-height: 232px;
  margin-bottom: 24px;
}
._3I0BN ._3Qt6w {
  width: initial;
}
._3I0BN ._1yYph {
  font-size: 15px;
  line-height: 1.333;
}
@media (min-width: 1440px) {
  ._2nnRB {
    height: 100%;
  }
}
