._22UXj {
  margin-bottom: 60px;
}
._3Dog3 {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
  margin-bottom: 40px;
}
.OTaQ- {
  width: calc(100% / 2 - 20px);
}
._1aKu- {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.k7uKb {
  width: calc(100% / 2 - 20px);
}
@media (max-width: 971px) {
  .k7uKb {
    width: calc(100% / 2 - 16px);
  }
}
