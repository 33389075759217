._3bj7t {
  display: flex;
  width: 100%;
}
@media (max-width: 674px) {
  ._3bj7t {
    flex-direction: column-reverse;
    border-radius: 20px;
    overflow: hidden;
    margin: 0 auto 16px;
    max-width: 520px;
  }
}
@media (hover: hover) {
  ._3bj7t:hover .lim6M {
    color: #a1a490;
  }
  ._3bj7t:hover ._3xR-d {
    transform: scale(1.1);
  }
}
._3bj7t + ._3bj7t {
  margin-top: 2px;
}
@media (max-width: 674px) {
  [class*='_latestArticles'] ._3bj7t + ._3bj7t {
    margin-top: 24px;
  }
}
._1u0R5 {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 32px 32px 32px 40px;
  background-color: #fff;
}
@media (max-width: 1238px) {
  ._1u0R5 {
    padding: 24px;
  }
}
@media (max-width: 971px) {
  ._1u0R5 {
    padding: 16px 20px;
  }
}
._2Y5vx {
  margin-bottom: 16px;
  word-break: break-word;
  transition: color 0.35s;
}
@media (max-width: 971px) {
  ._2Y5vx {
    font-size: 18px;
  }
}
.lim6M {
  font-size: 32px;
  line-height: 44px;
  transition: color 0.35s;
  color: #474b2f;
}
@media (max-width: 1238px) {
  .lim6M {
    font-size: 18px;
    line-height: 24px;
  }
}
@media (max-width: 674px) {
  .lim6M {
    font-size: 16px;
    line-height: 22px;
    color: #474b2f;
  }
}
.p7QRr {
  position: relative;
  display: none;
  width: 100%;
  font-size: 18px;
  z-index: 1;
}
@media (max-width: 674px) {
  .p7QRr {
    display: block;
  }
}
.p7QRr a {
  color: #fff;
}
.tKon- {
  display: flex;
  margin-bottom: 20px;
  padding-right: 16px;
  justify-content: space-between;
  align-items: center;
}
.tKon-:last-child {
  margin-bottom: 0;
}
._1uP-P {
  width: 50%;
  padding-left: 12px;
}
@media (max-width: 971px) {
  ._1uP-P {
    width: 100%;
  }
}
@media (max-width: 674px) {
  ._1uP-P {
    display: flex;
    align-items: center;
    font-size: 12px;
  }
}
._1uP-P:first-child {
  padding-left: 0;
}
@media (max-width: 971px) {
  ._1JtuW {
    display: none;
  }
}
@media (max-width: 674px) {
  ._1JtuW {
    display: block;
  }
}
._1oW1w {
  height: 100%;
  margin-right: 9px;
  float: left;
}
._1oW1w:after {
  content: '';
  display: block;
  height: 0;
  clear: both;
  overflow: hidden;
}
@media (max-width: 674px) {
  ._1oW1w {
    margin-right: 13px;
  }
}
@media (max-width: 480px) {
  ._1oW1w {
    display: none;
  }
}
._2Uaru {
  border: 2px solid #a1a490;
  width: 44px;
  height: 44px;
}
@media (max-width: 674px) {
  ._2Uaru {
    width: 28px;
    height: 28px;
  }
}
@media (max-width: 480px) {
  ._2Uaru {
    display: none;
  }
}
.LyxTo {
  color: #a1a492;
}
@media (max-width: 674px) {
  ._2Xk3K {
    margin-right: 7px;
  }
  ._2Xk3K:after {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border: 1px solid #cdcfbf;
    background-color: #cdcfbf;
    border-radius: 50%;
  }
}
._2itOb {
  word-break: break-word;
  color: #474b2f;
  margin-right: 8px;
  margin-bottom: 4px;
  font-size: 15px;
  line-height: 20px;
}
@media (max-width: 480px) {
  ._2itOb {
    font-size: 12px;
    line-height: 20px;
  }
}
@media (max-width: 674px) {
  ._2itOb {
    margin-bottom: 0;
  }
}
._36pCf {
  display: none;
}
@media (max-width: 674px) {
  ._36pCf {
    display: flex;
  }
}
._36pCf .LyxTo {
  margin-top: 3px;
  color: #a1a490;
}
._36pCf ._2itOb,
._36pCf ._2itOb a {
  color: #fff;
}
._36pCf ._27-In {
  margin-left: -47px;
}
._1nYsg {
  flex-grow: 1;
  margin-bottom: 25px;
  padding: 0;
  font-family: inherit;
  font-size: 15px;
  line-height: 20px;
  color: rgba(72,75,50,0.8);
}
._1nYsg:last-child {
  margin-bottom: 0;
}
@media (max-width: 1238px) {
  ._1nYsg {
    color: #959b78;
  }
}
@media (max-width: 674px) {
  ._1nYsg {
    display: none;
  }
}
._7nMf0 {
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-wrap: wrap;
  width: 400px;
  min-height: 360px;
  padding: 24px;
}
@media (max-width: 971px) {
  ._7nMf0 {
    width: 333px;
    min-height: 300px;
    padding: 16px;
  }
}
@media (max-width: 674px) {
  ._7nMf0 {
    width: 100%;
    margin-right: -32px;
    min-height: 100%;
    padding-top: 47.79%;
  }
}
@media (max-width: 567px) {
  ._7nMf0 {
    margin-right: -15px;
  }
}
._3xR-d {
  flex-shrink: 0;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  transition: transform 0.35s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  will-change: transform;
}
._2rnVF {
  display: flex;
  align-items: center;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
._2bMZy ._3xR-d {
  height: 100%;
  background-position: center;
  background-size: cover;
}
._1wwM9 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #000;
  transform: translateZ(0);
}
@media (max-width: 674px) {
  [class*='_latestArticles'] ._1wwM9 {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}
._101cL {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
._11uvX ul {
  display: block;
}
._11uvX ul > li {
  font-size: 13px;
  font-weight: 400;
  background-color: #000 !important;
  opacity: 0.6;
}
@media (max-width: 480px) {
  ._11uvX ul > li {
    margin-right: 2px;
    padding: 2px 8px;
    font-size: 11px;
  }
}
._11uvX ul + ul {
  display: flex;
}
@media (max-width: 674px) {
  ._11uvX {
    margin-left: 12px;
  }
}
.C63E2 {
  position: relative;
  flex: 1 1 100%;
  align-self: flex-end;
  margin-top: auto;
  z-index: 1;
}
._2USM2 {
  position: relative;
  width: 100%;
  margin-bottom: 13px;
  z-index: 2;
}
.zQaAQ:first-of-type ._1u0R5 {
  border-top-left-radius: 20px;
}
@media (max-width: 1079px) {
  [class*='_latestArticles'] .zQaAQ:first-of-type ._1u0R5 {
    border-radius: 0;
  }
}
.zQaAQ:first-of-type ._1wwM9 {
  border-top-right-radius: 20px;
}
@media (max-width: 674px) {
  .zQaAQ:first-of-type ._1wwM9 {
    border-top-left-radius: 20px;
    border-top-right-radius: 0;
  }
}
.zQaAQ:last-of-type ._1u0R5 {
  border-bottom-left-radius: 20px;
}
.zQaAQ:last-of-type ._1wwM9 {
  border-bottom-right-radius: 20px;
}
@media (max-width: 674px) {
  .zQaAQ:last-of-type ._1wwM9 {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 20px;
  }
}
