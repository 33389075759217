._1WNX3 {
  display: flex;
  flex-direction: row;
  margin: 0 -30px;
}
._3Qp_e {
  display: flex;
  flex-direction: row;
}
._2kzRr {
  position: absolute;
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  cursor: zoom-out;
}
._2SXIs {
  display: block;
  max-height: calc(100vh - 60px);
  cursor: zoom-out;
  object-fit: contain;
  max-width: 100%;
}
._25KCR {
  position: relative;
  max-width: 1160px;
}
@media (max-width: 1079px) {
  ._25KCR {
    max-width: 920px;
  }
}
@media (max-width: 971px) {
  ._25KCR {
    max-width: 860px;
    margin-right: auto;
    margin-left: auto;
  }
}
.EhEZf {
  display: flex;
  align-items: center;
  order: 1;
}
._3KNMQ {
  display: flex;
  align-items: center;
}
._1Zb4- {
  position: fixed;
  top: 24px;
  right: 24px;
  width: 40px;
  height: 40px;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  color: #fff;
  background-color: rgba(57,57,53,0.87);
  z-index: 5;
  transition: background-color 0.35s;
}
.ModalGallery__image-enter {
  opacity: 0;
  transform: scale(0.8);
}
.ModalGallery__image-enter-done {
  opacity: 1;
  transition: transform 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94), opacity 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  transform: scale(1);
}
.ModalGallery__image-exit {
  opacity: 0;
  transition: transform 150ms, opacity 150ms;
  transform: scale(1);
}
.ModalGallery__image-exit-done {
  opacity: 0;
  transform: scale(0.8);
}
