.FeaturedPortfolios__swiper_container {
  overflow: visible;
}
.FeaturedPortfolios__swiper_container > .swiper-wrapper {
  height: 300px;
  align-items: center;
}
@media (max-width: 971px) {
  .FeaturedPortfolios__swiper_container > .swiper-wrapper {
    height: auto;
    align-items: flex-start;
  }
}
.EwDan {
  background-color: #000;
  color: #fff;
  width: 100vw;
  height: 568px;
  padding: 60px 0;
  overflow: hidden;
}
@media (max-width: 1439px) {
  .EwDan {
    padding: 44px 0;
  }
}
@media (max-width: 971px) {
  .EwDan {
    height: 504px;
    padding: 40px 0;
  }
}
._3QRc5 {
  display: flex;
  flex-direction: column;
}
._1wAEB {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 60px;
}
@media (max-width: 1439px) {
  ._1wAEB {
    margin-bottom: 44px;
  }
}
@media (max-width: 971px) {
  ._1wAEB {
    margin-bottom: 40px;
  }
}
._3tBgu {
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 8px;
}
._3LOB0 {
  font-size: 22px;
  line-height: 32px;
  font-weight: normal;
}
@media (max-width: 971px) {
  ._3LOB0 {
    max-width: 370px;
    font-size: 16px;
    line-height: 24px;
  }
}
.geHNo {
  width: 219px;
  margin-left: 20px;
}
@media (max-width: 971px) {
  .geHNo {
    margin-top: 60px;
    margin-left: 0;
  }
}
.JYFb0 {
  height: 44px;
  width: 219px;
  padding: 10px 24px;
  flex-shrink: 0;
  border-radius: 90px;
  background: #dbd6eb;
  font-size: 18px;
  letter-spacing: normal;
  color: #2c2c2c;
}
