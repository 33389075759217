.Ra3dX {
  cursor: pointer;
}
._3_R82 {
  position: absolute;
  display: none;
  width: 234px;
  border-radius: 20px;
  opacity: 0;
  background-color: #fff;
  box-shadow: 0 12px 39px 0 rgba(198,198,198,0.55);
  z-index: 8;
  transition: opacity 0.3;
}
._3ENLp {
  display: block;
  opacity: 1;
}
._2OEK6 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
._2BrdP {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  z-index: 1;
}
._2vcwT {
  width: 100%;
  padding: 164px 20px 20px 16px;
}
._36Cqn {
  margin-bottom: 10px;
  padding-left: 5px;
  font-size: 15px;
}
.IK7DW {
  margin-bottom: 3px;
  font-weight: 600;
}
._36Y9s {
  margin-bottom: 5px;
  color: #a1a490;
}
._2qi7j {
  margin-right: 4px;
}
._3aG77 {
  height: 40px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
._3aHMC {
  width: 100%;
  color: #474b2f;
}
._2CduV {
  padding-right: 12px;
  color: #227422;
}
