._2qrtX {
  display: inline-block;
  max-height: 124px;
  margin-right: 25px;
  overflow: hidden;
  font-size: 31px;
  font-weight: 600;
  color: #fff;
}
@media (max-width: 674px) {
  ._2qrtX {
    max-height: 71px;
    font-size: 18px;
  }
}
._2qrtX .word {
  line-height: 1;
  vertical-align: top;
}
._2qrtX .last {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
