._5wV4Q {
  --card-width: 320px;
  display: block;
  position: relative;
  width: var(--card-width);
  height: 404px;
  cursor: pointer;
  margin-top: 10px;
}
._5wV4Q:hover .wG5kl {
  transform: rotate3d(0, 1, 0, -180deg);
  opacity: 0;
}
._5wV4Q:hover ._1Lfic {
  transform: rotate3d(0, 1, 0, 0deg);
  opacity: 1;
}
._5wV4Q:hover .cYuRj {
  transform: scale(1.05);
}
._3boVq {
  --card-width: 280px;
  height: 360px;
}
._3boVq .VrUge,
._3boVq .VrUge img {
  width: 64px;
  height: 64px;
}
._15NOK {
  position: relative;
  width: var(--card-width);
  height: var(--card-width);
  display: flex;
  align-items: center;
  justify-content: center;
}
.cYuRj {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #d6d6d6;
}
.cYuRj,
.cYuRj img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: width 0.2s, height 0.2s, transform 0.2s;
  border-radius: 50%;
}
.cYuRj img {
  object-fit: cover;
}
._2bqcS {
  position: absolute;
  top: 31px;
  display: flex;
  align-items: center;
  gap: 2px;
  color: #fff;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}
._17mvw {
  width: 24px;
}
._1U22m,
._2UZgr {
  width: 48px;
  height: 48px;
}
._1U22m {
  position: relative;
}
._2UZgr {
  position: absolute;
  transition: transform 0.2s, opacity 0.2s;
  backface-visibility: hidden;
  color: #fff;
}
._5wV4Q.wG5kl {
  filter: drop-shadow(0px 0px 48px #000);
}
._1Lfic {
  transform: rotate3d(0, 1, 0, 180deg);
  opacity: 0;
}
._1_Skc {
  width: 100%;
  position: absolute;
  bottom: 0;
  gap: 8px;
  align-items: center;
}
._1_Skc,
.cFYds {
  display: flex;
  flex-direction: column;
}
.VrUge {
  background-color: #71717a;
}
.VrUge,
.VrUge img {
  width: 72px;
  height: 72px;
  border-radius: 50%;
}
.VrUge img {
  object-fit: cover;
  border: 2px solid #71717a;
}
.cFYds {
  height: 40px;
  font-size: 15px;
  line-height: 20px;
}
._3Innl {
  color: #18181b;
  font-weight: 500;
}
._3Gclv {
  color: #71717a;
  font-weight: 400;
}
