.xS7sQ {
  overflow: hidden;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  transform: translateZ(0);
}
.BnAZJ ._3xOKg {
  border-radius: 20px;
}
._3yJxy {
  border-radius: 20px;
}
