._2q9V- {
  position: relative;
}
.GvR2I {
  position: absolute;
  top: 40px;
  left: 0;
}
._1m5TG {
  border-width: 4px;
}
._3auIL {
  display: flex;
  align-items: center;
  padding: 40px 24px;
  border: 1px solid #eceee3;
  border-radius: 20px;
}
._1fSKb {
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 1.333;
  color: #474b2f;
}
.S1odq {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
._1yiHe ._2q9V- {
  padding-left: 40px;
}
._1yiHe ._3auIL {
  min-height: 160px;
  padding-top: 24px;
  padding-left: 64px;
  padding-bottom: 24px;
}
._1Q7Oz ._3auIL {
  border-bottom: none;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
