._22vdk {
  background: #eff6ed;
  border-radius: 20px;
  width: fit-content;
  margin-right: 8px;
  line-height: 140%;
}
._3hupr {
  border-radius: 20px;
}
