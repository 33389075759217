._2dssv {
  position: fixed;
  top: 0;
  left: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  visibility: hidden;
  background-color: #fff;
  box-shadow: 0 12px 39px 0 rgba(198,198,198,0.55);
  z-index: 99;
  transition: transform 0.35s, visibility 0.35s;
  transform: translateX(0);
  will-change: transform;
}
.MG9PA {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 32px;
}
@media (max-width: 1238px) {
  .MG9PA {
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 567px) {
  .MG9PA {
    padding: 4px 16px;
    height: 56px;
  }
}
._3AVi0 {
  visibility: visible;
  transition: transform 0.35s, visibility 0s;
  transform: translateX(-100%);
}
._1M8G4 {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
._2ABQC,
._1M8G4 {
  border-bottom: 1px solid #e4e7d7;
}
._2ABQC:last-child,
._1M8G4:last-child {
  border-bottom: none;
}
._2ABQC {
  flex-shrink: 0;
}
@media (min-width: 1080px) {
  ._2ABQC {
    padding-right: 20px;
    padding-left: 20px;
  }
}
._2-jZ6 {
  flex-shrink: 0;
  overflow: hidden;
}
.Qbnbv {
  display: flex;
  align-items: center;
  height: 64px;
}
@media (max-width: 1238px) {
  .Qbnbv {
    width: 100%;
    max-width: 100%;
  }
}
.XJ5DB {
  display: flex;
  align-items: center;
  height: 64px;
  justify-content: space-between;
  padding: 4px 32px;
}
@media (max-width: 567px) {
  .XJ5DB {
    padding: 4px 16px;
  }
}
.qeHeG {
  display: flex;
  gap: 12px;
}
._2TW4c {
  font-weight: 300;
  font-size: 20px;
  margin-left: 12px;
}
._3_xtD {
  font-weight: 500;
  font-size: 16px;
  font-family: EuclidCircularBMedium;
  color: #000;
  margin-left: 8px;
}
._3G5oR {
  font-weight: 300;
  font-size: 16px;
  font-family: EuclidCircularBLight;
  color: #000;
  margin-left: 8px;
}
._2aH6r {
  display: flex;
  padding: 0px;
  color: #474b2f;
  font-weight: 600;
  flex-direction: row;
  align-items: center;
  margin-right: 32px;
  cursor: pointer;
}
@media (hover: hover) {
  ._2aH6r {
    transition: color 0.35s;
  }
  ._2aH6r:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
._2aH6r:hover {
  opacity: 0.4;
}
._2VnWd {
  display: inline-block;
  width: 24px;
  height: 24px;
  opacity: 0.1;
  vertical-align: middle;
  color: #e3e5dc;
}
._3F1pP {
  transform: rotate(90deg) translateX(1px);
}
@media (hover: hover) {
  ._2VnWd {
    transition: transform 0.35s;
    will-change: transform;
  }
  .XJ5DB:hover ._2VnWd {
    transform: translateX(32px);
  }
}
.Qbnbv {
  justify-content: flex-end;
  padding-top: 16px;
  padding-bottom: 16px;
}
.fS30z {
  flex: 1 1 0%;
}
._3cws9 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding: 32px;
}
@media (max-width: 567px) {
  ._3cws9 {
    padding: 16px;
  }
}
._1k9-8 {
  margin-right: 20px;
  padding-top: 8px;
  padding-bottom: 4px;
  font-weight: 500;
  font-size: 20px;
  font-family: EuclidCircularBMedium;
  color: #000;
  line-height: 23px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}
._1k9-8:hover {
  opacity: 0.4;
}
.fAeU3 {
  color: #30148a;
}
.hRAjw {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: middle;
  color: #474b2f;
  margin-left: 8px;
}
.qq6KD {
  display: inline-block;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  color: #474b2f;
}
@media (max-width: 567px) {
  .qq6KD {
    width: 24px;
    height: 24px;
  }
}
.R3PCy {
  color: #a1a490;
  flex-shrink: 0;
  height: auto;
  padding: 0;
  color: #dadcd5;
}
@media (hover: hover) {
  .R3PCy {
    transition: color 0.35s;
  }
  .R3PCy:hover {
    color: #474b2f;
  }
}
.KqyN5 {
  display: inline-block;
  width: 32px;
  height: 32px;
  vertical-align: middle;
}
@media (max-width: 567px) {
  .KqyN5 {
    width: 24px;
    height: 24px;
  }
}
._2cGTR {
  height: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
}
@media (max-width: 1238px) {
  ._2cGTR {
    columns: 3;
    margin: 0px;
    margin-bottom: 32px;
    padding: 0px;
  }
  ._5d4Lb {
    margin-top: 16px;
  }
}
@media (max-width: 567px) {
  ._2cGTR {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 24px;
  }
  ._5d4Lb {
    margin-top: 12px;
  }
  .bHw9k {
    width: 50%;
  }
}
._2TRHb {
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  font-weight: 300;
}
.bHw9k {
  font-size: 16px;
  font-weight: 300;
  font-family: EuclidCircularBLight;
  color: #000;
}
.bHw9k:hover {
  opacity: 0.4;
}
._3B5Dd {
  padding-bottom: 16px;
}
@media (max-width: 567px) {
  .bHw9k {
    padding-right: 16px;
  }
}
._1PibF {
  position: fixed;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  background-color: rgba(0,0,0,0);
  transition: background-color 0.35s, left 0s 0.35s;
}
._1Dhzm {
  left: 0;
  background-color: rgba(0,0,0,0.15);
  transition: background-color 0.35s, left 0s 0s;
}
