._1j8y3 {
  position: relative;
  width: 250px;
}
._2x-r_ {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
._1KzAN {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #000;
  transition: height 0.2s linear 0.1s, width 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95) 0.1s;
  transform: translateZ(0);
  transition-duration: 0.175s;
}
.XGDtU {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 255px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  z-index: 1;
  transition: border-radius 0.2s linear 0.1s, height 0.2s linear 0.1s;
}
@media (max-width: 971px) {
  .XGDtU {
    height: 168px;
  }
}
._1xmKH {
  display: block;
  max-width: 100%;
  height: 100%;
  transition: transform 0.2s cubic-bezier(0.445, 0.05, 0.55, 0.95) 100ms;
  transform: scale(1);
}
._3smV7 {
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
._1aPCV {
  width: 100%;
  padding: 230px 20px 19px;
  border-radius: 20px;
  background-color: #f9fbf7;
  transition: background 0.35s;
}
@media (max-width: 971px) {
  ._1aPCV {
    padding-top: 140px;
  }
}
._3gQKT {
  transition-duration: 0.175s;
  transition: transform 0.2s linear 0.1s;
  transform: translateY(50px);
}
._1_qA8 {
  display: block;
  color: #a1a490;
}
._12_Lc {
  margin-bottom: 8px;
}
._1IL5v {
  color: #474b2f;
}
._1x29D {
  display: -webkit-box;
  height: 72px;
  margin-bottom: 12px;
  overflow: hidden;
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  transition: color 0.2s;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
.m18Rf {
  height: 60px;
  padding: 0;
  padding-right: 12px;
  opacity: 0;
  overflow: hidden;
  font-size: 15px;
  line-height: 20px;
  color: #b2b7a3;
  transition: opacity 0.2s linear 0s;
  transition-duration: 0.175s;
}
._2gjkK {
  position: relative;
  z-index: 2;
}
@media (hover: hover) {
  ._37Bp7:hover .XGDtU,
  ._3QAml:hover .XGDtU {
    height: 196px;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    transition: border-radius 0.2s linear 0s, height 0.2s linear 0s;
  }
  ._37Bp7:hover ._1xmKH,
  ._3QAml:hover ._1xmKH {
    transition: transform 0.2s linear 0s;
    transform: scale(1.1);
  }
  ._37Bp7:hover ._1aPCV,
  ._3QAml:hover ._1aPCV {
    background-color: #fff;
  }
  ._37Bp7:hover ._3gQKT,
  ._3QAml:hover ._3gQKT {
    transition: transform 0.2s linear 0s;
    transform: translateY(-20px);
  }
  ._37Bp7:hover ._1x29D,
  ._3QAml:hover ._1x29D {
    color: #227422;
  }
  ._37Bp7:hover .m18Rf,
  ._3QAml:hover .m18Rf {
    opacity: 1;
    transition: opacity 0.2s linear 0.1s;
  }
}
@media (hover: hover) {
  ._3nhuH:hover ._1xmKH {
    transition: transform 0.2s linear 0s;
    transform: scale(1.1);
  }
}
._3nhuH .XGDtU {
  height: 240px;
}
._3nhuH .XGDtU::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.8), rgba(0,0,0,0) 33%, rgba(0,0,0,0) 77%, rgba(0,0,0,0.8));
}
._3nhuH ._1aPCV {
  height: 100%;
  padding: 264px 24px 24px;
  background-color: #fff;
}
._3nhuH ._3gQKT {
  transform: translateY(0);
}
._3nhuH ._1x29D {
  height: auto;
  max-height: 72px;
  margin-bottom: 8px;
}
._3QAml ._1aPCV {
  background-color: #fff;
}
._2l9bO {
  pointer-events: none;
}
._2l9bO ._1aPCV {
  background-color: #f4f4f1;
}
._2l9bO ._1x29D {
  background-color: #eceee3;
  border-radius: 20px;
  height: 24px;
}
._2l9bO ._1KzAN {
  background-color: #eceee3;
}
._2l9bO ._2gjkK {
  display: flex;
  gap: 8px;
}
._2l9bO ._14eaD {
  border-radius: 50px;
  height: 24px;
  box-shadow: inset 0 0 0 2px #eceee3;
}
._2l9bO ._14eaD:nth-child(1),
._2l9bO ._14eaD:nth-child(2) {
  width: 59px;
}
._2l9bO ._14eaD:nth-child(3) {
  width: 24px;
}
