._13-aY {
  margin-bottom: 40px;
}
@media (max-width: 567px) {
  ._13-aY {
    margin-bottom: 24px;
  }
}
._24M5t {
  display: flex;
  align-items: center;
}
@media (max-width: 567px) {
  ._24M5t {
    display: block;
  }
}
._3recT {
  min-width: 148px;
  margin-right: 14px;
}
@media (max-width: 567px) {
  ._3recT {
    margin-right: 0;
    margin-bottom: 20px;
    text-align: center;
  }
}
@media (max-width: 567px) {
  ._1E2Js {
    width: 100%;
    text-align: center;
  }
}
