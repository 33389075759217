.Ew8lG {
  display: flex;
  margin-bottom: 40px;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: left;
}
@media (max-width: 971px) {
  .Ew8lG {
    display: block;
    max-width: 443px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
}
@media (max-width: 567px) {
  .Ew8lG {
    max-width: 290px;
    margin-bottom: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
._3xL3M {
  flex-shrink: 0;
  width: 443px;
  height: 143px;
  margin-right: 7px;
}
@media (max-width: 971px) {
  ._3xL3M {
    margin-right: 0;
    margin-bottom: 7px;
  }
}
@media (max-width: 567px) {
  ._3xL3M {
    width: auto;
    height: 93px;
    margin-bottom: 4px;
  }
}
._1Vwvu {
  display: block;
  max-width: 100%;
}
._307Kn {
  flex: 1;
  margin-right: calc((-100vw + 1220px) / 2);
}
@media (max-width: 1439px) {
  ._307Kn {
    margin-right: calc((-100vw + 920px) / 2);
  }
}
@media (max-width: 1079px) {
  ._307Kn {
    margin-right: calc((-100vw + 860px) / 2);
  }
}
@media (max-width: 971px) {
  ._307Kn {
    margin-right: 0;
  }
}
._1enOW {
  font-size: 24px;
}
@media (max-width: 567px) {
  ._1enOW {
    font-size: 18px;
  }
}
._1aFOA {
  margin-left: 5px;
  text-decoration: underline;
  color: #f4b9e3;
}
._2ZbSW {
  display: flex;
  align-items: center;
  height: 43px;
  margin-bottom: 7px;
  padding-right: 20px;
  padding-left: 20px;
  color: #f4b9e3;
  background-color: #000;
}
._2ZbSW:last-child {
  margin-bottom: 0;
}
._2ZbSW:first-child {
  border-top-left-radius: 4px;
}
._2ZbSW:last-child {
  border-bottom-left-radius: 4px;
}
@media (max-width: 971px) {
  ._2ZbSW {
    justify-content: center;
    border-radius: 4px;
  }
}
@media (max-width: 567px) {
  ._2ZbSW {
    height: 28px;
    margin-bottom: 4px;
  }
}
._2ViWw {
  padding-bottom: 60px;
}
@media (max-width: 674px) {
  ._2ViWw {
    padding-bottom: 40px;
  }
}
