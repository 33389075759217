._2JuUF {
  display: inline-block;
  padding-top: 12px;
  padding-bottom: 12px;
  color: #474b2f;
}
@media (hover: hover) {
  ._2JuUF {
    transition: color 0.35s;
  }
  ._2JuUF:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
._2XUIf {
  cursor: default;
  pointer-events: none;
  color: #a1a490;
}
