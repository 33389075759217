._3lEPZ {
  position: relative;
  width: 1160px;
  font-size: initial;
  transition: none;
}
@media (max-width: 1238px) {
  ._3lEPZ {
    width: 100%;
  }
}
.c59aD {
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  height: calc(100vh - 120px);
}
._1gijC {
  width: 800px;
  margin: 0 auto;
  padding-top: 63px;
}
@media (max-width: 971px) {
  ._1gijC {
    width: 600px;
  }
}
@media (max-width: 674px) {
  ._1gijC {
    width: 100%;
  }
}
._2nJsu {
  margin-bottom: 40px;
}
@media (max-width: 674px) {
  ._2nJsu {
    margin-bottom: 32px;
  }
}
.ttSxU {
  height: auto !important;
  border-bottom: 2px solid #474b2f;
}
.ttSxU input {
  padding: 8px 1px;
  font-size: 31px;
  font-weight: 600;
  background-color: transparent;
}
@media (max-width: 567px) {
  .ttSxU input {
    font-size: 18px;
  }
}
.ttSxU input::placeholder {
  font-style: italic;
  font-weight: normal;
}
._1iBzU {
  position: fixed;
  top: 120px;
  right: calc(((100vw - 1160px) / 2));
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  padding: 0;
  border: none;
  border-radius: 20px;
  opacity: 0;
  cursor: pointer;
  color: #fff;
  background-color: rgba(57,57,53,0.88);
  z-index: 1000;
  transition: opacity 150ms ease-in;
}
._3muu2 {
  display: block;
  width: 20px;
  height: 20px;
  margin-right: auto;
  margin-left: auto;
  vertical-align: middle;
}
@media (hover: hover) {
  ._1iBzU {
    transition: background-color 0.35s;
  }
  ._1iBzU:hover {
    background-color: #1a1f19;
  }
}
@media (max-width: 1238px) {
  ._1iBzU {
    right: calc(((100vw - 920px) / 2));
  }
}
@media (max-width: 1079px) {
  ._1iBzU {
    right: calc(((100vw - 960px) / 2));
  }
}
@media (max-width: 971px) {
  ._1iBzU {
    right: 30px;
  }
}
@media (max-width: 674px) {
  ._1iBzU {
    top: 125px;
    right: 13px;
  }
}
@media (max-width: 567px) {
  ._1iBzU {
    right: 8px;
  }
}
.KEVUi {
  position: relative;
  margin-bottom: 40px;
}
@media (max-width: 567px) {
  .KEVUi {
    margin-bottom: 44px;
  }
}
._1LtLV {
  position: absolute;
  left: 0;
  bottom: 100%;
  font-size: 15px;
  color: #a1a490;
}
.aCDuS {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
}
@media (max-width: 674px) {
  .aCDuS {
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 971px) {
  ._2UFoY {
    min-width: 160px !important;
  }
}
@media (max-width: 567px) {
  .oFVmW {
    display: flex;
    flex-wrap: nowrap;
    padding-right: 24px;
  }
}
._3ZV3g {
  height: 44px;
}
@media (max-width: 971px) {
  ._3ZV3g {
    margin-right: -2px;
  }
}
@media (max-width: 674px) {
  ._3ZV3g {
    margin-bottom: 16px;
    order: -1;
  }
}
@media (max-width: 567px) {
  ._3ZV3g {
    width: 100vw;
    margin-right: -24px;
    margin-bottom: 20px;
    margin-left: -24px;
    padding: 0 24px;
  }
}
._3ZV3g::-webkit-scrollbar {
  display: none;
  height: 0 !important;
  background: transparent;
}
@media (max-width: 971px) {
  .oFVmW button:not(:last-child) {
    margin-right: 4px;
  }
}
@media (max-width: 674px) {
  ._36y3L {
    padding-left: 20px;
  }
}
._1dspM ._1iBzU {
  transition-duration: 0.175s;
}
@media (max-width: 674px) {
  ._1dspM ._1iBzU {
    background-color: #474b2f;
  }
}
#google-searchbox .gsc-control-cse {
  padding: 0;
  border-color: transparent;
  background: transparent;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
}
#google-searchbox .gsc-search-box .gsc-input-box {
  padding: 0;
  border: none;
  border-bottom: 2px solid #474b2f;
  border-radius: 0;
  background: transparent;
}
#google-searchbox .gsc-search-box .gsc-search-button {
  position: absolute;
  opacity: 0;
}
#google-searchbox .gsc-search-box td.gsc-input {
  padding-right: 0;
}
#google-searchbox .gsc-search-box .gsc-input input.gsc-input {
  background: transparent !important;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-size: 31px;
  font-weight: 600;
  text-indent: 0 !important;
}
#google-searchbox .gsc-search-box .gsc-input input.gsc-input::placeholder {
  font-style: normal;
  font-size: 31px;
  font-weight: normal;
  color: #a1a490;
}
@media (max-width: 567px) {
  #google-searchbox .gsc-search-box .gsc-input input.gsc-input::placeholder {
    font-size: 18px;
  }
}
@media (max-width: 567px) {
  #google-searchbox .gsc-search-box .gsc-input input.gsc-input {
    font-size: 18px;
  }
}
#google-searchbox .gsc-results .gsc-result {
  margin-bottom: 24px;
  padding: 24px;
  border-radius: 20px;
}
#google-searchbox .gsc-results .gsc-result:last-child {
  margin-bottom: 0;
}
@media (max-width: 567px) {
  #google-searchbox .gsc-results .gsc-result {
    padding: 16px;
  }
}
#google-searchbox .gsc-results .gsc-result .gs-title {
  margin-bottom: 12px;
  text-decoration: none;
}
#google-searchbox .gsc-results .gsc-result .gs-title * {
  font-size: 31px;
  text-decoration: none;
  color: #474b2f;
}
@media (max-width: 567px) {
  #google-searchbox .gsc-results .gsc-result .gs-title * {
    font-size: 18px;
  }
}
#google-searchbox .gsc-results .gsc-result .gs-visibleUrl {
  padding-bottom: 20px;
}
@media (max-width: 567px) {
  #google-searchbox .gsc-results .gsc-result .gs-visibleUrl {
    padding-bottom: 16px;
  }
}
#google-searchbox .gsc-results .gsc-result .gsc-table-result {
  font-size: 15px;
}
#google-searchbox .gsc-results .gsc-cursor-box .gsc-cursor-page {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border: 3px solid transparent;
  border-radius: 50%;
  color: #a1a490;
  background: transparent;
}
#google-searchbox .gsc-results .gsc-cursor-box .gsc-cursor-current-page {
  border-color: #eceee3;
}
#google-searchbox .gsib_a {
  padding: 5px 0 4px;
}
[class*='scroll-locked'] ._1iBzU {
  opacity: 1;
}
