.X7xp1 {
  display: flex;
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
  color: #474b2f;
  padding: 0;
  border: none;
  flex-direction: row;
  align-items: center;
}
@media (hover: hover) {
  .X7xp1 {
    transition: color 0.35s;
  }
  .X7xp1:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
._2mbCe {
  display: inline-block;
  width: 32px;
  height: 32px;
  vertical-align: middle;
  color: #474b2f;
}
@media (max-width: 567px) {
  ._2mbCe {
    width: 24px;
    height: 24px;
  }
}
