._1Uplu {
  display: flex;
}
@media (max-width: 971px) {
  ._1Uplu {
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: -24px;
  }
}
._1_MTL:not(:last-child) {
  margin-right: 43px;
}
@media (max-width: 971px) {
  ._1_MTL:not(:last-child) {
    margin-right: 0;
  }
}
