._3BLKv {
  display: flex;
  align-items: center;
  z-index: 1;
  position: absolute;
  top: 50%;
  margin: auto 20px;
  translate: transform(0, -50%);
}
._1QNlA {
  right: 0;
  order: 2;
}
.nNvNU {
  left: 0;
  order: 0;
}
._3C4gk {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  color: #c8c9bf;
  background-color: rgba(57,57,53,0.88);
  visibility: visible;
  opacity: 0.6;
}
@media (hover: hover) {
  ._3C4gk:hover {
    opacity: 1;
  }
}
._3C4gk:disabled,
._3C4gk[aria-disabled="true"] {
  visibility: hidden;
}
._36F8i {
  transform: rotate(180deg);
}
