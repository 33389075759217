._3DQMS {
  position: relative;
  border-radius: 20px;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  background-color: #f4f4f1;
}
._1HMKM {
  display: block;
  max-width: 260px;
  margin: 0 auto;
  padding: 32px 0;
}
._2du0d {
  width: 260px;
  height: 260px;
}
.oBUQn {
  position: relative;
  width: 260px;
  height: 260px;
  margin-bottom: 8px;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #f4f4f1;
}
._3yNBY {
  display: block;
  width: 100%;
  text-align: right;
  color: $gray400Color;
}
._37Xwf {
  padding: 0;
  background-color: transparent;
}
._37Xwf ._1HMKM {
  padding: 0;
}
._37Xwf ._1azc_ {
  margin-right: -40px;
}
@media screen and (max-width: 1280px) {
  ._37Xwf ._1azc_ {
    margin-right: 0;
  }
}
._37Xwf ._50urh {
  margin: 0;
}
._3FPOz {
  background-color: #fff;
}
._2ZEuB {
  display: none;
}
._2-Ujt {
  background: none;
}
._2-Ujt ._1HMKM {
  padding: 0;
}
[class*='__el_group'] ._3JUze {
  margin-bottom: 0;
  background-color: #fff;
}
@media (max-width: 674px) {
  [class*='__el_group'] ._3JUze {
    margin-bottom: 24px;
  }
}
