._2-Oc1 {
  position: fixed;
  z-index: 12;
  bottom: 32px;
  width: 100%;
}
._3YAeN {
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 12px 39px 0 rgba(198,198,198,0.55);
  padding: 24px 32px 24px 32px;
  display: flex;
  align-items: center;
}
._1D0lX {
  display: flex;
  align-items: center;
  flex: 1;
}
._8HQde {
  display: flex;
  flex-direction: column;
}
._2fvzz {
  display: flex;
  flex-direction: column;
  flex: 1;
}
._3FSpm {
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
}
._1ZilL {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 5px;
}
._3xpoo {
  justify-content: center;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  color: #fff;
  background-color: rgba(57,57,53,0.88);
}
.cW6As {
  display: flex;
  margin-top: 16px;
  flex: 1;
  justify-content: flex-end;
}
.Arbpb {
  margin: 0px 8px 0px 8px;
}
._2ajFr {
  display: flex;
}
.wEczg {
  margin-left: 8px;
}
