._3NNSE {
  display: flex;
  justify-content: flex-start;
  min-height: 120px;
  padding-top: 20px;
  padding-bottom: 20px;
}
@media (max-width: 971px) {
  ._3NNSE {
    position: relative;
    display: block;
    padding-top: 0;
    padding-left: 160px;
  }
}
@media (max-width: 674px) {
  ._3NNSE {
    padding-bottom: 0;
  }
}
@media (max-width: 567px) {
  ._3NNSE {
    padding-left: 0;
  }
}
.cwe7F {
  width: 410px;
}
@media (max-width: 971px) {
  .cwe7F {
    width: auto;
  }
}
@media (max-width: 567px) {
  .cwe7F {
    text-align: center;
  }
}
.nevtN {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 971px) {
  .nevtN {
    justify-content: flex-start;
  }
}
@media (max-width: 567px) {
  .nevtN {
    justify-content: center;
  }
}
._17Wj5 {
  margin-right: 200px;
  display: flex;
  align-items: center;
  width: 520px;
}
@media (max-width: 971px) {
  ._17Wj5 {
    margin: 0 0 16px;
    width: auto;
  }
}
@media (max-width: 567px) {
  ._17Wj5 {
    display: block;
    text-align: center;
  }
}
.c9kW5 {
  flex-shrink: 0;
  margin-right: 40px;
  border-width: 4px;
}
@media (max-width: 971px) {
  .c9kW5 {
    position: absolute;
    top: 0;
    left: 0;
  }
}
@media (max-width: 567px) {
  .c9kW5 {
    position: relative;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;
  }
}
._1Gv2s {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
._1kQRK {
  flex-shrink: 0;
  max-width: 250px;
  font-size: 31px;
  line-height: 1.36;
  font-weight: 600;
}
@media (max-width: 971px) {
  ._1kQRK {
    max-width: none;
  }
}
@media (max-width: 567px) {
  ._1kQRK {
    margin-bottom: 16px;
  }
}
.mh24H {
  margin-bottom: 16px;
}
@media (max-width: 567px) {
  .mh24H {
    margin-bottom: 18px;
  }
}
@media (max-width: 567px) {
  .mh24H [class*='__list'] {
    justify-content: center;
  }
}
._3QdDz,
._1Hz1J {
  line-height: 1.6;
}
._3QdDz {
  color: #a1a490;
}
