._3mvEU {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  padding: 32px 16px 16px;
  background-color: #fff;
  z-index: 1;
}
@media (hover: hover) {
  ._3mvEU:hover .B2tMZ {
    transform: scale(1.1);
  }
  ._3mvEU:hover ._35aAv {
    color: #a1a490;
  }
}
._34z8P {
  position: relative;
}
._17Z1q {
  position: relative;
  flex-shrink: 0;
  display: block;
  width: 64px;
  height: 64px;
  margin-top: calc(64px * -1);
  margin-bottom: 8px;
  border-radius: 20px;
  overflow: hidden;
  transform: translateZ(0);
}
.B2tMZ {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  background-color: #000;
  transition: transform 0.35s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
._2oHN7 {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
._35aAv {
  flex: 1 1 1;
  margin-bottom: 12px;
  font-size: 18px;
  word-break: break-word;
  transition: color 0.35s;
}
._35aAv a {
  color: inherit;
}
._35Nm0 {
  flex-shrink: 0;
}
