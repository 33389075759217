._3pX1z {
  padding-bottom: 60px;
}
.gANHW {
  max-width: 620px;
}
._1EZ8z {
  margin-bottom: 24px;
  font-family: EuclidCircularB;
  font-style: normal;
  font-size: 48px;
  font-weight: 600;
  line-height: 1.25;
  font-stretch: normal;
  letter-spacing: normal;
}
@media (max-width: 971px) {
  ._1EZ8z {
    font-size: 31px;
    line-height: 1.29;
  }
}
._1mQkN h2 {
  font-family: EuclidCircularB;
  font-style: normal;
  font-size: 18px;
  font-weight: 600;
  line-height: 1.33;
  font-stretch: normal;
  letter-spacing: normal;
}
@media (max-width: 567px) {
  ._1mQkN h2 {
    font-size: 15px;
    line-height: 1.6;
  }
}
._1mQkN,
._1mQkN p {
  font-family: NoticiaText;
  font-style: normal;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  font-stretch: normal;
  letter-spacing: normal;
}
@media (max-width: 567px) {
  ._1mQkN,
  ._1mQkN p {
    font-size: 15px;
    line-height: 1.6;
  }
}
._1mQkN a {
  box-shadow: inset 0 -1px 0 0 #474b2f;
}
._3pX1z hr {
  margin-bottom: 24px;
  border: none;
  border-bottom: 1px solid #eceee3;
}
