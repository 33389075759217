._2Ixdv {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 590px;
  margin: 0 auto;
}
._1gJR6 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 8px;
  max-width: 590px;
  margin: 24px auto;
}
._2X7CC {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(133,133,133,0.2);
  border-radius: 20px;
  width: 141px;
  height: 60px;
}
