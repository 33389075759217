._1wVXB {
  margin-bottom: 0;
}
@media (max-width: 971px) {
  ._1wVXB {
    display: flex;
    flex-direction: column;
  }
}
.X3KyG {
  margin-bottom: 40px;
}
@media (max-width: 971px) {
  .X3KyG {
    margin-bottom: 24px;
  }
}
._1dB5G {
  margin-bottom: 40px;
}
@media (max-width: 971px) {
  ._1dB5G {
    margin-bottom: 24px;
    font-size: 31px;
  }
}
._3Llj5 {
  font-weight: 400;
}
._3Llj5 {
  font-size: 48px;
  font-weight: 400;
  line-height: 1.25;
}
@media (max-width: 971px) {
  ._3Llj5 {
    font-size: 31px;
  }
}
._1VCoh {
  margin-bottom: 40px;
  border-radius: 20px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
@media (max-width: 971px) {
  ._1VCoh {
    order: -1;
  }
}
@media (max-width: 674px) {
  ._1VCoh {
    margin-bottom: 85px;
  }
}
@media (max-width: 567px) {
  ._1VCoh {
    margin-right: -15px;
    margin-bottom: 24px;
    margin-left: -15px;
    border-radius: 0;
  }
}
._2M3rx {
  display: block;
  width: 100%;
  border-radius: 20px;
}
@media (max-width: 567px) {
  ._2M3rx {
    border-radius: 0;
  }
}
.ML5D5 {
  margin-bottom: 40px;
  padding: 0;
  color: unset;
}
@media (max-width: 971px) {
  .ML5D5 {
    margin-bottom: 24px;
  }
}
@media (max-width: 674px) {
  .ML5D5 {
    margin-bottom: 60px;
    font-size: 18px;
    line-height: 1.333;
  }
}
@media (max-width: 567px) {
  .ML5D5 {
    margin-bottom: 24px;
  }
}
._2p8qv {
  position: relative;
  width: 100%;
  margin-bottom: 74px;
}
.m2SGd {
  margin-bottom: 60px;
}
@media (max-width: 971px) {
  .m2SGd {
    margin-bottom: 50px;
  }
}
@media (max-width: 674px) {
  .m2SGd {
    margin-bottom: 95px;
  }
}
@media (max-width: 567px) {
  .m2SGd {
    margin-bottom: 60px;
  }
}
._3mgOA {
  margin-top: 40px;
  margin-bottom: 40px;
}
@media (max-width: 971px) {
  ._3mgOA {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (max-width: 567px) {
  ._3mgOA {
    padding-right: 15px;
    padding-left: 15px;
  }
}
