._2IGHi {
  padding-top: 24px;
  padding-bottom: 24px;
}
@media (min-width: 568px) {
  ._2IGHi {
    display: flex;
  }
}
._3l2Zt {
  flex-shrink: 0;
  align-self: center;
  width: 262px;
  margin-right: 32px;
  text-align: center;
}
@media (max-width: 1079px) {
  ._3l2Zt {
    width: 202px;
  }
}
@media (max-width: 971px) {
  ._3l2Zt {
    width: 260px;
    margin-right: 30px;
  }
}
@media (max-width: 674px) {
  ._3l2Zt {
    align-self: flex-start;
    width: 144px;
    margin-right: 24px;
  }
}
@media (max-width: 567px) {
  ._3l2Zt {
    margin: 0 auto 24px;
  }
}
.tBfrt {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 675px) {
  .tBfrt {
    width: 144px;
  }
}
.tBfrt img {
  display: block;
  max-width: 100%;
  height: auto;
}
.-z2oj {
  flex: 1;
  overflow: hidden;
}
._1eurc {
  color: #474b2f;
}
@media (hover: hover) {
  ._1eurc {
    transition: color 0.35s;
  }
  ._1eurc:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
.gkWkf {
  margin-bottom: 8px;
}
._3ZVwa {
  flex: 1 1 100%;
  margin-bottom: 16px;
  padding: 0;
  font-size: 15px;
  line-height: 20px;
  color: #a1a490;
}
._3ZVwa:last-child {
  margin-bottom: 0;
}
@media (max-width: 674px) {
  ._3ZVwa {
    margin-bottom: 12px;
  }
}
