._3B_jB {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
}
@media (max-width: 567px) {
  ._3B_jB {
    flex-direction: column;
    align-items: stretch;
  }
}
._2LUYP {
  display: flex;
  gap: 10px;
}
@media (max-width: 567px) {
  ._2o950 {
    margin-bottom: 8px;
  }
  ._2o950:last-child {
    margin-bottom: 0;
  }
  ._2o950 .Select-control {
    text-align: center;
  }
  ._2o950 .Select-option {
    justify-content: center;
  }
}
