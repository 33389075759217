._1vOIl {
  display: flex;
  flex-direction: column;
  gap: 28px;
}
._3lQDL {
  margin-bottom: 2px;
  padding-right: 32px;
  padding-left: 32px;
  background-color: #fff;
}
._3lQDL:last-child {
  margin-bottom: 0;
}
@media (min-width: 675px) {
  ._3lQDL:first-of-type {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  ._3lQDL:last-of-type {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}
@media (max-width: 674px) and (max-width: 1079px) {
  ._3lQDL {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (max-width: 674px) and (max-width: 971px) {
  ._3lQDL {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (max-width: 674px) and (max-width: 567px) {
  ._3lQDL {
    padding-right: 15px;
    padding-left: 15px;
  }
}
._1iz5N {
  cursor: pointer;
}
