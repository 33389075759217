._1Tm50 {
  position: relative;
  flex-shrink: 0;
  width: 300px;
  margin-left: auto;
  padding-left: 40px;
}
.opYLj {
  margin-bottom: 38px;
}
.hfRoK {
  margin-bottom: 40px;
}
.hfRoK:last-child {
  margin-bottom: 0;
}
