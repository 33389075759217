.cR32A {
  position: relative;
  display: flex;
  overflow: hidden;
  height: 600px;
  padding: 60px 105px;
  border-radius: 20px;
  background-color: #000;
  align-items: center;
}
@media (max-width: 1439px) {
  .cR32A {
    height: 520px;
    padding: 40px;
  }
}
@media (max-width: 971px) {
  .cR32A {
    height: auto;
    padding-bottom: 0px;
    flex-direction: column;
    justify-content: flex-start;
  }
}
@media (max-width: 567px) {
  .cR32A {
    padding: 24px 24px 0;
  }
}
._3mAC6 {
  position: absolute;
  left: auto;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 1;
  height: 100%;
}
@media (max-width: 1439px) {
  ._3mAC6 {
    right: -20%;
  }
}
@media (max-width: 971px) {
  ._3mAC6 {
    position: relative;
    right: 0%;
    max-width: 75%;
    margin-top: -10%;
  }
}
._2gPz5 {
  margin-bottom: 24px;
}
._3dSzv {
  position: relative;
  z-index: 2;
  display: grid;
  max-width: 485px;
  justify-items: start;
  -webkit-box-align: center;
  align-items: center;
  align-content: center;
  -webkit-box-flex: 1;
  flex: 1;
  grid-auto-columns: 1fr;
  grid-column-gap: 40px;
  grid-row-gap: 40px;
  grid-template-columns: 1fr;
  grid-template-rows: max-content max-content max-content;
  color: #fff;
}
@media (max-width: 971px) {
  ._3dSzv {
    max-width: 460px;
    justify-content: center;
    grid-row-gap: 24px;
  }
}
.cR32A .L_DLq {
  width: 23%;
}
@media (max-width: 971px) {
  .cR32A .L_DLq {
    margin: 0 auto;
  }
}
@media (max-width: 674px) {
  .cR32A .L_DLq {
    width: 80px;
  }
}
@media (max-width: 567px) {
  .cR32A .L_DLq {
    width: 48px;
  }
}
._2hjcX {
  position: absolute;
  z-index: 3;
  top: 20px;
  right: 20px;
  background: rgba(0,0,0,0.25);
  border-radius: 4px;
  padding: 8px;
  font-size: 12px;
  line-height: 140%;
  color: #fff;
}
.vs1YG {
  font-size: 18px;
}
@media (max-width: 971px) {
  .vs1YG {
    text-align: center;
  }
}
@media (max-width: 674px) {
  .vs1YG {
    font-size: 15px;
  }
}
.yK15- {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: fit-content;
  padding: 20px;
  background: #bbdcb0;
  border-radius: 20px;
  font-size: 15px;
  color: #000;
}
@media (max-width: 971px) {
  .yK15- {
    width: 100%;
    align-items: center;
  }
}
._3qAO1 {
  font-size: 18px;
  font-weight: 600;
}
.zlEhl {
  width: 16px;
  height: 16px;
  color: #596066;
  vertical-align: baseline;
}
._66ybm {
  width: 610px;
  font-size: 18px;
  color: #a1a490;
}
@media (max-width: 1238px) {
  ._66ybm {
    width: 100%;
  }
}
@media (max-width: 567px) {
  ._66ybm {
    font-size: 15px;
  }
}
