.tYNVC {
  font-family: 'NoticiaText', Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 1.444;
}
@media (max-width: 567px) {
  .tYNVC {
    font-size: 15px;
    line-height: 1.333;
  }
}
@media (max-width: 674px) {
  ._3X7cq {
    margin-right: -32px;
    margin-left: -32px;
    border-radius: 0;
  }
}
@media (max-width: 567px) {
  ._3X7cq {
    margin-right: -15px;
    margin-left: -15px;
  }
}
._3NSo9 {
  display: flex;
}
@media (max-width: 971px) {
  ._3NSo9 {
    flex-direction: column;
  }
}
._3mXfL {
  margin-bottom: 40px;
  padding: 0;
}
._2_2gP {
  max-width: 620px;
  width: 100%;
}
.NN5ih {
  padding-top: 12px;
  border-top: 1px solid #eceee3;
}
._3wjrL {
  position: relative;
  width: 260px;
  margin-right: 40px;
}
@media (max-width: 971px) {
  ._3wjrL {
    width: auto;
    margin-right: 0;
    margin-bottom: 24px;
  }
}
._2tTxW:not(:last-child) {
  margin-bottom: 40px;
}
._2b0KA {
  position: absolute;
  right: 0;
  width: 260px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 150ms ease-out, visibility 150ms ease-out;
}
@media (max-width: 1439px) {
  ._2b0KA {
    left: 0;
    padding-top: 40px;
  }
}
._1y-Pk {
  opacity: 1;
  visibility: visible;
}
.oWHJ_ {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 40px;
}
@media (max-width: 1439px) {
  .oWHJ_ {
    align-items: flex-end;
  }
}
._2anNE {
  width: 620px;
  clear: both;
}
@media (max-width: 1079px) {
  ._2anNE {
    width: 560px;
  }
}
@media (max-width: 971px) {
  ._2anNE {
    width: 100%;
  }
}
._2anNE:not(:last-child) {
  margin-bottom: 24px;
}
._3z7ET ._2anNE {
  width: auto;
}
._2M-k1 {
  width: 100%;
}
._2tt_v a {
  line-height: 24px;
  box-shadow: inset 0 -1px 0 0 #474b2f;
  transition: color 0.35s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
}
@media (hover: hover) {
  ._2tt_v a:hover {
    transition-duration: 0.175s;
    color: #a1a490;
    box-shadow: inset 0 -1px 0 0 #a1a490;
  }
}
._2tt_v h1,
._2tt_v h2,
._2tt_v h3,
._2tt_v h4,
._2tt_v h5,
._2tt_v h6,
._2tt_v h1 *,
._2tt_v h2 *,
._2tt_v h3 *,
._2tt_v h4 *,
._2tt_v h5 *,
._2tt_v h6 * {
  text-align: left !important;
}
._2tt_v iframe {
  display: block;
  width: 100%;
  margin-top: 24px;
}
._2ZMzj {
  width: auto;
}
@media (max-width: 674px) {
  ._3OfWM {
    width: 100vw;
    margin-right: -32px;
    margin-left: -32px;
  }
}
@media (max-width: 567px) {
  ._3OfWM {
    margin-right: -15px;
    margin-left: -15px;
  }
}
._1Uwqu {
  width: 100%;
}
._18jwb ._2anNE {
  width: 100%;
}
@media (max-width: 674px) {
  ._18jwb ._3OfWM {
    width: 100vw;
  }
}
._18jwb ._3wjrL {
  display: none;
}
._18jwb ._2anNE {
  width: 100%;
}
._18jwb ._3OfWM {
  width: auto;
}
._18jwb .NN5ih {
  padding-top: 0;
  border: none;
}
._18jwb h2 {
  margin-top: 16px;
  margin-bottom: 16px;
  font-weight: 600;
  font-size: 22px;
  line-height: 24px;
}
._18jwb a {
  text-decoration: underline;
}
._18jwb ul,
._18jwb ol {
  margin: 0;
  padding: revert;
}
._18jwb ul > li,
._18jwb ol > li {
  padding: 0;
}
._18jwb p {
  margin-bottom: 0;
}
._10fEv {
  padding-top: 40px;
  text-align: center;
}
._167NQ {
  padding-right: 16px;
  padding-left: 16px;
  border-color: #eff0e7;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-size: 15px;
  color: #474b2f;
}
._167NQ:before {
  border-width: 2px;
}
._167NQ svg {
  display: none;
}
@media (max-width: 567px) {
  ._167NQ {
    max-width: 100%;
    font-size: 15px;
  }
}
._36oGq {
  display: block;
  margin-bottom: 8px;
  color: #9ea5ad;
}
._33a_m {
  display: block;
  margin-bottom: 24px;
  color: #9ea5ad;
}
._2iZ9S {
  text-decoration: underline;
  color: #9ea5ad;
}
