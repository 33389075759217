._3rFL0 {
  width: 256px;
  padding: 32px 25px 36px;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 1.333;
  color: #a1a490;
  border-radius: 20px;
  background-color: #f9fbf7;
}
@media (max-width: 971px) {
  ._3rFL0 {
    padding: 32px 24px;
    width: auto;
  }
}
@media (max-width: 674px) {
  ._3rFL0 {
    padding: 32px;
    border-radius: 0;
  }
}
@media (max-width: 567px) {
  ._3rFL0 {
    padding: 18px 17px 28px;
  }
}
._3tNML {
  width: 260px;
  padding: 24px;
  border-radius: 20px;
  background-color: #f9fbf7;
  margin-bottom: 12px;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 1.333;
  color: #a1a490;
}
._3tNML > .Znrlx {
  margin-bottom: 0;
}
@media (max-width: 971px) {
  ._3tNML {
    display: none;
  }
}
._2uy19 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.Znrlx {
  margin-bottom: 24px;
}
@media (max-width: 567px) {
  ._3yYvq {
    margin-top: 32px;
  }
}
.Znrlx,
._3yYvq {
  position: relative;
  min-height: 57px;
  margin-bottom: 22px;
}
@media (max-width: 971px) {
  .Znrlx,
  ._3yYvq {
    min-height: 35px;
    margin-bottom: 0;
  }
}
@media (max-width: 567px) {
  .Znrlx,
  ._3yYvq {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}
._1_RWc {
  display: inline-block;
  vertical-align: middle;
}
._1_RWc > div {
  border: 4px solid #f2f2ea;
}
@media (max-width: 567px) {
  ._1_RWc > div {
    width: 45px !important;
    height: 45px !important;
  }
}
._1MskX {
  margin-top: 16px;
}
@media (max-width: 971px) {
  ._2vLkh {
    display: flex;
    justify-content: space-between;
  }
}
@media (max-width: 567px) {
  ._2vLkh {
    display: block;
  }
}
@media (max-width: 971px) {
  ._2vLkh:not(:last-child) {
    margin-bottom: 24px;
  }
}
@media (max-width: 567px) {
  ._2vLkh:not(:last-child) {
    margin-bottom: 0;
  }
}
._2vLkh > .Znrlx {
  display: none;
}
@media (max-width: 971px) {
  ._2vLkh > .Znrlx {
    display: block;
  }
}
._3zPii {
  width: 260px;
  height: 168px;
  border-radius: 20px;
  background-color: var(--metasite-bg-color, #e7e6fe);
  background-image: var(--metasite-bg-image);
  background-size: cover;
  text-align: center;
  padding: 28px 24px;
  margin-top: 12px;
}
@media (max-width: 567px) {
  ._3zPii {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
}
._29sAR {
  font-size: 18px;
  line-height: 23px;
  text-align: center;
  font-weight: 600;
  font-family: EuclidCircularB;
  color: var(--metasite-text-color, #fff);
}
._2LF_k {
  position: relative;
  display: block;
  cursor: pointer;
  width: 212px;
  height: 48px;
  border-radius: 12px;
  padding: 10px 20px;
  margin-top: 18px;
  text-align: center;
  z-index: 1;
}
._2LF_k::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: var(--metasite-button-bg-color, #c0b6f1);
  z-index: -1;
}
._2LF_k:hover::before {
  opacity: 0.7;
}
@media (max-width: 567px) {
  ._2LF_k {
    width: 100%;
  }
}
._1-PX_ {
  font-weight: 500;
  font-size: 16px;
  font-family: EuclidCircularB;
  line-height: 20px;
  text-align: center;
  color: var(--metasite-button-text-color, #474b2f);
}
@media (max-width: 971px) {
  ._2Gmh_ {
    width: calc(50% - 16px);
  }
}
@media (max-width: 567px) {
  ._2Gmh_ {
    width: 100%;
  }
}
._3xiMN {
  display: flex;
  column-gap: 25px;
}
._21IbZ {
  margin-bottom: 24px;
}
.WrJPH {
  margin-bottom: 0;
}
._1A9-X {
  text-align: center;
}
@media (max-width: 971px) {
  ._21IbZ {
    margin-bottom: 0;
  }
}
@media (max-width: 567px) {
  ._21IbZ {
    margin-bottom: 16px;
  }
  .WrJPH {
    margin-bottom: 0;
  }
}
.BgZnm {
  padding-left: 5px;
  font-weight: 600;
  color: #a1a490;
}
._1A9-X ._3YCA8 {
  text-align: left;
}
.l4s_0 {
  display: inline-block;
  width: 140px;
  margin-right: 9px;
  overflow: hidden;
  vertical-align: middle;
}
@media (max-width: 971px) {
  .l4s_0 {
    width: 152px;
    margin-right: 24px;
  }
}
.JmBYg {
  display: flex;
  flex-direction: column;
}
@media (max-width: 971px) {
  .JmBYg {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
._3BFxd {
  color: #474b2f;
}
._2S6P6 {
  overflow: hidden;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  white-space: inherit;
}
._7ab8L {
  color: #474b2f;
}
@media (hover: hover) {
  ._7ab8L {
    transition: color 0.35s;
  }
  ._7ab8L:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
._7ab8L:not(:last-child) {
  margin-bottom: 2px;
}
@media (max-width: 971px) {
  ._7ab8L:not(:last-child) {
    margin-right: 12px;
  }
}
@media (max-width: 567px) {
  ._7ab8L:not(:last-child) {
    margin-right: 8px;
  }
}
@media (max-width: 971px) {
  ._7ab8L {
    display: inline-block;
    vertical-align: top;
  }
}
@media (max-width: 567px) {
  ._7ab8L {
    display: block;
  }
}
._1aRTJ {
  color: #a1a490;
}
._16qvK {
  font-weight: 600;
}
._3CKhu {
  color: #474b2f;
}
@media (hover: hover) {
  ._3CKhu {
    transition: color 0.35s;
  }
  ._3CKhu:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
._3jSyG {
  display: inline-flex;
  align-items: center;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.333;
}
._3famT {
  padding-right: 4px;
  color: #474b2f;
}
._3jSyG svg {
  width: 20px;
  height: 20px;
}
._2GOuZ {
  display: flex;
  align-items: center;
  gap: 10px;
}
._3ENAR {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
._3ENAR > * {
  margin: 0;
}
._3ENAR svg {
  height: 20px;
}
