.vf7Cr {
  display: flex;
  width: auto;
  min-width: 320px;
}
._1rYYP {
  display: flex;
  box-shadow: 0 12px 40px 0 rgba(0,0,0,0.12);
  padding: 24px;
  border-radius: 24px;
  flex: 1;
}
