.PoRC9 {
  margin-bottom: 22px;
}
.PoRC9:last-child {
  margin-bottom: 0;
}
@media (max-width: 674px) {
  .PoRC9 {
    margin-right: -32px;
    margin-left: -32px;
  }
}
@media (max-width: 567px) {
  .PoRC9 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
@media (max-width: 567px) {
  ._3GGzb {
    justify-content: center;
  }
}
