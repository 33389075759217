.nta_h {
  display: flex;
  margin-bottom: 40px;
  padding-top: 80px;
  padding-bottom: 80px;
  text-align: left;
}
@media (max-width: 971px) {
  .nta_h {
    display: block;
    max-width: 443px;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
  }
}
@media (max-width: 567px) {
  .nta_h {
    max-width: 290px;
    margin-bottom: 20px;
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
._3Oj1R {
  flex-shrink: 0;
  width: 443px;
  height: 143px;
  margin-right: 7px;
}
@media (max-width: 971px) {
  ._3Oj1R {
    margin-right: 0;
    margin-bottom: 7px;
  }
}
@media (max-width: 567px) {
  ._3Oj1R {
    width: auto;
    height: 93px;
    margin-bottom: 4px;
  }
}
._3Utf4 {
  display: block;
  max-width: 100%;
}
._3gG-p {
  flex: 1;
  margin-right: calc((-100vw + 1220px) / 2);
}
@media (max-width: 1439px) {
  ._3gG-p {
    margin-right: calc((-100vw + 920px) / 2);
  }
}
@media (max-width: 1079px) {
  ._3gG-p {
    margin-right: calc((-100vw + 860px) / 2);
  }
}
@media (max-width: 971px) {
  ._3gG-p {
    margin-right: 0;
  }
}
.jp5O7 {
  font-size: 24px;
}
@media (max-width: 567px) {
  .jp5O7 {
    font-size: 18px;
  }
}
._1mPxH {
  margin-left: 5px;
  text-decoration: underline;
  color: #f4b9e3;
}
._3NL65 {
  display: flex;
  align-items: center;
  height: 43px;
  margin-bottom: 7px;
  padding-right: 20px;
  padding-left: 20px;
  color: #f4b9e3;
  background-color: #000;
}
._3NL65:last-child {
  margin-bottom: 0;
}
._3NL65:first-child {
  border-top-left-radius: 4px;
}
._3NL65:last-child {
  border-bottom-left-radius: 4px;
}
@media (max-width: 971px) {
  ._3NL65 {
    justify-content: center;
    border-radius: 4px;
  }
}
@media (max-width: 567px) {
  ._3NL65 {
    height: 28px;
    margin-bottom: 4px;
  }
}
._38wOi {
  padding-bottom: 60px;
}
@media (max-width: 674px) {
  ._38wOi {
    padding-bottom: 40px;
  }
}
