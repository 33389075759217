._13pXc {
  margin-bottom: 24px;
}
._13pXc:last-child {
  margin-bottom: 0;
}
._21YT6 {
  display: flex;
}
@media (max-width: 971px) {
  ._21YT6 {
    flex-direction: column-reverse;
    align-items: center;
  }
}
._1X5D3 {
  flex-shrink: 0;
}
.bXgUr {
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #afb599;
}
@media (max-width: 567px) {
  .bXgUr {
    margin-bottom: 24px;
  }
}
._1GJQ_ {
  margin-right: 20px;
}
@media (min-width: 972px) {
  ._1GJQ_ {
    margin-left: 20px;
  }
}
.XAuqd {
  margin-right: 6px;
  padding-right: 16px;
  padding-left: 16px;
}
.XAuqd:last-child {
  margin-right: 0;
}
@media (max-width: 567px) {
  .XAuqd span {
    max-width: none !important;
  }
}
.XAuqd [class*='MovingArrow'] {
  display: none;
}
