._3a2-E {
  position: fixed;
  width: 1220px;
  max-width: 1220px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.08);
  z-index: 5;
  left: calc(50% - (1220px / 2));
}
@media (max-width: 1238px) {
  ._3a2-E {
    width: 100%;
    max-width: 100%;
    left: 0px;
    border: none;
    border-radius: 0;
  }
}
@media (max-width: 567px) {
  ._3a2-E {
    margin-bottom: 20px;
  }
}
._2bThc {
  height: 1px;
  margin: 0;
  border: none;
  outline: none;
  background-color: #e4e7d7;
}
._2vhxz,
._2c29v {
  padding-right: 20px;
  padding-left: 20px;
}
@media (max-width: 1238px) {
  ._2vhxz,
  ._2c29v {
    height: 64px;
  }
}
@media (max-width: 567px) {
  ._2vhxz,
  ._2c29v {
    height: 56px;
  }
}
._3t6aL {
  display: inline-block;
  width: 16px;
  height: 16px;
  vertical-align: middle;
  color: #e3e5dc;
  margin-left: 4px;
}
._1UXXu {
  transform: rotate(90deg) translateX(1px);
}
._2vhxz {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 4px 20px;
}
@media (max-width: 1238px) {
  ._2vhxz {
    width: 100%;
    max-width: 100%;
    padding: 4px 32px;
  }
}
@media (max-width: 567px) {
  ._2vhxz {
    padding: 4px 16px;
  }
}
.ADSKF {
  position: relative;
  height: 43px;
  overflow: hidden;
  display: flex;
  align-items: center;
}
.ADSKF:last-child {
  margin-right: 0;
}
._2Ra-2 {
  margin-right: 16px;
  font-weight: 500;
  font-size: 16px;
  font-family: EuclidCircularBMedium;
  color: #000;
  line-height: 20px;
  display: flex;
  flex-direction: row;
  text-wrap: nowrap;
  cursor: pointer;
  align-items: center;
}
._2Ra-2:hover {
  opacity: 0.4;
}
._2o0-u:hover {
  opacity: 1;
}
.T_d-5 {
  color: #30148a;
}
.T_d-5:hover {
  color: rgba(48,20,138,0.64);
}
.Nz8f_ {
  display: flex;
  height: 28px;
  padding: 3px 12px 5px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  background-color: var(--metasite-bg-color, #c0b6f1);
  margin-right: 16px;
  font-weight: 500;
  font-size: 16px;
  font-family: EuclidCircularBMedium;
  color: var(--metasite-text-color, #000);
}
.Nz8f_:hover {
  background-color: rgba(187,220,176,0.64);
}
.Nz8f_:active {
  background-color: #abd79a;
}
@media (max-width: 1238px) {
  .Nz8f_ {
    margin-right: 24px;
    height: 40px;
    padding: 4px 24px 5px 24px;
    border-radius: 90px;
  }
}
@media (max-width: 567px) {
  .Nz8f_ {
    margin-right: 16px;
    height: 28px;
    padding: 3px 12px 5px 12px;
    border-radius: 15px;
  }
}
._12mVC {
  margin-left: 20px;
}
@media (max-width: 971px) {
  ._12mVC {
    display: none;
  }
}
._2c29v {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 9px;
  padding-bottom: 8px;
}
._1shvN {
  display: flex;
  align-items: center;
  align-content: center;
}
._12mVC,
._1GQjT,
._2gon0 {
  flex-shrink: 0;
}
._1GQjT {
  margin-right: 20px;
  padding-top: 0;
  padding-bottom: 0;
}
@media (hover: hover) {
  ._1GQjT {
    transition: opacity 0.35s;
  }
  ._1GQjT:hover {
    opacity: 0.5;
  }
}
._3I9yo {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
}
@media (max-width: 971px) {
  ._3I9yo {
    display: none;
  }
}
._2gon0,
._3BjVK {
  padding-right: 0;
  padding-left: 0;
  color: #474b2f;
  margin-right: 6px;
}
@media (hover: hover) {
  ._2gon0,
  ._3BjVK {
    transition: color 0.35s;
  }
  ._2gon0:hover,
  ._3BjVK:hover {
    color: #a1a490;
  }
}
._2gon0:hover,
._3BjVK:hover {
  opacity: 0.4;
}
@media (max-width: 971px) {
  ._2gon0:before,
  ._3BjVK:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    width: 44px;
    height: 44px;
    transform: translateX(-50%) translateY(-50%);
  }
}
._1oPFe,
._36Cuz {
  margin-right: 4px;
}
._1oPFe:last-child,
._36Cuz:last-child {
  margin-right: 0;
}
@media (max-width: 971px) {
  ._1oPFe,
  ._36Cuz {
    display: none;
  }
}
._2Wje7,
._2Qo4n {
  display: inline-block;
  width: 24px;
  height: 24px;
  vertical-align: sub;
}
._2Wje7 svg,
._2Qo4n svg {
  transform: translateZ(0);
}
._3BjVK {
  color: inherit;
}
.SiN3W {
  color: #a1a490;
  height: auto;
  margin-top: -10px;
  margin-right: -6px;
  margin-bottom: -10px;
  padding: 0;
  color: #dadcd5;
}
@media (hover: hover) {
  .SiN3W {
    transition: color 0.35s;
  }
  .SiN3W:hover {
    color: #474b2f;
  }
}
.Qm9Ew {
  display: block;
  width: 32px;
  height: 32px;
  vertical-align: middle;
}
@media (max-width: 567px) {
  .Qm9Ew {
    width: 24px;
    height: 24px;
  }
}
._1XoB7 {
  display: flex;
  flex-flow: row nowrap;
}
