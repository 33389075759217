._2gNin {
  position: relative;
}
._2gNin ._2Dg58 {
  display: block;
  width: 100%;
  height: 100%;
  padding: 17px 15px;
  border: 1px solid #eceee3;
  border-radius: 5px;
  overflow: auto;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #474b2f;
  background: #fff;
  transition: border-color 0.35s;
}
._2gNin ._2Dg58::placeholder {
  opacity: 1;
  line-height: 20px;
  color: #b2b7a3;
}
._2gNin ._2Dg58:focus {
  border-color: #c8c9bf;
}
@media (hover: hover) {
  ._2gNin ._2Dg58:hover {
    border-color: #c8c9bf;
  }
}
.PKZqN {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 11px;
  font-weight: 600;
  line-height: 14px;
  color: #b2b699;
}
.QWPRN ._2Dg58 {
  font-weight: 600;
  color: #ef6c00;
}
.QWPRN ._2Dg58::placeholder {
  font-weight: 600;
  color: #ef6c00;
}
