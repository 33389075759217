._3aRWJ {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 16px;
  color: #000;
  z-index: 2;
  width: 516px;
  margin: 0 24px 24px 0;
  --card-content-color: #cfdbf9;
}
@media (max-width: 1439px) {
  ._3aRWJ {
    width: 450px;
  }
}
@media (max-width: 567px) {
  ._3aRWJ {
    width: calc(100vw - 30px);
    gap: 12px;
  }
}
.xLZil {
  --card-content-color: #cfdbf9;
}
.ZoPGb {
  --card-content-color: #facad9;
}
._3yTks {
  --card-content-color: #d9d2f3;
}
._2SpYq {
  --card-content-color: #eef3b6;
}
._2BKqk {
  --card-content-color: #bbdcb0;
}
._3aRWJ::before {
  position: absolute;
  z-index: -1;
  content: '';
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: var(--card-content-color);
  transform: translate(24px, 24px);
}
@media (max-width: 971px) {
  ._3aRWJ::before {
    transform: translate(15px, 15px);
  }
}
._155rX {
  width: 100%;
  height: 290px;
  border-radius: 20px;
  flex-shrink: 1;
}
._155rX img {
  object-fit: cover;
}
@media (max-width: 1439px) {
  ._155rX {
    height: 257px;
  }
}
@media (max-width: 567px) {
  ._155rX {
    height: auto;
  }
}
._1Qd_p:hover ~ .los2g ._1tX6r {
  color: #227422;
}
.los2g {
  justify-content: space-between;
}
.los2g,
._27zWK {
  display: flex;
  align-items: center;
  gap: 16px;
}
._27zWK {
  flex-shrink: 0;
  flex-grow: 1;
}
._3OS6B {
  display: flex;
  flex-direction: column;
}
._3w9eo {
  display: flex;
  align-items: center;
  gap: 8px;
}
a:hover ._2MH4z {
  color: #227422;
}
a:hover ._8-3DN {
  background-color: #474b2f;
  border-color: #474b2f;
  color: #fff;
}
._2MH4z {
  font-size: 12px;
  font-weight: 400;
  transition: color 0.2s;
}
._8-3DN {
  border: 1px solid #000;
  border-radius: 8px;
  padding: 2px 4px;
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.4;
  text-transform: uppercase;
  transition: color 0.2s, background-color 0.2s, border-color 0.2s;
}
.I1D2E {
  width: 72px;
  height: 72px;
  border-radius: 20px;
  border: 2px solid #f9fbf7;
}
.I1D2E img {
  height: 100%;
  object-fit: cover;
}
._2N8N4:hover + ._3OS6B ._2MH4z {
  color: #227422;
}
._2N8N4:hover + ._3OS6B ._8-3DN {
  background-color: #474b2f;
  border-color: #474b2f;
  color: #fff;
}
._1tX6r {
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #000;
  transition: color 0.35s cubic-bezier(0.215, 0.61, 0.355, 1);
}
._1tX6r:hover {
  color: #227422;
}
._2GfMT {
  display: block;
  position: relative;
  width: 320px;
  height: 288px;
  --card-content-color: #ebc0b7;
}
@media (max-width: 567px) {
  ._2GfMT {
    width: 280px;
  }
}
._1VgQ9 {
  --card-content-color: #ebc0b7;
}
._1hBYt {
  --card-content-color: #d9d2f3;
}
._3MYBE {
  --card-content-color: #cfdbf9;
}
._1sP0r {
  --card-content-color: #bbdcb0;
}
._2GfMT:hover ._3xWlQ {
  max-height: calc(100% - 20px);
}
._2GfMT:hover ._2WMqa {
  max-height: 100%;
  flex-shrink: 0;
  -webkit-line-clamp: 10;
}
._2GfMT:hover .b1sZ3 {
  height: 164px;
  flex-grow: 1;
  flex-shrink: 1;
  -webkit-line-clamp: 10;
}
._2mlFF {
  width: 100%;
  height: 220px;
  border-radius: 20px;
}
@media (max-width: 567px) {
  ._2mlFF {
    height: 192px;
  }
}
._2mlFF img {
  height: 100%;
  object-fit: cover;
}
._3xWlQ {
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0;
  width: calc(100% - 40px);
  max-height: 108px;
  overflow: hidden;
  padding: 16px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  background-color: var(--card-content-color);
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  transition: max-height 0.2s;
}
._2WMqa {
  width: 100%;
  max-height: 40px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #000;
  flex-shrink: 0;
  overflow: hidden;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  white-space: initial;
}
.b1sZ3 {
  height: 32px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: rgba(0,0,0,0.4);
  overflow: hidden;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: break-word;
  margin-bottom: 16px;
  flex-shrink: 0;
  white-space: initial;
}
._1kyTn {
  width: 100%;
  height: 164px;
}
._21RV8 {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  color: #000;
}
