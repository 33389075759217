._3Qt1Z {
  margin-bottom: 16px;
}
.Vd4KQ {
  padding-top: 24px;
  padding-bottom: 24px;
}
._7Umj2 {
  margin-bottom: 40px;
}
._7Umj2:last-child {
  margin-bottom: 0;
}
