._3penq {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 24px 24px 16px;
  z-index: 1;
}
._2MPz9,
.MSmVs {
  display: flex;
  justify-content: space-between;
}
.vIxS9 {
  align-self: flex-start;
  margin-left: auto;
}
._3eipq {
  line-height: 24px;
  color: #fff;
}
._1CmQJ {
  flex: 0 0 120px;
  width: 120px;
  height: 24px;
}
._1rNv8 {
  display: block;
  max-width: 100%;
  max-height: 100%;
}
