._2ibwS {
  display: flex;
  padding-top: 56px;
}
@media (max-width: 971px) {
  ._2ibwS {
    padding-top: 31px;
  }
}
@media (max-width: 971px) {
  ._2ibwS {
    width: calc(100% + 30px);
  }
}
@media (max-width: 674px) {
  ._2ibwS {
    width: calc(100% + 64px);
    margin-left: -32px;
  }
}
@media (max-width: 567px) {
  ._2ibwS {
    width: calc(100% + 32px);
    margin-right: calc(15px * -1);
    margin-left: calc(15px * -1);
  }
}
._32K4- {
  width: 112px;
  background-size: 80% 80%;
  height: 112px;
}
@media (max-width: 971px) {
  ._32K4- {
    width: 76px;
    height: 76px;
  }
}
.hSWyI {
  position: absolute;
  top: 70px;
  left: 32px;
}
@media (max-width: 1439px) {
  .hSWyI {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 32px;
  }
}
._3kLtr {
  position: absolute;
  left: 32px;
  bottom: 32px;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1439px) {
  ._3kLtr {
    position: relative;
    top: 0;
    left: 0;
    flex-direction: row;
  }
}
@media (max-width: 567px) {
  ._3kLtr {
    flex-direction: column;
    width: 100%;
  }
  ._3kLtr ._1B1yP {
    width: 100%;
  }
  ._3kLtr ._1B1yP label {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}
.F8PCu {
  position: relative;
  flex-shrink: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 40px 0 40px 40px;
}
@media (max-width: 1439px) {
  .F8PCu {
    display: block;
    justify-content: normal;
    padding: 70px 32px 32px;
  }
}
@media (max-width: 971px) {
  .F8PCu {
    padding-top: 50px;
  }
}
@media (max-width: 674px) {
  .F8PCu {
    padding-right: 0;
  }
}
@media (max-width: 567px) {
  .F8PCu {
    padding-right: 15px;
    padding-left: 15px;
  }
}
.F8PCu::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  width: 368.000000046px;
  border-radius: 20px;
  background-color: #fafafa;
}
@media (max-width: 1439px) {
  .F8PCu::before {
    width: 100%;
    background-color: #f4f4f1;
  }
}
@media (max-width: 971px) {
  .F8PCu::before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
@media (max-width: 674px) {
  .F8PCu::before {
    border-radius: 0;
  }
}
._18WEC {
  margin-bottom: 4px;
  word-break: break-word;
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
}
._18WEC:last-child {
  margin-bottom: 0;
}
.wwzTL {
  margin-bottom: 20px;
  font-weight: 400;
  color: #a1a490;
}
.wwzTL:last-child {
  margin-bottom: 0;
}
@media (max-width: 1079px) {
  .wwzTL {
    margin-bottom: 32px;
  }
}
@media (max-width: 567px) {
  ._1B1yP {
    margin-right: auto;
  }
}
._2RHuk {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 920.000000054px;
  border-radius: 20px;
  overflow: hidden;
}
@media (max-width: 1439px) {
  ._2RHuk {
    width: 100%;
    margin-bottom: 32px;
  }
}
@media (max-width: 1079px) {
  ._2RHuk {
    border-radius: 0;
    overflow: visible;
  }
}
._1f0Is {
  display: flex;
  width: calc(50% - 1px);
  margin-top: 1px;
  margin-bottom: 1px;
}
@media (max-width: 1079px) {
  ._1f0Is:first-of-type ._3dZl- {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
  }
  ._1f0Is:last-of-type ._3dZl- {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
._3dZl- {
  width: 100%;
}
._2Avk5 {
  margin-bottom: 32px;
}
._2Avk5:last-child {
  margin-bottom: 0;
}
@media (max-width: 1079px) {
  ._2Avk5 {
    margin-right: calc((-100vw + 860px) / 2 - 32px);
  }
}
@media (max-width: 971px) {
  ._2Avk5 {
    margin-right: 0;
  }
  ._2Avk5 .swiper-container {
    margin-right: calc(32px * -1);
    padding-right: 32px;
  }
}
@media (max-width: 567px) {
  ._2Avk5 .swiper-container {
    margin-right: calc(15px * -1);
    margin-left: calc(15px * -1);
    padding-right: 15px;
    padding-left: 15px;
  }
}
.d-zDI {
  flex-shrink: 0;
  width: 224px;
  margin: 0;
  padding-right: 2px;
}
