.CC4zD {
  margin-bottom: 60px !important;
}
._2bTSG {
  display: flex;
  align-items: flex-start;
}
._3SsQo {
  margin-bottom: 60px;
}
._3seyH {
  margin-bottom: 132px;
}
@media (min-width: 1440px) {
  .iv2EB {
    width: 920px;
    margin-right: 40px;
  }
}
._1f_cU {
  width: 260px;
}
._5gZrI {
  margin-top: 75px;
}
._1xq7R {
  margin-bottom: 40px;
}
._32UXR {
  display: block;
  width: 920px;
  margin-bottom: 40px;
  column-count: 2;
  column-gap: 40px;
}
._3HZeU {
  margin-bottom: 60px;
  break-inside: avoid;
}
