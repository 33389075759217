.FTLFi {
  position: relative;
  display: flex;
  padding-top: 32px;
  padding-right: 25px;
  padding-left: 25px;
  z-index: 2;
}
._1Oaun {
  flex: 1 1 0;
  margin-right: 16px;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
}
._1ZwO7 {
  position: relative;
  flex-shrink: 0;
  width: 100%;
  padding: 25px;
}
.UuDYy {
  z-index: 1;
}
._5h906 {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  height: 19px;
}
._5imYf {
  position: relative;
  width: 9px;
  height: 9px;
  margin-right: 10px;
  border-radius: 50%;
  cursor: pointer;
}
._5imYf:last-child {
  margin-right: 0;
}
._5imYf:before,
._5imYf:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
._5imYf:before {
  border-radius: inherit;
  background-color: #d7d8d1;
  transition: transform 0.35s, background-color 0.35s;
  transform: scale(0.66666);
  will-change: transform;
}
._5imYf:after {
  content: '';
  transform: scale(2.1);
}
._120ty {
  cursor: default;
  pointer-events: none;
}
._120ty:before {
  background-color: #000;
  transform: scale(1);
}
@media (hover: hover) {
  ._5imYf:hover:before {
    transition-duration: 0.175s;
    background-color: #000;
  }
}
._3VRkV {
  position: relative;
}
._3VRkV .FTLFi {
  position: absolute;
  top: 0;
  right: 0;
  padding: 24px;
  z-index: 2;
}
._3VRkV ._5h906 {
  height: 24px;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 12px;
  background-color: rgba(0,0,0,0.32);
}
._3VRkV ._5imYf {
  width: 8px;
  height: 8px;
}
._3VRkV ._5imYf:before {
  opacity: 0.6;
  background-color: #fff;
  transition: opacity 0.35s;
  transform: scale(0.5);
}
._3VRkV ._120ty:before {
  opacity: 1;
  transform: scale(1);
}
