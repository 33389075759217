._32wH2 {
  padding-left: 32px;
}
@media (max-width: 1079px) {
  ._32wH2 {
    padding-left: 0;
  }
}
._1w-Di {
  margin-bottom: -32px;
  margin-left: -32px;
}
@media (max-width: 1079px) {
  ._1w-Di {
    margin-left: 0;
  }
}
._3RYct {
  position: relative;
}
._3RYct:not(:last-child) {
  margin-bottom: 42px;
}
@media (max-width: 674px) {
  ._3RYct:not(:last-child) {
    margin-bottom: 20px;
  }
}
.vx-Ul {
  position: relative;
  margin-bottom: 2px;
  overflow: hidden;
  transform: translateZ(0);
}
@media (max-width: 674px) {
  .vx-Ul {
    overflow: initial;
  }
}
@media (max-width: 567px) {
  .vx-Ul {
    margin-bottom: 24px;
  }
}
._2r9Dr {
  width: 100%;
  height: 144px;
  margin-top: 70px;
  margin-bottom: 110px;
  border-radius: 20px;
  background-color: var(--metasite-bg-color, #e7e6fe);
  background-image: var(--metasite-bg-image);
  background-size: cover;
  padding: 44px 60px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 567px) {
  ._2r9Dr {
    padding: 24px 20px;
    flex-direction: column;
  }
}
.Bucyk {
  font-size: 28px;
  line-height: 36px;
  text-align: left;
  font-weight: 600;
  font-family: EuclidCircularB;
  color: var(--metasite-text-color, #fff);
}
@media (max-width: 567px) {
  .Bucyk {
    font-size: 16px;
    line-height: 20px;
    text-align: center;
  }
}
._2OvqV {
  position: relative;
  display: block;
  cursor: pointer;
  width: 220px;
  height: 56px;
  border-radius: 14px;
  padding: 16px 24px;
  background-color: var(--metasite-button-bg-color, #c0b6f1);
  text-align: center;
}
@media (max-width: 567px) {
  ._2OvqV {
    width: 100%;
    height: 44px;
    margin-top: 12px;
    padding: 12px 24px;
  }
}
._2OvqV::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: inherit;
  background-color: var(--metasite-button-bg-color, #c0b6f1);
  z-index: -1;
}
._2OvqV:hover::before {
  opacity: 0.7;
}
._2z6BL {
  font-weight: 500;
  font-size: 20px;
  font-family: EuclidCircularB;
  line-height: 24px;
  text-align: center;
  color: var(--metasite-button-text-color, #000);
}
@media (max-width: 567px) {
  ._2z6BL {
    font-size: 16px;
    line-height: 20px;
  }
}
._3kuh2 {
  opacity: 0;
  transition: opacity 0.2s ease-in;
}
._2IOUV {
  opacity: 1;
  transition: opacity 0.2s ease-out;
}
._3IH2h {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in;
}
._3yhiw {
  opacity: 1;
  visibility: visible;
  transition: opacity 0.2s ease-out;
}
._246QL {
  padding-top: 24px;
  padding-bottom: 24px;
}
@media (max-width: 674px) {
  ._246QL {
    margin-left: -15px;
  }
}
._2d1zg {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-left: -32px;
}
._1YoZX {
  flex: 1 1 0%;
  margin-left: 32px;
}
._3enxs {
  display: flex;
  justify-content: center;
}
