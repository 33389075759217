._1ndu4 {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  color: #fff;
  background-color: #143f14;
}
._1ndu4:before {
  content: '';
  position: absolute;
  top: -25px;
  right: -178px;
  width: 200%;
  height: 100%;
  background-image: url("/static/images/Footer/bg.jpg");
  background-repeat: no-repeat;
  background-position: 100% 0;
  background-size: auto 536px;
}
@media (max-width: 674px) {
  ._1ndu4:before {
    opacity: 0.65;
  }
}
@media (max-width: 567px) {
  ._1ndu4:before {
    top: -2px;
    right: -158px;
    opacity: 0.5;
    background-size: auto 424px;
  }
}
._3Gr3h {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 664px;
  padding: 60px 36px 36px;
  z-index: 1;
}
@media (max-width: 971px) {
  ._3Gr3h {
    min-height: 0;
    border-radius: 0;
  }
}
@media (max-width: 674px) {
  ._3Gr3h {
    padding-top: 48px;
    padding-bottom: 48px;
  }
}
@media (max-width: 567px) {
  ._3Gr3h {
    padding-right: 15px;
    padding-left: 15px;
  }
}
._12ReD {
  margin-bottom: 12px;
  font-size: 31px;
  word-break: break-word;
  color: #e9ff4c;
}
._10ogQ {
  margin-bottom: 32px;
  font-size: 18px;
  font-weight: 600;
}
@media (max-width: 674px) {
  ._10ogQ {
    margin-bottom: 22px;
  }
}
._275bL {
  position: relative;
  margin-bottom: 64px;
}
@media (max-width: 674px) {
  ._275bL {
    margin-bottom: 32px;
  }
}
@media (max-width: 567px) {
  ._275bL {
    margin-bottom: 24px;
  }
}
._1RexW {
  position: relative;
  display: flex;
  height: 70px;
  border-radius: 8px;
  overflow: hidden;
  background-color: #fff;
  transform: translateZ(0);
}
@media (max-width: 674px) {
  ._1RexW {
    height: 56px;
  }
}
.fuBp7 {
  width: calc(100% - 148px);
  height: 100%;
  font-size: 18px;
  font-weight: 600;
}
@media (max-width: 567px) {
  .fuBp7 {
    width: 100%;
  }
}
._2veLF {
  position: absolute;
  top: 50%;
  right: 15px;
  margin-top: calc(44px/-2);
  color: #227422;
  background-color: #e9ff4c;
}
.TiBqT {
  display: none;
}
@media (max-width: 567px) {
  ._2veLF {
    position: static;
    display: initial;
    margin-top: 12px;
    box-shadow: 0 0 2px 0 #ebebeb;
  }
}
._2jOHL {
  align-self: center;
  max-width: 148px;
  max-height: 100%;
  padding-right: 22px;
  overflow: hidden;
  font-weight: 600;
  color: #ef6c00;
}
._3F9EP {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
._2uSHO {
  display: flex;
  align-items: center;
  padding: 4px 16px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
  color: #474b2f;
  background-color: inherit;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
._12vIf {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 32px;
}
@media (max-width: 971px) {
  ._12vIf {
    margin-bottom: 52px;
  }
}
@media (max-width: 674px) {
  ._12vIf {
    margin-bottom: 2px;
  }
}
.myqKB {
  width: 50%;
  margin-bottom: 35px;
  padding-right: 12px;
  break-inside: avoid;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.GLWx3 {
  display: flex;
  width: 32px;
  height: 32px;
  padding: 3.85px 4px 4.15px 4px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  margin-right: 12px;
}
@media (max-width: 971px) {
  .myqKB {
    margin-bottom: 30px;
  }
}
@media (max-width: 674px) {
  .myqKB {
    margin-bottom: 25px;
  }
}
.myqKB:nth-child(even) {
  padding-left: 15px;
}
@media (max-width: 674px) {
  .myqKB:nth-child(even) {
    padding-left: 35px;
  }
}
@media (max-width: 567px) {
  .myqKB:nth-child(even) {
    padding-left: 6px;
  }
}
._3Mvc7 {
  margin-bottom: 4px;
  font-size: 15px;
  font-weight: 600;
}
._36Aim {
  font-size: 18px;
  font-weight: 700;
  color: #e9ff4c;
}
@media (hover: hover) {
  ._36Aim {
    transition: color 0.35s;
  }
  ._36Aim:hover {
    transition-duration: 0.175s;
    color: rgba(233,255,76,0.6);
  }
}
._2Sc5a {
  margin-top: auto;
  font-size: 15px;
  color: #a1a490;
}
._32aq6 {
  margin-bottom: 12px;
}
._32aq6:last-child {
  margin-bottom: 0;
}
._32aq6 a {
  color: #474b2f;
  color: inherit;
}
@media (hover: hover) {
  ._32aq6 a {
    transition: color 0.35s;
  }
  ._32aq6 a:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
._2rpKq {
  width: 14px;
  height: 14px;
  margin-left: 4px;
  vertical-align: baseline;
  color: #a1a492;
}
.cQZMa {
  margin-bottom: 12px;
}
.cQZMa:last-child {
  margin-bottom: 0;
}
.cQZMa a {
  color: #474b2f;
  color: inherit;
}
@media (hover: hover) {
  .cQZMa a {
    transition: color 0.35s;
  }
  .cQZMa a:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
._1Zafd {
  display: inline-block;
  margin-right: 12px;
}
._1Zafd:last-child {
  margin-right: 0;
}
._1Zafd a {
  color: #474b2f;
  color: inherit;
}
@media (hover: hover) {
  ._1Zafd a {
    transition: color 0.35s;
  }
  ._1Zafd a:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
