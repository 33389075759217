.F2a9U {
  margin-bottom: 8px;
}
@media (max-width: 1439px) {
  .F2a9U {
    order: 1;
    margin-bottom: 0;
    margin-left: 8px;
  }
}
@media (max-width: 567px) {
  .F2a9U {
    margin-top: 8px;
    margin-left: 0;
  }
}
.tT-uL {
  margin-left: 8px;
  color: #474b2f;
}
