._1uMbH {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 8px;
  margin: 24px auto;
  max-width: 590px;
}
._2W_zq {
  width: fit-content;
}
._1TkGH {
  max-width: 590px;
  margin: 0 auto;
}
._2M-Pv {
  border: 2px solid #bbdcb0;
  border-radius: 8%;
  width: 100%;
  max-width: 275px;
}
._2M-Pv > svg {
  width: 100%;
  height: 100%;
}
.WMvPv {
  display: flex;
  justify-content: center;
  margin: 8px auto 24px;
  font-weight: 600;
  font-size: 15px;
  line-height: 140%;
  color: #484b32;
}
._1flCS {
  display: inline-flex;
  color: #474b2f;
}
._1flCS > span {
  display: inline-flex;
  align-items: center;
  line-height: 20px;
}
._3arrx {
  margin-right: 10px;
}
