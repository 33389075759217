.xniEJ {
  padding: 32px 40px;
  border-radius: 20px;
  overflow: hidden;
  background-color: #f4f4f1;
}
@media (max-width: 1079px) {
  .xniEJ {
    padding-right: 25px;
    padding-left: 25px;
  }
}
@media (max-width: 971px) {
  .xniEJ {
    padding-right: 16px;
    padding-left: 16px;
    padding-bottom: 16px;
  }
}
@media (max-width: 674px) {
  .xniEJ {
    margin-right: -32px;
    margin-left: -32px;
    padding-right: 56px;
    padding-left: 56px;
    padding-bottom: 32px;
  }
}
@media (max-width: 567px) {
  .xniEJ {
    margin-right: -15px;
    margin-left: -15px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 16px;
  }
}
._28wz0 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 16px;
  padding: 0;
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
  color: #484b32;
  background-color: #f4f4f1;
}
@media (max-width: 1439px) {
  ._28wz0 {
    flex-direction: column;
    margin-bottom: 32px;
  }
}
@media (max-width: 674px) {
  ._28wz0 {
    margin-bottom: 24px;
  }
}
._2aR7e {
  flex-shrink: 0;
  order: 1;
  margin-right: 27px;
}
._2aR7e:last-child {
  margin-right: 0;
}
@media (max-width: 1439px) {
  ._2aR7e {
    margin-right: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
  }
}
.WoBio {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
}
._3AAgL {
  position: relative;
  z-index: 1;
  margin-bottom: 16px;
}
._1HEoW {
  margin-bottom: 2px;
  background-color: #fff;
  font-family: "EuclidCircularB";
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #474b2f;
  overflow: hidden;
}
._1HEoW:first-of-type {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
._1HEoW:last-of-type {
  margin-bottom: 0;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
._2L0D4 {
  position: relative;
  display: block;
  padding: 16px 28px 16px 48px;
  font-weight: 600;
  word-break: break-word;
  color: #474b2f;
}
@media (hover: hover) {
  ._2L0D4 {
    transition: color 0.35s;
  }
  ._2L0D4:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
@media (hover: hover) {
  ._2L0D4:hover .RLqvy {
    opacity: 1;
  }
  ._2L0D4:hover ._1Nq-- {
    opacity: 0;
  }
}
._1Nq-- {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #afb599;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
._1l4yD {
  position: absolute;
  top: 50%;
  left: 14px;
  width: 20px;
  height: 20px;
  margin-top: -10px;
}
.RLqvy {
  margin-top: 8px;
  opacity: 0;
  transform: rotate(-90deg);
}
@media (min-width: 1239px) {
  .qr_6C {
    padding: 16px 8px 8px;
  }
}
@media (min-width: 1239px) {
  .qr_6C ._28wz0 {
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
    padding-right: 16px;
    padding-left: 16px;
  }
}
@media (min-width: 1239px) {
  .qr_6C ._2aR7e {
    margin-top: 0;
    order: 0;
  }
}
._212x5 {
  justify-content: center;
  padding: 0;
  width: 100%;
  border: none;
  height: auto;
}
