.bYvNM {
  width: 100vw;
  margin-right: calc((-100vw + 1220px) / 2);
  margin-left: calc((-100vw + 1220px) / 2);
  padding: 42px 0;
  background-color: #1a1f19;
}
@media (max-width: 1439px) {
  .bYvNM {
    margin-right: calc((-100vw + 920px) / 2);
    margin-left: calc((-100vw + 920px) / 2);
  }
}
@media (max-width: 1079px) {
  .bYvNM {
    margin-right: calc((-100vw + 860px) / 2);
    margin-left: calc((-100vw + 860px) / 2);
  }
}
@media (max-width: 971px) {
  .bYvNM {
    margin-right: -32px;
    margin-left: -32px;
  }
}
@media (max-width: 567px) {
  .bYvNM {
    margin-right: -15px;
    margin-left: -15px;
    padding: 32px 0;
  }
}
._3gV2M {
  margin-bottom: 30px !important;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
  color: #fff;
}
._1nOrq {
  margin: 0;
}
._17VId {
  max-width: 920px;
  margin: 0 auto;
}
@media (max-width: 1079px) {
  ._17VId {
    max-width: 860px;
  }
}
@media (max-width: 971px) {
  ._17VId {
    max-width: calc(100vw - 2 * 30px);
  }
}
@media (max-width: 674px) {
  ._17VId {
    max-width: calc(100vw - 2 * 32px);
  }
}
@media (max-width: 567px) {
  ._17VId {
    max-width: calc(100vw - 2 * 15px);
  }
}
._2lHZy {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  margin-bottom: 22px;
  z-index: 1;
}
.RIx3o {
  height: 5px;
  border-radius: 20px;
  background-color: #000;
}
._32G48 {
  position: relative;
  top: -2.5px;
  height: 10px;
  border-radius: inherit;
  background-color: #fff;
  cursor: move;
  cursor: grab;
}
._32G48:active {
  cursor: move;
  cursor: grabbing;
}
.Inafa {
  width: auto;
  height: 460px;
}
@media (max-width: 567px) {
  .Inafa {
    height: 178px;
  }
}
[class*='Vendor__inner'] .bYvNM {
  margin-left: calc((-100vw + 920px) / 2 - 32px);
}
@media (max-width: 1079px) {
  [class*='Vendor__inner'] .bYvNM {
    margin-left: calc((-100vw + 860px) / 2 - 32px);
  }
}
@media (max-width: 971px) {
  [class*='Vendor__inner'] .bYvNM {
    margin-right: -64px;
    margin-left: -64px;
  }
}
@media (max-width: 674px) {
  [class*='Vendor__inner'] .bYvNM {
    margin-right: -32px;
    margin-left: -32px;
  }
}
@media (max-width: 567px) {
  [class*='Vendor__inner'] .bYvNM {
    margin-right: -15px;
    margin-left: -15px;
  }
}
