._17iJt {
  position: relative;
  padding: 125px 43px 42px;
}
@media (max-width: 1439px) {
  ._17iJt {
    padding: 130px 32px 32px;
  }
}
@media (max-width: 971px) {
  ._17iJt {
    margin-right: -32px;
    padding-top: 94px;
  }
}
@media (max-width: 674px) {
  ._17iJt {
    margin-left: -32px;
    padding-top: 82px;
  }
}
@media (max-width: 567px) {
  ._17iJt {
    width: auto;
    margin-right: calc(15px * -1);
    margin-left: calc(15px * -1);
    padding-right: 15px;
    padding-left: 15px;
  }
}
._17iJt:before {
  content: '';
  position: absolute;
  top: 51px;
  right: 0;
  left: 0;
  bottom: 0;
  display: block;
  border-radius: 20px;
  background-image: linear-gradient(128deg, #ef6c00, #ffb7e3);
}
@media (max-width: 1238px) {
  ._17iJt:before {
    max-width: 100%;
  }
}
@media (max-width: 971px) {
  ._17iJt:before {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
}
@media (max-width: 674px) {
  ._17iJt:before {
    top: 32px;
    border-radius: 0;
  }
}
._2q7un {
  cursor: pointer;
  top: 51px;
  width: 112px;
  height: 112px;
}
@media (max-width: 971px) {
  ._2q7un {
    width: 76px;
    height: 76px;
  }
}
@media (max-width: 674px) {
  ._2q7un {
    top: 32px;
  }
}
._2aFU6 {
  background-color: #ef6c00;
  width: 112px;
  height: 112px;
}
@media (max-width: 971px) {
  ._2aFU6 {
    width: 76px;
    height: 76px;
  }
}
._3FkmT {
  position: relative;
  margin-bottom: 32px;
  color: #ffaee0;
}
._3FkmT > a {
  color: inherit;
}
@media (max-width: 971px) {
  ._3FkmT {
    font-size: 31px;
  }
}
._17Uz4 {
  display: flex;
  margin-bottom: 32px;
}
._17Uz4:last-child {
  margin-bottom: 0;
}
@media (max-width: 674px) {
  ._17Uz4 .swiper-container {
    margin-right: -32px;
    margin-left: -32px;
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (max-width: 567px) {
  ._17Uz4 .swiper-container {
    margin-right: calc(15px * -1);
    margin-left: calc(15px * -1);
    padding-right: 15px;
    padding-left: 15px;
  }
}
._2ryHX {
  display: flex;
  width: calc(100% / 3 - 2px * 2 / 3);
  min-width: 198px;
  height: auto;
  margin-right: 2px;
}
._2ryHX:last-child {
  margin-right: 0;
}
._31Z7j {
  width: 100%;
}
._2ryHX:first-child ._31Z7j {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
._2ryHX:last-child ._31Z7j {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  box-shadow: none;
}
._3zBPz {
  border-color: #f6b6dd;
  color: #fcf;
}
._3zBPz:not(:last-child) {
  margin-right: 16px;
}
@media (max-width: 1439px) {
  ._3zBPz:not(:last-child) {
    margin-right: 8px;
  }
}
@media (hover: hover) {
  ._3zBPz:hover {
    color: #ef6c00;
  }
  ._3zBPz:hover:before {
    background-color: #f6b6dd;
  }
}
