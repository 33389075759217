._2Nyu7 {
  overflow: hidden;
}
._2Nyu7:not(:last-child) {
  margin-bottom: 4px;
}
._2Nyu7:first-child {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
._2Nyu7:last-child {
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
}
._2Nyu7:last-child ._14p0Q {
  padding-bottom: 30px;
}
._1rxDw {
  opacity: 0.5;
}
._2ghxY {
  border-radius: 50px;
  padding: 0 8px;
  margin-right: 5px;
  border: 1px solid #ef6c00;
  color: #ef6c00;
}
._3fLJk {
  display: flex;
  justify-content: space-between;
  align-self: center;
}
._14p0Q {
  padding: 18px 16px 15px 21px;
  background-color: #fff;
}
._2ybUc {
  margin-bottom: 7px;
  padding-right: 13px;
  word-wrap: break-word;
}
.P47FI {
  margin-right: 9px;
  color: #c8c9bf;
  transition: color 0.35s;
}
._1P4mg {
  display: flex;
  align-items: center;
}
._1pwzR {
  color: #474b2f;
  padding: 0;
  border: none;
  cursor: pointer;
  color: #c8c9bf;
  background-color: transparent;
  opacity: 0.2;
}
@media (hover: hover) {
  ._1pwzR {
    transition: color 0.35s;
  }
  ._1pwzR:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
._3fD13 {
  color: #a1a490;
  opacity: 1;
}
._1pwzR:hover ._1QY2Y {
  opacity: 0.33;
  transition-duration: 0.175s;
}
._7zWfS {
  flex-shrink: 0;
  display: block;
  width: 28px;
  text-align: center;
}
._1QY2Y {
  display: block;
  vertical-align: middle;
  transition: opacity 0.35s;
}
._1Qv3l {
  display: inline-flex;
  align-items: center;
  color: #b2b699;
}
.VugjH {
  color: #b2b699;
}
@media (max-width: 567px) {
  ._2yFIM {
    display: none;
  }
  ._2yFIM + ._29L_g {
    display: none;
  }
}
._15xWs {
  display: none;
}
@media (max-width: 567px) {
  ._15xWs {
    display: inline-flex;
    margin-bottom: 13px;
  }
}
._3MIfM {
  font-weight: 600;
}
._2q6-z {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  border: none;
}
._1GJoL {
  padding: 0;
  border: none;
  cursor: pointer;
  color: #eef0e6;
  background-color: transparent;
  transition: color 0.35s;
}
@media (hover: hover) {
  ._1GJoL:hover {
    color: #b2b699;
    transition-duration: 0.175s;
  }
  ._1GJoL:hover ._3YiG_ {
    opacity: 1;
    transition-duration: 0.175s;
  }
}
._3YiG_ {
  display: block;
  opacity: 0.2;
  transition: opacity 0.35s;
}
._29L_g {
  padding: 0 3px;
}
