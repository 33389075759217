._2PSiI {
  width: 25px;
  height: 25px;
  cursor: pointer;
  color: #474b2f;
  transition: color 0.2s ease-in-out;
}
._2PSiI:hover {
  color: #474b2f;
}
