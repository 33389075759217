.qTdXM {
  display: flex;
  justify-content: center;
  align-items: center;
}
.VOcPV {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  transition: background-color 0.35s;
}
.jCUwF .bE598 {
  transform: rotate(-180deg);
}
@media (hover: hover) {
  .VOcPV:hover {
    background-color: #eceee3;
  }
}
.bE598 {
  display: block;
  color: #d7d8d1;
}
.bE598 svg {
  display: block;
}
.SGOzf {
  margin-right: 15px;
  margin-left: 15px;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 1.333;
  color: #a1a490;
}
