._1rBOj {
  width: 212px;
}
._1rBOj > span {
  justify-content: space-between;
}
._3yl5- {
  flex: 1;
  text-align: left;
  color: #474b2f;
}
