.nlmIK {
  display: flex;
}
._2IAwK {
  width: 920px;
}
._3oBbM {
  margin-bottom: 60px;
}
@media (max-width: 674px) {
  ._3oBbM {
    margin-bottom: 40px;
  }
}
.WWWOF {
  width: 300px;
  padding-left: 40px;
}
._2cC6S {
  margin-bottom: 40px;
}
._2cC6S:last-child {
  margin-bottom: 0;
}
._3Iz8J {
  column-count: 2;
  column-gap: 40px;
}
._3Iz8J ._2__bq {
  break-inside: avoid;
}
._2__bq {
  margin-bottom: 50px;
}
._2__bq:last-child {
  margin-bottom: 0;
}
