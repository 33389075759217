._2J76p {
  margin-bottom: 60px;
}
._3-e8n {
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  margin-bottom: 60px;
}
.tchjo {
  width: calc(100% / 2 - 20px);
}
._2vznm {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
._19prP {
  width: calc(100% / 2 - 20px);
}
@media (max-width: 971px) {
  ._19prP {
    width: calc(100% / 2 - 16px);
  }
}
