._3zDlI {
  margin-bottom: 60px;
}
@media (max-width: 674px) {
  ._3zDlI {
    padding-bottom: 40px;
  }
}
._1WnHS {
  margin-bottom: 40px;
}
._2IUwA {
  margin-bottom: 40px;
}
.Er37E {
  margin-bottom: 40px;
}
.P6Kp2 {
  margin-top: 60px;
  margin-bottom: 60px !important;
}
@media (max-width: 971px) {
  .P6Kp2 {
    margin-top: 0;
  }
}
.Kcf6Q {
  margin-bottom: 40px;
}
