._32F9G {
  padding-top: 24px;
  padding-bottom: 24px;
}
@media (min-width: 568px) {
  ._32F9G {
    display: flex;
  }
}
._1CvQk {
  flex-shrink: 0;
  align-self: center;
  width: 262px;
  margin-right: 32px;
  text-align: center;
}
@media (max-width: 1079px) {
  ._1CvQk {
    width: 202px;
  }
}
@media (max-width: 971px) {
  ._1CvQk {
    width: 260px;
    margin-right: 30px;
  }
}
@media (max-width: 674px) {
  ._1CvQk {
    align-self: flex-start;
    width: 144px;
    margin-right: 24px;
  }
}
@media (max-width: 567px) {
  ._1CvQk {
    margin: 0 auto 24px;
  }
}
._2t3l1 {
  display: block;
  margin-right: auto;
  margin-left: auto;
}
@media (min-width: 675px) {
  ._2t3l1 {
    width: 144px;
  }
}
._2t3l1 img {
  display: block;
  max-width: 100%;
  height: auto;
}
._W-T7 {
  flex: 1;
  overflow: hidden;
}
._3Qpp6 {
  color: #474b2f;
}
@media (hover: hover) {
  ._3Qpp6 {
    transition: color 0.35s;
  }
  ._3Qpp6:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
._1atDZ {
  margin-bottom: 8px;
}
._3aJUQ {
  width: calc(100% - 80px);
}
.IUDay {
  display: flex;
  align-items: center;
}
.K3z-d {
  width: 80px;
  align-items: center;
  display: flex;
  color: #a1a490;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
}
._1KUb- {
  margin-right: 12px;
  padding: 2px 10px;
  height: auto;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}
._1IlzF {
  flex: 1 1 100%;
  margin-bottom: 16px;
  padding: 0;
  font-size: 15px;
  line-height: 20px;
  color: #a1a490;
}
._1IlzF:last-child {
  margin-bottom: 0;
}
@media (max-width: 674px) {
  ._1IlzF {
    margin-bottom: 12px;
  }
}
.Evpbq {
  position: relative;
  margin-bottom: 16px;
  padding: 0;
  height: 40px;
  overflow: hidden;
  font-size: 15px;
  line-height: 20px;
  color: #a1a490;
  white-space: break-spaces;
}
._2VY-E {
  position: absolute;
  top: 0;
  margin-top: 5px;
  height: 100%;
  width: 100%;
  display: inline-block;
  background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #fff 100%);
}
