.x-bV0 {
  display: block;
  padding: 20px;
  color: #474b2f;
  background-color: #fff;
  color: #474b2f;
}
@media (hover: hover) {
  .x-bV0 {
    transition: color 0.35s;
  }
  .x-bV0:hover {
    transition-duration: 0.175s;
    color: #a1a490;
  }
}
._3s4gV {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 4px;
  font-family: "EuclidCircularB";
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  word-break: break-word;
  color: #484b32;
  opacity: 0.8;
}
._1wvMG {
  margin-bottom: 20px;
  font-family: "EuclidCircularB";
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #474b2f;
}
._3g1pF {
  font-family: "EuclidCircularB";
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #474b2f;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-word;
  overflow: hidden;
}
._2Cg4g {
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  padding: 0;
  border: none;
  font-weight: 600;
  cursor: pointer;
  color: $textLighten;
  background-color: transparent;
}
._1mT5n {
  color: #227422;
}
._3mcfw {
  margin-right: 9px;
}
._27IeE {
  display: flex;
  align-items: center;
}
._24xLQ {
  padding: 0 8px;
  color: #afb599;
}
