._3ph4s {
  padding-bottom: 60px;
}
._95DUT {
  padding-top: 65px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 674px) {
  ._95DUT {
    margin-right: -32px;
    margin-left: -32px;
  }
}
@media (max-width: 567px) {
  ._95DUT {
    margin-right: -15px;
    margin-left: -15px;
  }
}
._2PJy7 {
  max-width: 620px;
  width: 100%;
}
@media (max-width: 674px) {
  ._2PJy7 {
    margin-bottom: 40px;
  }
}
