@media (max-width: 674px) {
  ._2RDc3 {
    margin-right: -32px;
    margin-left: -32px;
  }
}
@media (max-width: 567px) {
  ._2RDc3 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
._1Bbjl {
  margin-bottom: 24px;
}
@media (max-width: 674px) {
  ._1Bbjl {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (max-width: 567px) {
  ._1Bbjl {
    padding-right: 15px;
    padding-left: 15px;
  }
}
._1KDLu {
  padding: 0;
  font-size: 15px;
  color: unset;
}
.eTwpV {
  display: flex;
  padding: 28px 25px 32px;
  border-radius: 20px;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 1.333;
  background-color: #f9fbf7;
}
@media (max-width: 674px) {
  .eTwpV {
    padding-right: 32px;
    padding-left: 32px;
    border-radius: 0;
  }
}
@media (max-width: 567px) {
  .eTwpV {
    display: block;
    padding-top: 24px;
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 24px;
  }
}
._2xRaz {
  flex-shrink: 0;
  width: 176px;
  padding-right: 24px;
  font-weight: 600;
}
@media (max-width: 567px) {
  ._2xRaz {
    width: 100%;
    margin-bottom: 12px;
  }
}
._3Z1zy {
  color: #a1a490;
}
._3Hu6N {
  flex: 1;
}
._2RJwJ {
  margin-bottom: 14px;
}
._2RJwJ:last-child {
  margin-bottom: 0;
}
._1wc0v {
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 100%;
  transition: transform 0.35s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
._3FnBh {
  flex-shrink: 0;
  width: 56px;
  height: 56px;
  margin-right: 12px;
  border-radius: 20px;
  overflow: hidden;
  background-color: #f9fbf7;
  transform: translateZ(0);
}
._2ai8O {
  display: flex;
  align-items: center;
}
._1gT0G {
  padding-right: 18px;
  font-weight: 600;
  transition: color 0.35s;
}
@media (hover: hover) {
  ._2ai8O:hover ._1gT0G {
    color: #a1a490;
  }
  ._2ai8O:hover ._1wc0v {
    transform: scale(1.1) translateZ(0);
  }
}
