._1gzqf {
  min-width: 218px;
}
._36EpS,
.o0-tZ {
  position: relative;
}
._36EpS:before,
.o0-tZ:before {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: '';
  border-width: 3px;
  border-style: solid;
  border-color: #eceee3;
  border-radius: 20px;
  background-color: inherit;
  transition: background-color 0.35s, transform 0.35s;
  transform: translateZ(0);
  will-change: transform;
}
.sGcs2 {
  display: flex;
  align-items: center;
}
._14fJF,
._1gzqf .Select-placeholder {
  display: block;
  padding-right: 12px;
  padding-left: 8px;
  border: none;
  outline: none;
  font-weight: 600;
  line-height: inherit;
  color: #474b2f;
  background: none;
  transition: color 0.35s;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
._3StjQ {
  display: flex;
  align-items: center;
  max-width: 100%;
  padding: 10px 22px 10px 38px;
  margin-bottom: 4px;
  border-radius: 14px;
  font-weight: 600;
  white-space: nowrap;
  cursor: pointer;
  color: #141414;
  background-color: inherit;
  transition: color 0.35s;
}
@media (hover: hover) {
  ._3StjQ:hover {
    background-color: #eceee4;
  }
}
._3StjQ.is-selected {
  border-radius: 14px;
  background-color: #474b2f;
  color: #f9fbf7;
  pointer-events: none;
}
@media (max-width: 674px) {
  ._3StjQ {
    display: block;
    overflow: hidden;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
@media (hover: hover) {
  ._1gzqf .Select-control {
    transition: box-shadow 0.35s;
  }
  ._1gzqf .Select-control:hover {
    box-shadow: inset 0 0 0 3px #a1a492;
  }
  ._1gzqf .Select-control:hover ._14fJF,
  ._1gzqf .Select-control:hover .Select-placeholder {
    color: #474b2f;
  }
}
._1gzqf .Select-arrow-zone,
._1gzqf .Select-clear,
._1gzqf .Select-clear-zone {
  display: none;
}
._1gzqf .Select-arrow-zone {
  flex-shrink: 0;
  display: inline-block;
  width: 20px;
  height: 20px;
  background-image: url("/static/icons/dropdown-arrow.svg");
  color: #474b2f;
  background-repeat: no-repeat;
  background-size: cover;
  transform: rotate(90deg);
  order: -1;
  transition: transform 0.35s;
}
._1gzqf .is-open .Select-arrow-zone {
  transform: rotate(-90deg);
}
._1gzqf .Select.is-focused:not(.is-open) > .Select-control {
  background-color: transparent;
}
._1gzqf .Select-input {
  position: absolute;
}
._1gzqf .Select-input:focus {
  outline: none;
}
._1gzqf .Select-control {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  height: 44px;
  padding: 0 10px;
  border: none;
  border-radius: 20px;
  line-height: 44px;
  cursor: pointer;
  background-color: transparent;
  transition: background-color 0.35s;
}
._1gzqf .Select:not(.is-opened) .Select-control {
  box-shadow: inset 0 0 0 3px #eceee4;
}
._1gzqf .Select.is-open > .Select-control {
  background-color: #eceee4;
}
._1gzqf .Select-menu-outer {
  position: absolute;
  top: 100%;
  left: 0;
  min-width: 100%;
  max-height: 300px;
  margin-top: 8px;
  padding: 12px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0px 2px 4px 0px rgba(161,164,146,0.4);
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 674px) {
  ._1gzqf .Select-menu-outer {
    max-width: 100%;
  }
}
._1gzqf .Select-menu {
  max-height: 260px;
  -ms-overflow-style: none;
  overflow-x: hidden;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
._1gzqf .Select-menu::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  background: transparent;
}
.o0-tZ ._3StjQ {
  padding-right: 16px;
  padding-left: 16px;
}
.o0-tZ ._14fJF,
.o0-tZ .Select-placeholder {
  color: #474b2f;
}
@media (hover: hover) {
  .o0-tZ .Select-control {
    transition: background 0.35s;
  }
  .o0-tZ .Select-control:hover {
    background-color: transparent;
  }
}
.o0-tZ .Select-control {
  min-width: 254px;
  box-shadow: inset 0 0 0 3px #eceee4;
  transition: box-shadow 0.35s;
}
.o0-tZ .Select-control:hover {
  box-shadow: inset 0 0 0 3px #a1a492;
}
.o0-tZ .Select.is-open .Select-control {
  box-shadow: inset 0 0 0 3px transparent;
}
.Select {
  position: relative;
  flex: 1 1 100%;
}
.Select.is-open {
  z-index: 2;
}
.Select input::-webkit-contacts-auto-fill-button,
.Select input::-webkit-credentials-auto-fill-button {
  display: none !important;
}
.Select input::-ms-clear {
  display: none !important;
}
.Select input::-ms-reveal {
  display: none !important;
}
.Select,
.Select div,
.Select input,
.Select span {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}
.Select.is-disabled .Select-arrow-zone {
  opacity: 0.35;
  cursor: default;
  pointer-events: none;
}
.Select.has-value.is-pseudo-focused .Select-input {
  opacity: 0;
}
.Select.is-open .Select-arrow,
.Select .Select-arrow-zone:hover > .Select-arrow {
  border-top-color: #666;
}
.Select.Select--rtl {
  text-align: right;
  direction: rtl;
}
.Select-control {
  position: relative;
  display: table;
  width: 100%;
  height: 36px;
  border: 1px solid #ccc;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border-spacing: 0;
  border-collapse: separate;
  outline: none;
  overflow: hidden;
  cursor: default;
  color: #333;
  background-color: #fff;
  z-index: 1;
}
.Select-input > input {
  display: inline-block;
  width: 100%;
  margin: 0;
/* For IE 8 compatibility */
  padding: 8px 0 12px;
  border: 0 none;
  outline: none;
  font-family: inherit;
  font-size: inherit;
  line-height: 17px;
  cursor: default;
  background: none transparent;
  box-shadow: none;
/* For IE 8 compatibility */
  -webkit-appearance: none;
}
.is-focused .Select-input > input {
  cursor: text;
}
.has-value.is-pseudo-focused .Select-input {
  opacity: 0;
}
.Select-control:not(.is-searchable) > .Select-input {
  outline: none;
}
.Select-multi-value-wrapper {
  width: 100%;
}
