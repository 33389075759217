._2Qget {
  background-color: #fff;
  padding: 24px;
  border-radius: 20px;
}
@media (min-width: 1080px) {
  ._2Qget {
    position: absolute;
    top: 0;
    z-index: 10;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
}
@media (max-width: 1439px) {
  ._2Qget {
    width: 82.8%;
  }
}
@media (max-width: 1079px) {
  ._2Qget {
    width: 100%;
  }
}
._3bZs8 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px 60px;
}
@media (max-width: 567px) {
  ._3bZs8 {
    flex-direction: column;
  }
}
._1l1Tv {
  color: #3b722d;
  font-weight: 500;
  font-size: 18px;
  line-height: 140%;
}
@media (max-width: 1079px) {
  ._1l1Tv {
    font-weight: 600;
    font-size: 15px;
  }
}
