._3W_TJ {
  display: flex;
}
._1jjnZ {
  width: 920px;
}
._26TBa {
  margin-bottom: 60px;
}
@media (max-width: 674px) {
  ._26TBa {
    margin-bottom: 40px;
  }
}
._3efDH {
  width: 300px;
  padding-left: 40px;
}
._3kdQd {
  margin-bottom: 40px;
}
._3kdQd:last-child {
  margin-bottom: 0;
}
._2wlQ7 {
  column-count: 2;
  column-gap: 40px;
}
._2wlQ7 ._1M_Bm {
  break-inside: avoid;
}
._1M_Bm {
  margin-bottom: 50px;
}
._1M_Bm:last-child {
  margin-bottom: 0;
}
