.IZvXa {
  display: flex;
  justify-content: space-between;
  width: 246px;
  padding-top: 16px;
  padding-right: 16px;
  padding-left: 26px;
  padding-bottom: 48px;
  border-radius: 20px;
  color: #a1a490;
  background-color: #f4f4f1;
}
.I6L6S {
  align-self: center;
  color: #484b32;
}
._3E5Ph {
  flex-shrink: 0;
  max-width: 40px;
  max-height: 40px;
}
