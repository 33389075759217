._2ce1x {
  position: relative;
  color: #fff;
  transition: all 200ms ease-in-out;
  width: 220px;
  height: 168px;
  border-radius: 4px;
  display: flex;
  align-items: flex-end;
  padding: 12px;
  margin-right: 0;
}
._1_pd0 {
  width: 220px;
  margin-right: 6px;
}
@media (max-width: 971px) {
  ._1_pd0 {
    height: 196px;
  }
}
._1ergz {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: transform 0.35s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transform: translateZ(0);
  will-change: transform;
  border-radius: 4px;
}
._1ergz:before {
  background-color: inherit !important;
}
._2K0ps {
  height: 100%;
  word-break: break-word;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  color: #fff;
}
@media (max-width: 971px) {
  ._2K0ps {
    height: auto;
  }
}
.bzQQQ {
  position: relative;
  height: 100%;
  transition: all 100ms ease-out;
}
.y9snL {
  flex-shrink: 0;
}
._2ln00 {
  text-align: center;
  font-size: 10px;
  font-weight: bold;
  line-height: 20px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 8px;
}
._3kNgi {
  position: relative;
  font-size: 15px;
  color: #fff;
}
