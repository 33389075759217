._3LzpE {
  padding-bottom: 60px;
}
._1z5m5 {
  display: flex;
  margin-bottom: 60px;
  padding-top: 65px;
}
._1z5m5:last-child {
  margin-bottom: 0;
}
@media (max-width: 971px) {
  ._1z5m5 {
    flex-direction: column;
  }
}
@media (max-width: 674px) {
  ._1z5m5 {
    margin-right: -32px;
    margin-left: -32px;
  }
}
@media (max-width: 567px) {
  ._1z5m5 {
    margin-right: -15px;
    margin-left: -15px;
  }
}
._3nICt {
  max-width: 620px;
  width: 100%;
}
@media (max-width: 1079px) {
  ._3nICt {
    width: 560px;
  }
}
@media (max-width: 971px) {
  ._3nICt {
    width: 100%;
    margin-bottom: 40px;
  }
}
._15e0J {
  position: relative;
  flex-shrink: 0;
  width: 260px;
}
._14Q7e {
  margin-right: 40px;
}
@media (max-width: 1439px) {
  ._14Q7e {
    display: none;
  }
}
._2HM5V {
  margin-left: auto;
  margin-left: 40px;
}
@media (max-width: 971px) {
  ._2HM5V {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
    margin-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 674px) {
  ._2HM5V {
    margin-left: 32px;
    padding-right: 32px;
  }
}
@media (max-width: 567px) {
  ._2HM5V {
    margin-left: 15px;
    padding-right: 15px;
  }
}
@media (min-width: 972px) {
  ._18V-E {
    margin-bottom: 46px;
  }
}
@media (max-width: 971px) {
  ._18V-E {
    width: calc(50% - 16px);
    margin-bottom: 0;
  }
}
@media (max-width: 674px) {
  ._18V-E {
    width: 100%;
    margin-right: auto;
    margin-bottom: 20px;
    margin-left: auto;
  }
}
@media (min-width: 972px) {
  ._30T11 {
    padding: 0;
    background-color: transparent;
  }
}
@media (max-width: 971px) {
  ._30T11 {
    width: calc(50% - 16px);
    margin-bottom: 18px;
    overflow: hidden;
  }
}
@media (max-width: 674px) {
  ._30T11 {
    width: 100%;
    margin-right: auto;
    margin-bottom: 0;
    margin-left: auto;
  }
}
