._2Xmet {
  padding-bottom: 40px;
}
.kbkOK {
  margin-bottom: 60px;
}
._1Bu2I {
  margin-bottom: 24px;
}
@media (max-width: 567px) {
  ._1Bu2I {
    margin-bottom: 16px;
  }
}
.OV-bE {
  margin-bottom: 32px;
}
