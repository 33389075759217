._1iFQQ {
  padding-bottom: 60px;
}
._3DDzZ {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
._1rPIg {
  margin-right: 24px;
}
@media (max-width: 567px) {
  ._1rPIg {
    margin-right: 12px;
  }
}
._1Qpnw {
  min-width: auto;
}
@media (max-width: 674px) {
  ._1Qpnw {
    width: 160px;
  }
}
._3YOQO {
  display: flex;
  margin-bottom: 60px;
}
._3YOQO:last-child {
  margin-bottom: 0;
}
@media (max-width: 971px) {
  ._3YOQO {
    flex-direction: column;
  }
}
._107Oj {
  flex: 1 1 0%;
  width: calc(100% - 300px);
}
@media (max-width: 971px) {
  ._107Oj {
    width: 100%;
    margin-bottom: 40px;
  }
}
._3qvJv {
  margin-left: 24px;
  min-width: auto;
  background-color: transparent;
  color: #a1a490;
  box-shadow: inset 0 0 0 3px #eceee3;
  transition: color 0.35s, background-color 0.35s;
}
@media (hover: hover) {
  ._3qvJv {
    transition: color 0.35s, background-color 0.35s;
  }
}
._3qvJv:hover {
  color: #474b2f;
  box-shadow: inset 0 0 0 3px #eceee3;
  background-color: transparent;
}
@media (max-width: 567px) {
  ._3qvJv {
    margin-top: 12px;
    margin-left: 0px;
  }
}
