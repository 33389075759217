._1e4e8 {
  padding-bottom: 60px;
  overflow: hidden;
}
@media (max-width: 674px) {
  ._1e4e8 {
    padding-bottom: 40px;
  }
}
.VccBC {
  width: 100%;
}
._1KVCu {
  position: relative;
  flex-shrink: 0;
  width: 308px;
  margin-left: auto;
  padding-left: 20px;
}
@media (max-width: 1238px) {
  ._1KVCu {
    display: none;
  }
}
._3fOrs,
._3p2pX,
.VDlLJ,
._2GUpE,
._2zk03,
._3PRa1,
._11Vha {
  margin-bottom: 60px;
}
@media (max-width: 971px) {
  ._3fOrs,
  ._3p2pX,
  .VDlLJ,
  ._2GUpE,
  ._2zk03,
  ._3PRa1,
  ._11Vha {
    margin-bottom: 40px;
  }
}
