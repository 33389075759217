._3aY8a {
  display: flex;
}
._3aY8a .social-likes__icon {
  display: none;
}
._19dQy {
  height: 20px;
  margin-right: 25px;
  cursor: pointer;
}
._19dQy:last-child {
  margin-right: 0;
}
@media (max-width: 567px) {
  ._19dQy {
    margin-right: 40px;
  }
}
._19dQy svg {
  width: 20px;
  height: auto;
}
