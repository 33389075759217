._3kSAz {
  margin-bottom: 47px;
}
@media (max-width: 1079px) {
  ._3kSAz {
    margin-bottom: 60px;
  }
}
@media (max-width: 971px) {
  ._3kSAz {
    margin-bottom: 40px;
  }
}
