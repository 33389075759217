._3Nn4i {
  position: relative;
  flex-shrink: 0;
}
._1gfbj {
  position: absolute;
  top: 100%;
  right: -20px;
  z-index: 2;
}
