._2Cg1H {
  position: relative;
}
._2tgOq {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
  transition: bottom 0.2s;
}
._22Ukq {
  margin-left: 5px;
  transform: rotate(90deg);
  transition: transform 0.2s;
}
._3Irua {
  transform: translateY(2px) rotate(-90deg);
}
._3FWFb {
  bottom: 0px;
}
._3dTgh {
  overflow: hidden;
  transition: height 0.2s;
  min-height: 350px;
  height: var(--content-height, fit-content);
}
._3dTgh::before {
  content: '';
  position: absolute;
  width: calc(100% + 64px);
  bottom: 0;
  height: 100px;
  background-image: linear-gradient(0deg, #f9fbf7 10%, rgba(0,0,0,0) 100%);
  margin: 0 -32px;
  pointer-events: none;
  z-index: 2;
  transition: opacity 0.2s;
  opacity: 0;
}
.oLmJN {
  height: var(--paragraph-height);
}
.oLmJN::before {
  opacity: 1;
}
