._1zfzO {
  margin-bottom: 60px !important;
}
@media (max-width: 674px) {
  ._1zfzO {
    margin-bottom: 40px !important;
  }
}
._3OAP4 {
  margin-bottom: 60px;
}
.oGgR5 {
  display: block;
  column-count: 2;
  column-gap: 40px;
}
._7QMVe {
  padding-bottom: 40px;
  break-inside: avoid;
}
