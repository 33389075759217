.u_P9Y {
  display: block;
  width: 206px;
  padding-right: 20px;
  float: left;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-size: 15px;
  line-height: 1.333;
}
._1MDpf {
  min-height: 175px;
  padding: 16px 17px 21px;
  border: 1px solid #e4e7d7;
  border-radius: 20px;
}
._17mjR {
  margin-bottom: 14px;
  padding-left: 5px;
}
._23dpA {
  margin-bottom: 2px;
  font-weight: 600;
}
._2buJc {
  color: #b2b7a3;
}
