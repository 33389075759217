._2chEo {
  width: 100%;
}
.ipmY7 {
  font-weight: bolder;
  font-size: 50px;
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  overflow-wrap: anywhere;
  word-break: normal;
}
@media screen and (min-width: 320px) {
  .ipmY7 {
    font-size: 35px;
  }
}
@media screen and (min-width: 567px) {
  .ipmY7 {
    font-size: 45px;
  }
}
._14yda {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 567px) {
  ._14yda {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
}
._3sD48 > div {
  padding: 0;
}
._2AL17 {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
._3nWUy {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #dcdcdc;
  padding-top: 5%;
}
._3SJL5 {
  display: flex;
  flex-direction: row;
}
.Gbk_Q {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}
.Gbk_Q img {
  height: 100%;
  object-fit: cover;
}
._1q0Ws {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 3%;
}
._3oW8I {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media screen and (min-width: 320px) {
  ._3oW8I {
    flex-direction: column;
    gap: 15px;
  }
}
@media screen and (min-width: 971px) {
  ._3oW8I {
    gap: 5%;
    flex-direction: row;
  }
}
._2Dzp- {
  width: auto;
}
@media screen and (min-width: 320px) {
  ._2Dzp- {
    width: auto;
  }
}
@media screen and (min-width: 971px) {
  ._2Dzp- {
    width: 640px;
  }
}
@media screen and (min-width: 1238px) {
  ._2Dzp- {
    width: 780px;
  }
}
._3H4Js {
  width: 260px;
}
@media (max-width: 567px) {
  ._3H4Js {
    margin: 0 auto;
  }
}
._3b4q0 {
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bolder;
}
._3kZQD {
  display: flex;
  width: 575px;
  padding-bottom: 2%;
  justify-content: space-between;
  gap: 15px;
  font-size: 12px;
}
@media screen and (min-width: 320px) {
  ._3kZQD {
    flex-direction: row;
    padding-top: 5%;
    width: 290px;
  }
}
@media (max-width: 567px) {
  ._3kZQD {
    width: 100%;
  }
}
._1HdS1 {
  margin-top: 40px;
}
