._2s95e {
  display: flex;
}
._2s95e:not(:last-child) {
  margin-bottom: 16px;
}
.OK6AO {
  margin-right: 16px;
  width: 80px;
  font-weight: 600;
  font-size: 12px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #a1a490;
}
.jS3n_ {
  width: calc(100% - 80px);
}
._3KYiA {
  margin-right: 12px;
  padding: 2px 10px;
  height: auto;
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 1px;
}
