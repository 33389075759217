@media (max-width: 674px) {
  ._28eYe {
    margin-right: -32px;
    margin-left: -32px;
  }
}
@media (max-width: 567px) {
  ._28eYe {
    margin-right: -15px;
    margin-left: -15px;
  }
}
._3xCKj {
  margin-bottom: 2px;
  background-color: #fff;
}
._3xCKj:last-child {
  margin-bottom: 0;
}
@media (min-width: 675px) {
  ._3xCKj:first-of-type {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
  }
  ._3xCKj:last-of-type {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
  }
}
._18bNQ {
  padding-right: 16px;
  padding-left: 16px;
}
@media (max-width: 674px) and (max-width: 1079px) {
  ._18bNQ {
    padding-right: 30px;
    padding-left: 30px;
  }
}
@media (max-width: 674px) and (max-width: 971px) {
  ._18bNQ {
    padding-right: 32px;
    padding-left: 32px;
  }
}
@media (max-width: 674px) and (max-width: 567px) {
  ._18bNQ {
    padding-right: 15px;
    padding-left: 15px;
  }
}
