.EfXtz {
  display: flex;
}
._5cEzx {
  width: 920px;
  margin-right: 40px;
}
._296qf {
  width: 260px;
}
._1UHk7:not(:last-child) {
  margin-bottom: 40px;
}
