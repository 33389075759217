._3_d6Q {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 40px;
  background-color: #f4f4f1;
  border-radius: 20px;
  position: relative;
  overflow: hidden;
  --slider-margin: 40px;
}
@media (max-width: 971px) {
  ._3_d6Q {
    margin: 0 -32px;
    border-radius: 0;
    padding-top: 20px;
    padding-bottom: 20px;
    gap: 18px;
    --slider-margin: 42px;
  }
}
@media (max-width: 567px) {
  ._3_d6Q {
    padding: 15px;
    margin: 0 -15px;
    gap: 12px;
    --slider-margin: 15px;
  }
}
._18WHy {
  overflow: visible;
}
._1EAMu {
  overflow: visible;
}
.WDUaR {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 16px 40px;
}
.jLoLW {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
._1Jk6x {
  flex-shrink: 0;
  display: flex;
  gap: 8px;
}
@media (max-width: 567px) {
  ._1Jk6x {
    width: 100%;
  }
}
._3qGx3 {
  font-size: 36px;
  font-weight: 600;
  color: #000;
  line-height: 1;
}
@media (max-width: 567px) {
  ._3qGx3 {
    font-size: 24px;
  }
}
._3BqcV {
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: #000;
}
._34zM0 {
  margin: 0 calc(0px - var(--slider-margin));
}
._39RyK {
  width: fit-content;
  margin-right: 20px;
}
@media (max-width: 971px) {
  ._39RyK {
    margin-right: 5px;
    margin-left: 5px;
  }
}
._39RyK:first-child {
  margin-left: var(--slider-margin);
}
@media (max-width: 567px) {
  ._39RyK:first-child {
    margin-left: 6px;
  }
}
._39RyK:last-child {
  margin-right: var(--slider-margin);
}
._3oB7R {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
._3w-mi {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 16px;
}
._27jze,
._3PCEO {
  width: 100%;
  height: 8px;
  background-color: #f9fbf7;
  border-radius: 50px;
  overflow: hidden;
}
._27jze > div {
  background-color: #bbdcb0;
  border-radius: inherit;
}
._1y0JA {
  width: 40px;
  height: 40px;
  padding: 8px;
  background-color: #f9fbf7;
}
._3onZB {
  transform: scaleX(-1);
}
