._1nG8T {
  position: relative;
  padding-top: 56.25%;
}
._1nG8T iframe {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
