._3ePCU {
  text-align: center;
}
._3Vx7Q {
  display: block;
  width: 100%;
  max-width: 100%;
}
.QOkeG {
  position: relative;
  display: inline-block;
  padding-bottom: 48px;
}
.b_zWf {
  position: absolute;
  left: 0;
  bottom: 0;
  display: block;
  width: 100%;
  padding: 11px 22px 16px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  line-height: 1.333;
  text-align: center;
  color: #b2b7a3;
  background-color: #f9fbf7;
}
._2yMWg {
  display: inline-block;
  font-family: 'EuclidCircularB', Arial, Helvetica, sans-serif;
  font-size: 15px;
  white-space: initial;
  word-wrap: anywhere;
}
._3hOqn {
  display: inline-block;
  vertical-align: middle;
}
._3hOqn svg {
  display: block;
}
