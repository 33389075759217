._3vTjQ {
  padding-top: 56px;
}
._3vTjQ .swiper-container {
  overflow: visible;
}
._3vTjQ .swiper-wrapper {
  margin-bottom: 26px;
}
@media (max-width: 674px) {
  ._3vTjQ .swiper-wrapper {
    margin-bottom: 0;
  }
}
@media (max-width: 674px) {
  ._3vTjQ {
    padding-top: 0;
  }
}
._2soxt {
  background-size: 80% 80%;
}
@media (max-width: 1079px) {
  ._2soxt {
    width: 112px;
    height: 112px;
  }
}
@media (max-width: 674px) {
  ._2soxt {
    display: none;
  }
}
.TY-O8 {
  height: 10px;
  border-radius: 20px;
  background-color: #eff0ec;
}
@media (max-width: 674px) {
  .TY-O8 {
    display: none;
  }
}
._3akWe {
  height: 100%;
  border-radius: inherit;
  cursor: grab;
  background-color: #48d3b5;
}
._3akWe:active {
  cursor: move;
  cursor: grabbing;
}
._3akWe::before {
  content: '';
  position: absolute;
  top: -8px;
  right: -8px;
  left: -8px;
  bottom: -8px;
  display: block;
}
._2yBHl {
  width: 250px;
  padding-right: 2px;
  cursor: grab;
}
@media (max-width: 674px) {
  ._2yBHl {
    width: 188px;
  }
}
._2yBHl:last-child .es783 {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
._2yBHl:last-child .es783:before,
._2yBHl:last-child .es783:after {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
._2yBHl:last-child ._1U-vb {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}
.es783 {
  position: relative;
  display: flex;
  align-items: flex-end;
  height: 384px;
  padding: 24px 20px;
  opacity: 0.4;
  overflow: hidden;
  background-color: #000;
  transition: opacity 0.35s;
  transform: translateZ(0);
}
@media (hover: hover) {
  .es783:hover ._1U-vb {
    transform: scale(1.1);
  }
  .es783:hover:before {
    transition-duration: 0.175s;
    opacity: 1;
  }
}
@media (max-width: 674px) {
  .es783 {
    height: 272px;
  }
}
.es783:before,
.es783:after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: block;
  transform: translateZ(0);
}
.es783:before {
  opacity: 0;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), #000);
  transition: opacity 0.35s;
  mix-blend-mode: multiply;
}
.es783:after {
  background-image: linear-gradient(to bottom, rgba(0,0,0,0), rgba(0,0,0,0.8));
}
._1U-vb {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  transition: transform 0.35s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transform: translateZ(0);
  will-change: transform;
}
._3V3HE {
  position: relative;
  width: 100%;
  z-index: 2;
  transform: translateZ(0);
}
._2xOLG {
  margin-bottom: 12px;
  line-height: 1.28;
  word-break: break-word;
}
._2xOLG:last-child {
  margin-bottom: 0;
}
._3amW0 {
  color: #474b2f;
  color: #fff;
}
@media (hover: hover) {
  ._3amW0 {
    transition: color 0.35s;
  }
  ._3amW0:hover {
    color: #a1a490;
  }
}
@media (max-width: 567px) {
  .ZbAJA {
    max-height: 230px;
    overflow-x: hidden;
    overflow-y: auto;
  }
}
._3fWgz .es783 {
  opacity: 1;
}
@media (max-width: 674px) {
  ._7fdEx {
    width: auto;
  }
}
._7fdEx .es783 {
  display: block;
  padding: 70px 30px 30px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: visible;
  background-color: #143f14;
  background-image: url("/static/images/home/AssetsSlider/preview-bg.png");
  background-repeat: no-repeat;
  background-position: 100% 100%;
  background-size: 100% auto;
}
._7fdEx .es783:before,
._7fdEx .es783:after {
  display: none;
}
@media (max-width: 674px) {
  ._7fdEx .es783 {
    position: relative;
    display: flex;
    align-items: flex-end;
    padding: 25px 20px;
    background-image: none;
  }
}
._7fdEx ._2xOLG {
  word-break: break-word;
  color: #79fffc;
}
@media (max-width: 674px) {
  ._7fdEx ._2xOLG {
    flex-shrink: 0;
    font-size: 18px;
    transform: rotate(180deg);
    writing-mode: vertical-lr;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
